import {DICTIONARY, ICONS, PAGE_STATES} from "../../../globals/constants/client/constants"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"

const Success = props => {
	const currentState = store.getState()
	const levelOne = currentState.window.current.levelOne
	return <div className={"w3-display-middle w3-center w3-animate-opacity"} style={{fontSize: "8vh"}}>
		<div onClick={() => {
			store.dispatch({
				type: actions.SET_CURRENT_WINDOW,
				values: {window: PAGE_STATES[levelOne].MAIN.REQUESTS.X}
			})
		}}>
			<MobileFontAwesome icon={ICONS.CHECK_DOUBLE} label={props.label || translate(DICTIONARY.SUCCESS_EXCLAMATION.X)}/>
		</div>
		<div style={{fontSize: "4vh"}}>
			{props.label || translate(DICTIONARY.SUCCESS_EXCLAMATION.X)}
		</div>
	</div>
}

export default Success