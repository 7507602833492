import React from "react"
import {DICTIONARY, ICONS, TEXT_FIELD_PROPERTIES} from "../../../globals/constants/client/constants"
import {deleteMessage, fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../main/MobileFontAwesome"
import downArrowGold from "../../../images/chevron-double-down-duotone-gold.svg"
import downArrowBlue from "../../../images/chevron-double-down-duotone-blue.svg"
/**
 *
 * @param {Object} props
 * @param {string} props.type - type of input field
 * @param {React.HTMLAttributes.style} props.style - style object of container's top level element
 * @param {React.HTMLAttributes.className} props.className - className(s) of container's top level element
 * @param {string} props.id
 * @param {string} props.name
 * @param {string} props.form
 * @param {string} props.label
 * @param {Object} props.labelStyle
 * @param {boolean} props.showLabel
 * @param {boolean} props.autoFocus
 * @param {string} props.maxLength
 * @param {string} props.invalidMessage
 * @param {string} props.min - YYYY-MM-DD format for date type, integer format for number type
 * @param {string} props.max - YYYY-MM-DD format for date type, integer format for number type
 * @param {string} props.step - for number type only
 * @param {function} props.onChange
 * @param {function} props.onClick
 * @param {Object[]} props.list - list items for datalist - datalist and dropdown types only
 * @param {string} props.list[].value
 * @param {string} props.list[].label
 * @param {string} props.frozenValue - value to be shown on read only version of datalist - datalist type only
 * @param {boolean} props.multiple - option to select multiple values from dropdown - dropdown type only
 * @param {string|number} props.size - number of options to show at a time - dropdown type only
 * @param {Object} props.selected - default value of dropdown list - dropdown type only
 * @param {string} props.selected.value
 * @param {string} props.selected.label
 * @param {boolean} props.selected.disabled
 * @param {string} props.autoComplete
 * @param {string} props.placeholder
 * @param {string} props.defaultValue
 * @param {string} props.pattern - regex input filtering pattern
 * @param {boolean} props.required
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {string} props.translate {string} - "yes" or "no"
 * @param {string} props.inputMode
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @param {string} [props.height = "6vh"] - height of input element
 * @param {string} props.width - width of input element
 * @param {string|number} props.dataId - Default content of data-id attribute of element
 * @param {string|number} props.dataIndex - Default content of data-index attribute of element
 * @param {string|number} props.dataA - Default content of data-a attribute of element
 * @param {string|number} props.dataB - Default content of data-b attribute of element
 * @param {string|number} props.dataC - Default content of data-c attribute of element
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputGeneric = props => {
	try {
		let input, left = <></>, right = <></>, label = props.label
		const height = props.height || "6vh"
		const list = (!!props.list) ? props.list : []
		if (props.left) {
			if (props.left.icon) {
				const clear_left = props.left.icon[0] === " " ? "0" : null
				const thisIcon_left = props.left.icon[0] === " " ? ["fas", "music"] : props.left.icon
				left = <MobileFontAwesome id={`${props.id}-left`} icon={thisIcon_left} onClick={props.left.onClick}
					className={`paralian-input-icons ${props.left.className}`} style={{...props.left.style, padding: "1vh 0vw",
					textAlign: "center", opacity: clear_left}} label={props.left.label}/>
			}
		}
		if (props.right) {
			if (props.right.icon) {
				const clear_right = props.right.icon[0] === " " ? "0" : null
				const thisIcon_right = props.right.icon[0] === " " ? ["fas", "music"] : props.right.icon
				right = <MobileFontAwesome id={`${props.id}-right`} icon={thisIcon_right} onClick={props.right.onClick}
					className={`paralian-input-icons ${props.right.className}`} style={{...props.right.style, padding: "1vh 0vw",
					textAlign: "center", opacity: clear_right}} label={props.right.label}/>
			}
		}
		const rand = `${Math.floor((Math.random() * 1000))}-min`
		switch (props.type) {
			case "tel":
				input =
					<input type={"tel"} id={`${props.id}-input`} className={"paralian-input-field"} name={props.name}
						form={props.form} autoFocus={props.autoFocus} maxLength={TEXT_FIELD_PROPERTIES.phone.maxLength}
						style={{color: "inherit", height: height, width: props.width}} required={props.required}
						autoComplete={props.autoComplete} pattern={TEXT_FIELD_PROPERTIES.phone.pattern} disabled={props.disabled}
						placeholder={props.placeholder} defaultValue={props.defaultValue}/>
				break
			case "text":
				input =
					<input type={"text"} id={`${props.id}-input`} className={"paralian-input-field"} title={props.title}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={props.maxLength}
					 	disabled={props.disabled} autoComplete={props.autoComplete} pattern={props.pattern}
					 	required={props.required} inputMode={props.inputMode}
						style={{color: "inherit", height: height, width: props.width, whiteSpace: "pre", textOverflow:
							"ellipsis", overflow: "hidden"}} placeholder={props.placeholder} defaultValue={props.defaultValue}
					 	onChange={() => {
							 try {
								 if (props.onChange) {props.onChange(document.getElementById(`${props.id}-input`).value)}
							 } catch (e) {
								 console.log(e)
							 }
						}}
					 	onInvalid={() => {
							try {
								fadingMessage("input-invalid-message", `${translate(DICTIONARY.INVALID_INPUT.X)}: ${props.label || props.title}`,
									props.invalidMessage || "", "w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE, 5000)
							} catch (e) {
								console.log(e)
							}
						}}/>
				break
			case "textbox":
				input =
					<textarea id={`${props.id}-input`} className={"paralian-input-field"} title={props.title}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={props.maxLength}
						autoComplete={props.autoComplete} required={props.required} disabled={props.disabled}
						style={{color: "inherit", height: height, width: props.width, whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden"}}
						placeholder={props.placeholder} defaultValue={props.defaultValue} onChange={props.onChange}/>
				break
			case "website":
				input =
					<input type={"text"} id={`${props.id}-input`} className={"paralian-input-field"} disabled={props.disabled}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={"128"}
						autoComplete={props.autoComplete || "url"} pattern={TEXT_FIELD_PROPERTIES.website.pattern} required={props.required}
						style={{color: "inherit", height: height, width: props.width, whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden"}}
						placeholder={props.placeholder} defaultValue={props.defaultValue} onChange={props.onChange}/>
				break
			case "email":
				input =
					<input type={"email"} id={`${props.id}-input`} className={"paralian-input-field"} disabled={props.disabled}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={"120"}
						style={{color: "inherit", height: height, width: props.width, whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden"}}
						autoComplete={props.autoComplete} pattern={TEXT_FIELD_PROPERTIES.eMail.pattern} required={props.required}
						placeholder={props.placeholder} defaultValue={props.defaultValue}/>
				break
			case "date":
				input =
					<input type={"date"} id={`${props.id}-input`} className={`paralian-date-field ${props.className}`}
						style={{color: "inherit", backgroundColor: "inherit", height: height, width: props.width}} name={props.name}
						form={props.form} autoFocus={props.autoFocus} required={props.required} min={props.min} max={props.max}
						autoComplete={props.autoComplete} defaultValue={props.defaultValue} disabled={props.disabled}
					 	onChange={() => {
							if (props.onChange) {
								props.onChange(document.getElementById(`${props.id}-input`).value)
							}
						}}/>
				break
			case "datalist":
				input = <>
					{/* key={`${props.id}-${rand}`} */}
					<input key={`${props.id}-datalist`} type={"text"} list={`${props.id}-list`} id={`${props.id}-input`}
					 	className={"paralian-input-field"}
						style={{color: "inherit", backgroundColor: "inherit", height: height, width: props.width,
							whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden"}} onSelect={props.onSelect}
						disabled={!!props.frozenValue} name={props.name} data-index={props.dataIndex || " "} form={props.form}
						data-a={props.dataA || " "} data-b={props.dataB || " "} data-id={props.dataId || " "}
						autoFocus={props.autoFocus} maxLength={props.maxLength} autoComplete={props.autoComplete}
						pattern={props.pattern || "([a-zA-Z0-9-_.:/ ]){2,128}"} required={props.required}
						placeholder={props.frozenValue || props.placeholder} title={props.title}
						defaultValue={props.defaultValue} onChange={props.onChange} translate={props.translate}/>
					<datalist id={`${props.id}-list`}>
						{props.list.map(value => <option id={`${props.id}-${value.label}`} key={`${props.id}-${value.label}-${rand}`}
							value={value.label} label={value.value} data-a={value.code}>{value.value}</option>)}
					</datalist>
				</>
				break
			case "password":
				label = props.label ? props.label : translate(DICTIONARY.PASSWORD.X)
				input =
					<input type={"password"} id={`${props.id}-input`} className={"paralian-input-field"}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={"64"}
						style={{color: "inherit", height: height, width: props.width}} disabled={props.disabled}
						autoComplete={props.autoComplete} pattern={TEXT_FIELD_PROPERTIES.password.pattern}
						required={true} placeholder={label} defaultValue={null} onChange={props.onChange}/>
				break
			case "username":
				label = translate(DICTIONARY.USERNAME.X)
				input =
					<input type={"text"} id={`${props.id}-input`} className={"paralian-input-field"}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={"32"} translate={"no"}
						autoComplete={props.autoComplete} pattern={TEXT_FIELD_PROPERTIES.username.pattern} required={props.required}
						style={{color: "inherit", height: height, width: props.width}} disabled={props.disabled}
						placeholder={label} defaultValue={props.defaultValue} onChange={props.onChange}/>
				break
			case "number":
				input = <div style={{display: props.units ? "flex" : "block"}}>
					<input type={"number"} id={`${props.id}-input`} className={"paralian-input-field"}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={props.maxLength}
						autoComplete={props.autoComplete} pattern={props.pattern} required={props.required}
						placeholder={props.placeholder} min={props.min} max={props.max} step={props.step}
						defaultValue={props.defaultValue} onChange={props.onChange} disabled={props.disabled}
						style={{color: "inherit", height: height, width: props.width}}/>
					{props.units ? <div style={{margin: "auto", padding: "0vh 1vh"}}>{props.units}</div> : <></>}
				</div>
				break
			case "long":
				input = <div style={{display: props.units ? "flex" : "block"}}>
					<input type={"text"} id={`${props.id}-input`} className={"paralian-input-field"} disabled={props.disabled}
						name={props.name} form={props.form} autoFocus={props.autoFocus} maxLength={props.maxLength}
						autoComplete={props.autoComplete} pattern={props.pattern} required={props.required} step={props.step}
						placeholder={props.placeholder} style={{color: "inherit", height: height, width: props.width}}
						defaultValue={props.defaultValue} onChange={props.onChange} data-a={props.dataA} data-b={props.dataB}
						onInputCapture={() => {
							const x = Number(document.getElementById(`${props.id}-input`).value.replace(/[^\d]/gi,""))
							let pass = true
							if (!x || isNaN(x)) {return}
							if (x > Number(props.max)) {
								newMessage("max", translate(DICTIONARY.VALUE_TOO_HIGH.X),
									`${translate(DICTIONARY.VALUE_MUST_BE_LESS_THAN.X)}${props.max}`)
								pass = false
							} else {
								deleteMessage("max", false)
							}
							if (x < Number(props.min)) {
								if (!props.ignoreZero) {
									newMessage("min", translate(DICTIONARY.VALUE_TOO_LOW.X),
										`${translate(DICTIONARY.VALUE_MUST_BE_GREATER_THAN.X)}${props.min}`)
								}
								pass = false
							} else {
								deleteMessage("min", false)
							}
							if (x % Number(props.step) !== 0) {
								newMessage("mod", translate(DICTIONARY.VALUE_NOT_ALLOWED.X),
									`${translate(DICTIONARY.VALUE_MUST_BE_MULTIPLE_OF.X)}${props.step}`)
								pass = false
							} else {
								deleteMessage("mod", false)
							}
							document.getElementById(`${props.id}-input`).value = x ? x.toLocaleString() : x
							try {if (props.onChange) {props.onChange(x, pass)}} catch (e) {}
					}}/>
					{props.units ? <div style={{margin: "auto", padding: "0vh 1vh"}}>{props.units}</div> : <></>}
				</div>
				break
			case "dropdown":
				const selected = props.selected ? <option key={`${props.id}-${props.selected.value}-${rand}`}
					value={props.selected.value} disabled={props.selected.disabled}
					label={props.selected.label} /*selected={true}*/>{props.selected.label}</option> : <></>
				const bgStyle = !props.disabled ? {
					background: !props.disabled ? `url(${props.className === "paralian-theme-level-1" ||
					props.className === "paralian-theme-level-2" ? downArrowGold : downArrowBlue}) no-repeat right` : undefined,
					backgroundSize: props.fontSize || "2.5vh"} : {}
				input =
					<select key={`${props.id}-${rand}`} id={`${props.id}-input`} className={"paralian-input"} size={props.size}
						name={props.name} form={props.form} autoFocus={props.autoFocus} required={props.required}
						defaultValue={props.defaultValue} onChange={props.onChange} multiple={props.multiple} disabled={props.disabled}
						style={{paddingLeft: "2vh", color: "inherit", height: height, width: props.width || "100%", paddingRight: "1vh",
							whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden", WebkitAppearance: "none",
							MozAppearance: "none", appearance: "none", ...bgStyle}} autoComplete={props.autoComplete}
						data-a={props.dataA} data-b={props.dataB}	data-c={props.dataC} data-id={props.dataId}
						data-index={props.dataIndex}>
						{selected}
						{list.map(item => <option className={props.className === "paralian-theme-level-1" ||
							props.className === "paralian-theme-level-2" ?
							"paralian-theme-level-1-flipped" : "paralian-theme-level-1"} /*selected={item.value === props.defaultValue}*/
							key={`${props.id}-${item.value}-${rand}`} value={item.value} label={item.label}>{item.label}</option>)}
					</select>
				break
			case "color":
				input =
					<input type={"color"} id={`${props.id}-input`} style={{color: "inherit", height: height, width: props.width}}
						form={props.form} name={props.name} className="paralian-input-field" autoFocus={props.autoFocus}
						required={props.required}	placeholder={label} defaultValue={props.defaultValue || "#ffffff"} onChange={props.onChange}/>
				break
			case "button":
				// console.log("button")
				input =
					<div id={`${props.id}-input`} className={"paralian-input-field"} onClick={props.onClick}>
						{props.label}
					</div>
				break
			default:
				input = <></>
		}
		return <div style={{width: "100%"}}>
			<div className={"paralian-label-field"} style={{display: props.showLabel ? "block" : "none", ...props.labelStyle}}>
				{props.label}
			</div>
			<div className={`${props.className}${props.type === "dropdown" ? " w3-ripple" : ""}`}
			 	style={{...props.style, display: "inline-flex",
				width: "100%", borderTopLeftRadius: props.topRadius, borderBottomLeftRadius: props.bottomRadius,
				borderTopRightRadius: props.topRadius, borderBottomRightRadius: props.bottomRadius}}>
				{left}
				<div style={{width: "100%", paddingRight: props.type === "dropdown" ?  "1.5vh" : "0vh"}}>
					<label htmlFor={`${props.id}-input`} style={{display: "none"}}>{props.label}</label>
					{input}
				</div>
				{right}
			</div>
		</div>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileInputGeneric