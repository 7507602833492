import React, {useEffect, useState} from "react"
import Currency from "../../../globals/classes/shared/Currency"
import Servicio from "../../../globals/classes/shared/Servicio"

/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Currency} props.order.currency
 * @param {Object} props.item
 * @param {number} props.item.item
 * @param {number} props.item.charge
 * @param {number} props.item.quantity
 * @param {IndexedArray} props.indexedServices
 * @return {JSX.Element}
 * @constructor
 */
const MobileOrderDetailsCard = props => {
	const [currency, setCurrency] = useState(new Currency(props.order.currency))
	useEffect(() => {
		setCurrency(new Currency(props.order.currency))
	}, [props.order.currency])
	const service = new Servicio(props.indexedServices.byIndex(`${props.item.item}`))
	try {
		return <div key={`order-${props.order.id}-item-${props.item.item}-adjust`}
			className={"paralian-theme-level-2"} style={{padding: "1vh", margin: "2vh"}}>
			<div style={{padding: "2vh 0vh"}}>
				<i>{service.labelsAsString}</i>
			</div>
			<div style={{display: "inline-flex", padding: "1vh 1vh", width: "100%"}}>
				<div style={{textAlign: "left", width: "100%"}}>
					{currency.unitAsText(props.item.charge, props.item.quantity) + " (" +
					props.item.quantity.toLocaleString(undefined) + ")"}
				</div>
				<div>
					{currency.asText(props.item.charge)}
				</div>
			</div>
		</div>
	} catch (e) {
		console.log(e)
		return <></>
	}
}

export default MobileOrderDetailsCard