import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import {deLinkAccount, switchAccount} from "../../../globals/functions/client/serverFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileImage from "./MobileImage"
import Image from "../../../globals/classes/shared/Image"
/**
 *
 * @param {Object} props
 * @param {Object} props.account
 * @param {number} props.account.id
 * @param {string} props.account.username
 * @param {string} props.account.image - image path
 * @param {string} props.account.type - account type
 * @param {string} props.account.organization - name
 * @param {number} props.account.notifications - number of pending notifications
 * @param {function} props.refresh
 * @param {function} props.setAccount
 * @param {function} props.setLinkedAccounts
 * @param {function} props.removeAccount
 * @return {JSX.Element}
 * @constructor
 */
const MobileLinkedAccount = props => {
	const organization = props.account.organization || ""
	return <div className={"paralian-mobile-info-card"}>
		<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
			textAlign: "center", marginBottom: "1vh"}}>
			<div style={{minWidth: "10%"}}>
				<MobileFontAwesome icon={ICONS.UNLINK} style={{fontSize: "3vh"}} onClick={async () => {
					const confirmed = window.confirm(translate(DICTIONARY.UNLINK_ACCOUNT_QUERY.X))
					if (confirmed) {
						const response = await deLinkAccount(props.account.id)
						// console.log(response)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refresh().then()
							props.removeAccount()
						}
					}
				}}/>
			</div>
			<div style={{width: "100%", padding: "0.5vh"}}>
				<i>{props.account.username}</i>
			</div>
			<div style={{minWidth: "10%"}}>
				<MobileFontAwesome icon={ICONS.RANDOM} style={{fontSize: "3vh"}} onClick={async () => {
					const response = await switchAccount(props.account.id)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS){
						await props.refresh().then()
					}
				}}/>
			</div>
		</div>
		<div style={{display: "inline-flex", width: "100%", maxWidth: "36vh"}}>
			<MobileImage image={new Image({path: props.account.image})} size={"10vh"} alt={"profile"}
				style={{margin: "auto 1vh"}}/>
			<div style={{width: "100%", textAlign: "right", margin: "auto"}}>
				<div>
					<i style={{fontSize: "2vh"}}>{translate(`${props.account.type.toUpperCase()}_ACCOUNT`)}</i>
				</div>
				<div style={{fontSize: "3vh"}}>
					{organization}
				</div>
				{props.account.notifications ? <div style={{display: "inline-flex", margin: "1vh 0vh 0vh auto"}}>
					<i style={{paddingRight: "1vh"}}>{`${translate(DICTIONARY.NOTIFICATIONS.X)} `}</i>
					<div style={{borderRadius: "50%",	width: "3vh",	height: "3vh", textAlign: "center", padding: "0.5vh",
						fontSize: "1.5vh"}}	className={"paralian-theme-level-1"}>
						{props.account.notifications}
					</div>
				</div> : <></>}
			</div>
		</div>
	</div>
}

export default MobileLinkedAccount