import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {VERIFICATION_TYPE} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileBrandModelYearHullEdit from "../hull/MobileBrandModelYearHullEdit"
import MobileInputText from "../../../shared/input/text/MobileInputText"
import MobileInputCode from "../../../shared/input/text/MobileInputCode"
import MobileInputSlider from "../../../shared/input/switch/MobileInputSlider"
import Vessel from "../../../../globals/classes/shared/Vessel"
import Hull from "../../../../globals/classes/shared/vessels/Hull"

/**
 *
 * @param {Object} props
 * @param {Object} [props.owner]
 * @param {Email} props.owner.email
 * @param {Phone} props.owner.phone
 * @param {string} props.owner.name
 * @param {boolean} props.owner.isOrganization
 * @param {number} props.owner.action
 * @param {function} props.onAdd
 * @param {function} props.onUndo
 * @param {boolean} props.isOrganization
 * @param {boolean} props.isInvite
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselAdd = props => {
	const [useCode, setUseCode] = useState(false)
	try {
		const slider = props.isInvite ? <></> : <div style={{height: "10vh"}} className={"w3-display-container"}>
			<MobileInputSlider id={"use-code"} label={translate(DICTIONARY.I_HAVE_CONFIRMATION_CODE.X)} form={"add-vessel-form"}
				height={"3vh"} className={"w3-display-middle"} labelStyle={{paddingLeft: "4vh", textAlign: "left"}}
				defaultChecked={false} width={"8vh"} onChange={() => setUseCode(!useCode)} padding={"0.25vh 0vh 0.25vh 2vh"}/>
		</div>
		const agreement = useCode ? <div style={{display: "flex", padding: "2vh 0vh"}} className={"w3-animate-opacity"}>
			<div>
				<input id={"vessel-add-checkbox"} style={{height: "2.5vh", width: "2.5vh", margin: "0vh 1vh"}} type={"checkbox"}
					name={"vessel-add-checkbox"} data-a={"denied"} onChange={() => {
					const checkbox = document.getElementById("vessel-add-checkbox")
					if (checkbox.getAttribute("data-a") === "denied") {
						checkbox.setAttribute("data-a", "accepted")
						document.getElementById("invitation-code-input").disabled = false
					} else {
						checkbox.setAttribute("data-a", "denied")
						document.getElementById("invitation-code-input").disabled = true
					}
				}}/>
			</div>
			<div style={{width: "100%", textAlign: "left"}}>
				<label htmlFor={"vessel-add-checkbox"} style={{textAlign: "left", margin: "auto"}}>
					{translate(DICTIONARY.VESSEL_LEGAL_OWNER_MESSAGE.X)}
				</label>
			</div>
		</div> : <></>
		/* TODO: server-side vessel confirmation */
		const content = useCode ?
			<MobileInputCode id={"invitation-code"} className={"paralian-theme-level-1 w3-animate-opacity"} name={"code"}
				disabled={true}	left={{icon: ICONS.SHIELD}} verificationType={VERIFICATION_TYPE.VESSEL_OWNERSHIP_CONFIRMATION}
				autoFocus={true} showLabel={false} placeholder={translate(DICTIONARY.CODE.X)} label={translate(DICTIONARY.CODE.X)}
				required={true}	onFailure={() => {}} onSuccess={payload => {
				const vessel = new Vessel({
					id: payload.vessel.id,
					name: payload.vessel.name,
					hull: new Hull({
						brand: payload.vessel.hull.brand,
						model: payload.vessel.hull.model,
						year: payload.vessel.hull.year
					})
				})
				props.onAdd(vessel)
			}}/> :
			<>
				<MobileInputText id={"vessel-name"} label={translate(DICTIONARY.VESSEL_NAME.X)} form={"hull-bmy-form"}
					autoFocus={true} placeholder={translate(DICTIONARY.NAME.X)} maxLength={"64"} required={true} showLabel={true}
					className={"paralian-theme-level-1 w3-animate-opacity"} style={{margin: "1vh 0vh"}} name={"name"}
					pattern={"([a-zA-Z0-9-_.' ]){2,64}"} autoComplete={"off"}/>
				<MobileBrandModelYearHullEdit add={true} isInvite={props.isInvite} isOrganization={props.isOrganization}
					owner={props.owner} onSuccess={(id, name, brand, model, year) => {
					const vessel = new Vessel({
						id: id,
						name: name,
						hull: new Hull({
							brand: brand,
							model: model,
							year: year
						})
					})
					props.onAdd(vessel)
				}}/>
			</>
		return <div className={"paralian-mobile-info-card"}>
			{slider}
			{content}
			{agreement}
		</div>
	} catch (e) {
		console.log(e)
		return <></>
	}

}

export default MobileVesselAdd