import React, {useState} from "react"
import {ICONS} from "../../../../globals/constants/client/constants"
import {Document, Page, pdfjs} from "react-pdf"
import {newMessage} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../../main/MobileFontAwesome"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.label
 * @param {string} [props.info]
 * @param {string} [props.className]
 * @param {boolean} [props.showLabel]
 * @param {function} props.setFile
 * @returns {JSX.Element}
 * @constructor
 */
const MobileFileUploadCanvas = props => {
	const [pdfDocument, setDocument] = useState('')
	const [pdfSelected, setPdfSelected] = useState(false)
	const deviceWidth = window.screen.availWidth
	const deviceHeight = window.screen.availHeight
	const docWidth = Math.min(Math.trunc(deviceHeight*(0.76-0.12)),Math.trunc(deviceWidth-deviceHeight*0.12))
	const label = props.showLabel ?
		<div className={"paralian-label-field"}>
			{props.label}
			{
				props.info ?
				<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{paddingLeft: "1.5vh"}}
					onClick={() => newMessage(`${props.id}-file-input-info-message`,
						props.label, props.info, "w3-pale-blue", ICONS.INFO_CIRCLE)}/> : <></>
			}
		</div> : <></>
	const fileDiv = pdfSelected ?
		<div id={`${props.id}-file-input-canvas`} style={{padding: "1vh 0vw"}}
		 	onClick={() => {document.getElementById(`${props.id}-file-input`).click()}}>
			<Document file={pdfDocument} onLoadSuccess={() => {}} onLoadError={console.log}>
				<Page pageNumber={1} width={docWidth}/>
			</Document>
		</div> :
		<div id={`${props.id}-file-input-button`}
			className={`${props.className || "paralian-theme-level-1"} w3-display-container`}
			style={{padding: "2vh 0vw", backgroundSize: "cover", minHeight: "16vh"}}
			onClick={() => {document.getElementById(`${props.id}-file-input`).click()}}>
			<MobileFontAwesome icon={ICONS.FILE_PDF} style={{fontSize: "12vh", opacity: "0.25"}}
				className={"w3-display-middle"}/>
			<MobileFontAwesome icon={ICONS.UPLOAD} style={{fontSize: "6vh"}} className={"w3-display-middle"}/>
		</div>
	return <>
		<input type={"file"} id={`${props.id}-file-input`} name={"file"} accept={"application/pdf"} required={false}
			style={{opacity: "0", height: "0px", display: "none"}} form={props.form}
				onChange={() => {
					try {
						const fileElement = document.getElementById(`${props.id}-file-input`).files[0]
						setDocument(URL.createObjectURL(fileElement))
						setPdfSelected(true)
					} catch (e) {
						console.log(e)
					}}}/>
		{label}
		<label htmlFor={`${props.id}-file-input-button`} style={{display: "none"}}>{props.label}</label>
		{fileDiv}
	</>
}

export default MobileFileUploadCanvas