import {faShieldCheck as fasShieldCheck,faGasPump as fasGasPump, faOutlet as fasOutlet, faStar as fasStar,
	faStarHalfAlt as fasStarHalfAlt, faCheckDouble as fasCheckDouble} from '@fortawesome/pro-solid-svg-icons'
import {faSlidersSimple, faFolderOpen, faICursor, faRepeatAlt, faFireExtinguisher, faFire, faPlug, faBurn, faMountain,
	faDotCircle, faMinusCircle, faCashRegister, faCoin, faBellSlash, faDollyFlatbedEmpty, faHandHoldingUsd, faReply,
	faCommentDots, faUserHardHat, faClipboardListCheck, faHeartCircle, faFrown, faAward, faTrophyAlt, faCookieBite,
	faHandshakeAlt, faOctagon, faShareAlt, faShare, faCrown, faCommentAltCheck, faCommentAltTimes, faPeopleArrows,
	faUsers, faPaperPlane, faRadar, faIdBadge, faArrowsAlt, faPlay, faPause, faStop, faStepForward, faStepBackward,
	faSignInAlt, faStreetView, faDogLeashed, faRacquet, faGolfClub, faBasketballBall, faSwimmer, faMessageText,
	faWaterLower, faLandmark, faPrescription, faDog, faCatSpace, faPaw, faChild, faHashtag, faHandSparkles, faSpa,
	faMedkit, faTooth, faCut, faLandmarkAlt, faCarWash, faBed, faGamepadAlt, faFish, faToilet, faShower, faPassport,
	faHelicopter, faSnowflakes, faWasher, faDryerAlt, faTruckRamp, faTintSlash, faOilCan, faDumpster, faSackDollar,
	faDesktop, faDumbbell, faCar, faGlassMartiniAlt, faUtensils, faShieldCheck, faUserShield, faUserUnlock, faBrowser,
	faCoins, faDuck, faWhale, faEllipsisH, faAt, faUserAstronaut, faImage, faCircleNotch, faPencilPaintbrush, faTags,
	faUmbrellaBeach, faDoorOpen, faPalette, faBadgeCheck, faGlobeAmericas, faUserHeadset, faMailbox, faStoreAlt,
	faPaintRoller, faPaintBrush, faHorizontalRule, faCheckDouble, faUserTie, faWallet, faCreditCard, faSimCard,
	faSadTear, faAlarmClock, faCalendarStar, faClock, faCameraAlt, faConciergeBell, faMapMarkerAlt, faCrosshairs,
	faTachometer, faTachometerSlowest, faTachometerSlow, faTachometerFast, faTachometerFastest, faTachometerAverage,
	faFilePdf, faShield, faUserSecret, faEye, faUndo, faArrowAltLeft, faUpload, faCheck, faSignOut, faMapPin, faStore,
	faMapMarker, faStar, faStarExclamation, faStarHalfAlt, faRulerTriangle, faBars, faCog, faUnlockAlt, faQuestion,
	faQuestionCircle, faExclamation, faEyeSlash, faInfo, faPhone, faEnvelope, faChevronCircleRight, faChevronCircleLeft,
	faLanguage, faPlus, faKey, faTrash, faUser, faShip, faRoute, faAnchor, faQrcode, faCalendar, faCalendarAlt, faTruck,
	faTruckFast, faBellOn, faBell, faTools, faExclamationTriangle, faDownload, faFile, faIdCard, faFileAlt, faCertificate,
	faFileCertificate, faDiploma, faSyncAlt, faPencil, faCompass, faPlusCircle, faAngleDoubleDown, faAngleDoubleUp,
	faRandom, faLink, faUnlink, faInfoCircle, faBan, faGlobe, faLocationArrow, faWind, faWater, faMapMarkedAlt,
	faCheckCircle, faSortUp, faSortDown, faFilter, faFaucet, faWifi, faNetworkWired, faTvRetro, faBolt, faOutlet,
	faTimesCircle, faExclamationCircle, faSpinner, faHourglassStart, faHourglassHalf, faHourglassEnd, faWrench, faParking,
	faSnowboarding, faForklift, faGasPump, faSoap, faShuttleVan, faEnvelopeOpen, faDove, faShieldSlash, faChevronsDown,
	faPump, faWhiskeyGlassIce, faUserPilot, faEngine} from '@fortawesome/pro-duotone-svg-icons'
import {faPhone as farPhone, faMapPin as farMapPin, faEnvelope as farEnvelope, faCut as farCut, faOutlet as farOutlet,
	faGasPump as farGasPump, faStar as farStar, faStarHalfAlt as farStarHalfAlt} from '@fortawesome/pro-regular-svg-icons'
import {faStripeS, faStripe, faFortAwesome,	faCcAmazonPay, faCcApplePay, faCcDinersClub, faCcJcb,	faCcPaypal,
	faCcStripe,	faCcDiscover,	faCcAmex,	faCcMastercard,	faCcVisa, faAccessibleIcon, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import {faGasPump as falGasPump, faPlug as falPlug, faFaucet as falFaucet} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React from "react"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string} props.label
 * @param {React.HTMLAttributes.className} props.className
 * @param {function} props.onClick
 * @param {React.HTMLAttributes.style} props.style
 * @param {string[]} props.icon
 * @returns {JSX.Element}
 * @constructor
 */
const MobileFontAwesome = props => {
	const fad = {
		faEngine: <FontAwesomeIcon icon={faEngine} style={{}}/>,
		faUserPilot: <FontAwesomeIcon icon={faUserPilot} style={{}}/>,
		faWhiskeyGlassIce: <FontAwesomeIcon icon={faWhiskeyGlassIce} style={{}}/>,
		faPump: <FontAwesomeIcon icon={faPump} style={{}}/>,
		faMessageText: <FontAwesomeIcon icon={faMessageText} style={{}}/>,
		faSlidersSimple: <FontAwesomeIcon icon={faSlidersSimple} style={{}}/>,
		faFolderOpen: <FontAwesomeIcon icon={faFolderOpen} style={{}}/>,
		faICursor: <FontAwesomeIcon icon={faICursor} style={{}}/>,
		faRepeatAlt: <FontAwesomeIcon icon={faRepeatAlt} style={{}}/>,
		faFireExtinguisher: <FontAwesomeIcon icon={faFireExtinguisher} style={{}}/>,
		faFire: <FontAwesomeIcon icon={faFire} style={{}}/>,
		faPlug: <FontAwesomeIcon icon={faPlug} style={{}}/>,
		faBurn: <FontAwesomeIcon icon={faBurn} style={{}}/>,
		faMountain: <FontAwesomeIcon icon={faMountain} style={{}}/>,
		faDotCircle: <FontAwesomeIcon icon={faDotCircle} style={{}}/>,
		faMinusCircle: <FontAwesomeIcon icon={faMinusCircle} style={{}}/>,
		faCashRegister: <FontAwesomeIcon icon={faCashRegister} style={{}}/>,
		faCoin: <FontAwesomeIcon icon={faCoin} style={{}}/>,
		faBellSlash: <FontAwesomeIcon icon={faBellSlash} style={{}}/>,
		faDollyFlatbedEmpty: <FontAwesomeIcon icon={faDollyFlatbedEmpty} style={{}}/>,
		faHandHoldingUsd: <FontAwesomeIcon icon={faHandHoldingUsd} style={{}}/>,
		faReply: <FontAwesomeIcon icon={faReply} style={{}}/>,
		faCommentDots: <FontAwesomeIcon icon={faCommentDots} style={{}}/>,
		faUserHardHat: <FontAwesomeIcon icon={faUserHardHat} style={{}}/>,
		faClipboardListCheck: <FontAwesomeIcon icon={faClipboardListCheck} style={{}}/>,
		faHeartCircle: <FontAwesomeIcon icon={faHeartCircle} style={{}}/>,
		faFrown: <FontAwesomeIcon icon={faFrown} style={{}}/>,
		faAward: <FontAwesomeIcon icon={faAward} style={{}}/>,
		faTrophyAlt: <FontAwesomeIcon icon={faTrophyAlt} style={{}}/>,
		faCookieBite: <FontAwesomeIcon icon={faCookieBite} style={{}}/>,
		faHandshakeAlt: <FontAwesomeIcon icon={faHandshakeAlt} style={{}}/>,
		faOctagon: <FontAwesomeIcon icon={faOctagon} style={{}}/>,
		faShareAlt: <FontAwesomeIcon icon={faShareAlt} style={{}}/>,
		faShare: <FontAwesomeIcon icon={faShare} style={{}}/>,
		faCrown: <FontAwesomeIcon icon={faCrown} style={{}}/>,
		faCommentAltCheck: <FontAwesomeIcon icon={faCommentAltCheck} style={{}}/>,
		faCommentAltTimes: <FontAwesomeIcon icon={faCommentAltTimes} style={{}}/>,
		faPeopleArrows: <FontAwesomeIcon icon={faPeopleArrows} style={{}}/>,
		faUsers: <FontAwesomeIcon icon={faUsers} style={{}}/>,
		faPaperPlane: <FontAwesomeIcon icon={faPaperPlane} style={{}}/>,
		faRadar: <FontAwesomeIcon icon={faRadar} style={{}}/>,
		faIdBadge: <FontAwesomeIcon icon={faIdBadge} style={{}}/>,
		faArrowsAlt: <FontAwesomeIcon icon={faArrowsAlt} style={{}}/>,
		faPlay: <FontAwesomeIcon icon={faPlay} style={{}}/>,
		faPause: <FontAwesomeIcon icon={faPause} style={{}}/>,
		faStop: <FontAwesomeIcon icon={faStop} style={{}}/>,
		faStepForward: <FontAwesomeIcon icon={faStepForward} style={{}}/>,
		faStepBackward: <FontAwesomeIcon icon={faStepBackward} style={{}}/>,
		faSignInAlt: <FontAwesomeIcon icon={faSignInAlt} style={{}}/>,
		faStreetView: <FontAwesomeIcon icon={faStreetView} style={{}}/>,
		faDogLeashed: <FontAwesomeIcon icon={faDogLeashed} style={{}}/>,
		faAccessibleIcon: <FontAwesomeIcon icon={faAccessibleIcon} style={{}}/>,
		faRacquet: <FontAwesomeIcon icon={faRacquet} style={{}}/>,
		faGolfClub: <FontAwesomeIcon icon={faGolfClub} style={{}}/>,
		faBasketballBall: <FontAwesomeIcon icon={faBasketballBall} style={{}}/>,
		faSwimmer: <FontAwesomeIcon icon={faSwimmer} style={{}}/>,
		faWaterLower: <FontAwesomeIcon icon={faWaterLower} style={{}}/>,
		faLandmark: <FontAwesomeIcon icon={faLandmark} style={{}}/>,
		faPrescription: <FontAwesomeIcon icon={faPrescription} style={{}}/>,
		faDog: <FontAwesomeIcon icon={faDog} style={{}}/>,
		faCatSpace: <FontAwesomeIcon icon={faCatSpace} style={{}}/>,
		faPaw: <FontAwesomeIcon icon={faPaw} style={{}}/>,
		faChild: <FontAwesomeIcon icon={faChild} style={{}}/>,
		faHashtag: <FontAwesomeIcon icon={faHashtag} style={{}}/>,
		faHandSparkles: <FontAwesomeIcon icon={faHandSparkles} style={{}}/>,
		faSpa: <FontAwesomeIcon icon={faSpa} style={{}}/>,
		faMedkit: <FontAwesomeIcon icon={faMedkit} style={{}}/>,
		faTooth: <FontAwesomeIcon icon={faTooth} style={{}}/>,
		faCut: <FontAwesomeIcon icon={faCut} style={{}}/>,
		faLandmarkAlt: <FontAwesomeIcon icon={faLandmarkAlt} style={{}}/>,
		faCarWash: <FontAwesomeIcon icon={faCarWash} style={{}}/>,
		faBed: <FontAwesomeIcon icon={faBed} style={{}}/>,
		faGamepadAlt: <FontAwesomeIcon icon={faGamepadAlt} style={{}}/>,
		faFish: <FontAwesomeIcon icon={faFish} style={{}}/>,
		faToilet: <FontAwesomeIcon icon={faToilet} style={{}}/>,
		faShower: <FontAwesomeIcon icon={faShower} style={{}}/>,
		faPassport: <FontAwesomeIcon icon={faPassport} style={{}}/>,
		faHelicopter: <FontAwesomeIcon icon={faHelicopter} style={{}}/>,
		faSnowflakes: <FontAwesomeIcon icon={faSnowflakes} style={{}}/>,
		faWasher: <FontAwesomeIcon icon={faWasher} style={{}}/>,
		faDryerAlt: <FontAwesomeIcon icon={faDryerAlt} style={{}}/>,
		faTruckRamp: <FontAwesomeIcon icon={faTruckRamp} style={{}}/>,
		faTintSlash: <FontAwesomeIcon icon={faTintSlash} style={{}}/>,
		faOilCan: <FontAwesomeIcon icon={faOilCan} style={{}}/>,
		faDumpster: <FontAwesomeIcon icon={faDumpster} style={{}}/>,
		faSackDollar: <FontAwesomeIcon icon={faSackDollar} style={{}}/>,
		faSackDollarShake: <FontAwesomeIcon icon={faSackDollar} className={"fa-shake"} style={{}}/>,
		faDesktop: <FontAwesomeIcon icon={faDesktop} style={{}}/>,
		faDumbbell: <FontAwesomeIcon icon={faDumbbell} style={{}}/>,
		faCar: <FontAwesomeIcon icon={faCar} style={{}}/>,
		faGlassMartiniAlt: <FontAwesomeIcon icon={faGlassMartiniAlt} style={{}}/>,
		faUtensils: <FontAwesomeIcon icon={faUtensils} style={{}}/>,
		faShieldCheck: <FontAwesomeIcon icon={faShieldCheck} style={{}}/>,
		faUserShield: <FontAwesomeIcon icon={faUserShield} style={{}}/>,
		faUserUnlock: <FontAwesomeIcon icon={faUserUnlock} style={{}}/>,
		faBrowser: <FontAwesomeIcon icon={faBrowser} style={{}}/>,
		faCoins: <FontAwesomeIcon icon={faCoins} style={{}}/>,
		faDuck: <FontAwesomeIcon icon={faDuck} style={{}}/>,
		faWhale: <FontAwesomeIcon icon={faWhale} style={{}}/>,
		faEllipsisH: <FontAwesomeIcon icon={faEllipsisH} style={{}}/>,
		faAt: <FontAwesomeIcon icon={faAt} style={{}}/>,
		faUserAstronaut: <FontAwesomeIcon icon={faUserAstronaut} style={{}}/>,
		faImage: <FontAwesomeIcon icon={faImage} style={{}}/>,
		faCircleNotch: <FontAwesomeIcon icon={faCircleNotch} style={{}}/>,
		faPencilPaintbrush: <FontAwesomeIcon icon={faPencilPaintbrush} style={{}}/>,
		faTags: <FontAwesomeIcon icon={faTags} style={{}}/>,
		faUmbrellaBeach: <FontAwesomeIcon icon={faUmbrellaBeach} style={{}}/>,
		faDoorOpen: <FontAwesomeIcon icon={faDoorOpen} style={{}}/>,
		faPalette: <FontAwesomeIcon icon={faPalette} style={{}}/>,
		faBadgeCheck: <FontAwesomeIcon icon={faBadgeCheck} style={{}}/>,
		faGlobeAmericas: <FontAwesomeIcon icon={faGlobeAmericas} style={{}}/>,
		faUserHeadset: <FontAwesomeIcon icon={faUserHeadset} style={{}}/>,
		faMailbox: <FontAwesomeIcon icon={faMailbox} style={{}}/>,
		faStoreAlt: <FontAwesomeIcon icon={faStoreAlt} style={{}}/>,
		faPaintRoller: <FontAwesomeIcon icon={faPaintRoller} style={{}}/>,
		faPaintBrush: <FontAwesomeIcon icon={faPaintBrush} style={{}}/>,
		faHorizontalRule: <FontAwesomeIcon icon={faHorizontalRule} style={{}}/>,
		faCheckDouble: <FontAwesomeIcon icon={faCheckDouble} style={{}}/>,
		faUserTie: <FontAwesomeIcon icon={faUserTie} style={{}}/>,
		faWallet: <FontAwesomeIcon icon={faWallet} style={{}}/>,
		faCreditCard: <FontAwesomeIcon icon={faCreditCard} style={{}}/>,
		faCreditCardShake: <FontAwesomeIcon icon={faCreditCard} className={"fa-shake"} style={{}}/>,
		faSimCard: <FontAwesomeIcon icon={faSimCard} style={{}}/>,
		faSadTear: <FontAwesomeIcon icon={faSadTear} style={{}}/>,
		faAlarmClock: <FontAwesomeIcon icon={faAlarmClock} style={{}}/>,
		faCalendarStar: <FontAwesomeIcon icon={faCalendarStar} style={{}}/>,
		faClock: <FontAwesomeIcon icon={faClock} style={{}}/>,
		faCameraAlt: <FontAwesomeIcon icon={faCameraAlt} style={{}}/>,
		faConciergeBell: <FontAwesomeIcon icon={faConciergeBell} style={{}}/>,
		faMapMarkerAlt: <FontAwesomeIcon icon={faMapMarkerAlt} style={{}}/>,
		faMapMarkerAltBounce: <FontAwesomeIcon icon={faMapMarkerAlt} className={"fa-bounce"} style={{}}/>,
		faCrosshairs: <FontAwesomeIcon icon={faCrosshairs} style={{}}/>,
		faTachometer: <FontAwesomeIcon icon={faTachometer} style={{}}/>,
		faTachometerSlowest: <FontAwesomeIcon icon={faTachometerSlowest} style={{}}/>,
		faTachometerSlow: <FontAwesomeIcon icon={faTachometerSlow} style={{}}/>,
		faTachometerFast: <FontAwesomeIcon icon={faTachometerFast} style={{}}/>,
		faTachometerFastest: <FontAwesomeIcon icon={faTachometerFastest} style={{}}/>,
		faTachometerAverage: <FontAwesomeIcon icon={faTachometerAverage} style={{}}/>,
		faFilePdf: <FontAwesomeIcon icon={faFilePdf} style={{}}/>,
		faShield: <FontAwesomeIcon icon={faShield} style={{}}/>,
		faUserSecret: <FontAwesomeIcon icon={faUserSecret} style={{}}/>,
		faEye: <FontAwesomeIcon icon={faEye} style={{}}/>,
		faUndo: <FontAwesomeIcon icon={faUndo} style={{}}/>,
		faArrowAltLeft: <FontAwesomeIcon icon={faArrowAltLeft} style={{}}/>,
		faUpload: <FontAwesomeIcon icon={faUpload} style={{}}/>,
		faCheck: <FontAwesomeIcon icon={faCheck} style={{}}/>,
		faSignOut: <FontAwesomeIcon icon={faSignOut} style={{}}/>,
		faMapPin: <FontAwesomeIcon icon={faMapPin} style={{}}/>,
		faStore: <FontAwesomeIcon icon={faStore} style={{}}/>,
		faMapMarker: <FontAwesomeIcon icon={faMapMarker} style={{}}/>,
		faStar: <FontAwesomeIcon icon={faStar} style={{}}/>,
		faStarExclamation: <FontAwesomeIcon icon={faStarExclamation} style={{}}/>,
		faStarHalfAlt: <FontAwesomeIcon icon={faStarHalfAlt} style={{}}/>,
		faRulerTriangle: <FontAwesomeIcon icon={faRulerTriangle} style={{}}/>,
		faBars: <FontAwesomeIcon icon={faBars} style={{}}/>,
		faCog: <FontAwesomeIcon icon={faCog} style={{}}/>,
		faUnlockAlt: <FontAwesomeIcon icon={faUnlockAlt} style={{}}/>,
		faQuestion: <FontAwesomeIcon icon={faQuestion} style={{}}/>,
		faQuestionCircle: <FontAwesomeIcon icon={faQuestionCircle} style={{}}/>,
		faExclamation: <FontAwesomeIcon icon={faExclamation} style={{}}/>,
		faEyeSlash: <FontAwesomeIcon icon={faEyeSlash} style={{}}/>,
		faInfo: <FontAwesomeIcon icon={faInfo} style={{}}/>,
		faPhone: <FontAwesomeIcon icon={faPhone} style={{}}/>,
		faEnvelope: <FontAwesomeIcon icon={faEnvelope} style={{}}/>,
		faChevronCircleRight: <FontAwesomeIcon icon={faChevronCircleRight} style={{}}/>,
		faChevronCircleLeft: <FontAwesomeIcon icon={faChevronCircleLeft} style={{}}/>,
		faLanguage: <FontAwesomeIcon icon={faLanguage} style={{}}/>,
		faPlus: <FontAwesomeIcon icon={faPlus} style={{}}/>,
		faKey: <FontAwesomeIcon icon={faKey} style={{}}/>,
		faTrash: <FontAwesomeIcon icon={faTrash} style={{}}/>,
		faUser: <FontAwesomeIcon icon={faUser} style={{}}/>,
		faShip: <FontAwesomeIcon icon={faShip} style={{}}/>,
		faRoute: <FontAwesomeIcon icon={faRoute} style={{}}/>,
		faAnchor: <FontAwesomeIcon icon={faAnchor} style={{}}/>,
		faQrcode: <FontAwesomeIcon icon={faQrcode} style={{}}/>,
		faCalendar: <FontAwesomeIcon icon={faCalendar} style={{}}/>,
		faCalendarAlt: <FontAwesomeIcon icon={faCalendarAlt} style={{}}/>,
		faTruck: <FontAwesomeIcon icon={faTruck} style={{}}/>,
		faTruckFast: <FontAwesomeIcon icon={faTruckFast} style={{}}/>,
		faBellOn: <FontAwesomeIcon icon={faBellOn} style={{}}/>,
		faBellOnShake: <FontAwesomeIcon icon={faBellOn} className={"fa-shake"} style={{"--fa-animation-iteration-count": "5"}}/>,
		faBell: <FontAwesomeIcon icon={faBell}  style={{}}/>,
		faTools: <FontAwesomeIcon icon={faTools} style={{}}/>,
		faExclamationTriangle: <FontAwesomeIcon icon={faExclamationTriangle} style={{}}/>,
		faDownload: <FontAwesomeIcon icon={faDownload} style={{}}/>,
		faFile: <FontAwesomeIcon icon={faFile} style={{}}/>,
		faIdCard: <FontAwesomeIcon icon={faIdCard} style={{}}/>,
		faFileAlt: <FontAwesomeIcon icon={faFileAlt} style={{}}/>,
		faCertificate: <FontAwesomeIcon icon={faCertificate} style={{}}/>,
		faFileCertificate: <FontAwesomeIcon icon={faFileCertificate} style={{}}/>,
		faDiploma: <FontAwesomeIcon icon={faDiploma} style={{}}/>,
		faSyncAlt: <FontAwesomeIcon icon={faSyncAlt} style={{}}/>,
		faPencil: <FontAwesomeIcon icon={faPencil} style={{}}/>,
		faCompass: <FontAwesomeIcon icon={faCompass} style={{}}/>,
		faPlusCircle: <FontAwesomeIcon icon={faPlusCircle} style={{}}/>,
		faAngleDoubleDown: <FontAwesomeIcon icon={faAngleDoubleDown} style={{}}/>,
		faAngleDoubleUp: <FontAwesomeIcon icon={faAngleDoubleUp} style={{}}/>,
		faRandom: <FontAwesomeIcon icon={faRandom} style={{}}/>,
		faLink: <FontAwesomeIcon icon={faLink} style={{}}/>,
		faUnlink: <FontAwesomeIcon icon={faUnlink} style={{}}/>,
		faInfoCircle: <FontAwesomeIcon icon={faInfoCircle} style={{}}/>,
		faBan: <FontAwesomeIcon icon={faBan} style={{}}/>,
		faGlobe: <FontAwesomeIcon icon={faGlobe} style={{}}/>,
		faLocationArrow: <FontAwesomeIcon icon={faLocationArrow} style={{}}/>,
		faWind: <FontAwesomeIcon icon={faWind} style={{}}/>,
		faWater: <FontAwesomeIcon icon={faWater} style={{}}/>,
		faMapMarkedAlt: <FontAwesomeIcon icon={faMapMarkedAlt} style={{}}/>,
		faCheckCircle: <FontAwesomeIcon icon={faCheckCircle} style={{}}/>,
		faSortUp: <FontAwesomeIcon icon={faSortUp} style={{}}/>,
		faSortDown: <FontAwesomeIcon icon={faSortDown} style={{}}/>,
		faFilter: <FontAwesomeIcon icon={faFilter} style={{}}/>,
		faFaucet: <FontAwesomeIcon icon={faFaucet} style={{}}/>,
		faWifi: <FontAwesomeIcon icon={faWifi} style={{}}/>,
		faNetworkWired: <FontAwesomeIcon icon={faNetworkWired} style={{}}/>,
		faTvRetro: <FontAwesomeIcon icon={faTvRetro} style={{}}/>,
		faBolt: <FontAwesomeIcon icon={faBolt} style={{}}/>,
		faOutlet: <FontAwesomeIcon icon={faOutlet} style={{}}/>,
		faTimesCircle: <FontAwesomeIcon icon={faTimesCircle} style={{}}/>,
		faExclamationCircle: <FontAwesomeIcon icon={faExclamationCircle} style={{}}/>,
		faSpinner: <FontAwesomeIcon icon={faSpinner} style={{}}/>,
		faHourglassStart: <FontAwesomeIcon icon={faHourglassStart} style={{}}/>,
		faHourglassHalf: <FontAwesomeIcon icon={faHourglassHalf} style={{}}/>,
		faHourglassEnd: <FontAwesomeIcon icon={faHourglassEnd} style={{}}/>,
		faWrench: <FontAwesomeIcon icon={faWrench} style={{}}/>,
		faParking: <FontAwesomeIcon icon={faParking} style={{}}/>,
		faSnowboarding: <FontAwesomeIcon icon={faSnowboarding} style={{}}/>,
		faForklift: <FontAwesomeIcon icon={faForklift} style={{}}/>,
		faGasPump: <FontAwesomeIcon icon={faGasPump} style={{}}/>,
		faSoap: <FontAwesomeIcon icon={faSoap} style={{}}/>,
		faShuttleVan: <FontAwesomeIcon icon={faShuttleVan} style={{}}/>,
		faEnvelopeOpen: <FontAwesomeIcon icon={faEnvelopeOpen} style={{}}/>,
		faDove: <FontAwesomeIcon icon={faDove} style={{}}/>,
		faShieldSlash: <FontAwesomeIcon icon={faShieldSlash} style={{}}/>,
		faChevronsDown: <FontAwesomeIcon icon={faChevronsDown} style={{}}/>,
		faChevronsDownBounce: <FontAwesomeIcon icon={faChevronsDown} className={"fa-bounce"} style={{"--fa-animation-duration": "5s", "--fa-bounce-height": "5vh"}}/>
	}
	const far = {
		faPhone: <FontAwesomeIcon icon={farPhone}/>,
		faMapPin: <FontAwesomeIcon icon={farMapPin}/>,
		faEnvelope: <FontAwesomeIcon icon={farEnvelope}/>,
		faCut: <FontAwesomeIcon icon={farCut}/>,
		faOutlet: <FontAwesomeIcon icon={farOutlet}/>,
		faGasPump: <FontAwesomeIcon icon={farGasPump}/>,
		faStar: <FontAwesomeIcon icon={farStar}/>,
		faStarHalfAlt: <FontAwesomeIcon icon={farStarHalfAlt}/>
	}
	const fab = {
		faWhatsapp: <FontAwesomeIcon icon={faWhatsapp}/>,
		faStripeS: <FontAwesomeIcon icon={faStripeS}/>,
		faStripe: <FontAwesomeIcon icon={faStripe}/>,
		faStripeBeat: <FontAwesomeIcon icon={faStripe} className={"fa-beat"}/>,
		faFortAwesome: <FontAwesomeIcon icon={faFortAwesome}/>,
		faCcAmazonPay: <FontAwesomeIcon icon={faCcAmazonPay}/>,
		faCcApplePay: <FontAwesomeIcon icon={faCcApplePay}/>,
		faCcDinersClub: <FontAwesomeIcon icon={faCcDinersClub}/>,
		faCcJcb: <FontAwesomeIcon icon={faCcJcb}/>,
		faCcPaypal: <FontAwesomeIcon icon={faCcPaypal}/>,
		faCcStripe: <FontAwesomeIcon icon={faCcStripe}/>,
		faCcDiscover: <FontAwesomeIcon icon={faCcDiscover}/>,
		faCcAmex: <FontAwesomeIcon icon={faCcAmex}/>,
		faCcMastercard: <FontAwesomeIcon icon={faCcMastercard}/>,
		faCcVisa: <FontAwesomeIcon icon={faCcVisa}/>
	}
	const fas = {
		faGasPump: <FontAwesomeIcon icon={fasGasPump}/>,
		faOutlet: <FontAwesomeIcon icon={fasOutlet}/>,
		faStar: <FontAwesomeIcon icon={fasStar}/>,
		faStarHalfAlt: <FontAwesomeIcon icon={fasStarHalfAlt}/>,
		faShieldCheck: <FontAwesomeIcon icon={fasShieldCheck}/>,
		faCheckDouble: <FontAwesomeIcon icon={fasCheckDouble}/>
	}
	const fal = {
		faGasPump: <FontAwesomeIcon icon={falGasPump}/>,
		faPlug: <FontAwesomeIcon icon={falPlug}/>,
		faFaucet: <FontAwesomeIcon icon={falFaucet}/>
	}
	const cursor = props.onClick ? "pointer" : null
	let icon = <></>
	try {
		const cap = str => str.replace(/(^|[\s-])\S/g, match => match.toUpperCase())
		const prefix = props.icon[0]
		const sufix = props.icon[1]
		const iconName = "fa" + cap(`${sufix.charAt(0).toUpperCase() + sufix.slice(1)}`).replace(/-/g,"")
		switch (prefix) {
			case "far":
				icon = far[iconName]
				break
			case "fab":
				icon = fab[iconName]
				break
			case "fas":
				icon = fas[iconName]
				break
			case "fal":
				icon = fal[iconName]
				break
			default:
				icon =fad[iconName]
				if (props.selected) {
					// console.log("before", props.style.opacity, icon.props, icon.props.style)
					if (icon.props.style) {
						icon.props.style["--fa-secondary-opacity"] = "1"
						icon.props.style["--fa-secondary-color"] = "darkred"
					}
					// console.log("after", icon)
					if (props.style.opacity) {props.style.opacity = null}
				} else {
					// console.log(props.style)
					if (icon.props.style && props.style && props.style.opacity) {
						// console.log("here", props.style.opacity)
						icon.props.style["--fa-secondary-opacity"] = `${Number(props.style.opacity) * 0.5}`
						icon.props.style["--fa-primary-opacity"] = `${props.style.opacity}`
						// console.log("icon.props")
						props.style.opacity = null
					}
				}
				break
		}
		// console.log(icon)
	} catch (e) {
		console.log(e)
		return <></>
	}
	return <>
		<label htmlFor={props.id} style={{display: "none"}}>{props.label}</label>
		<i id={props.id} style={{cursor: cursor, textRendering: "optimizeLegibility", ...props.style}} data-a={`${props.dataA}`}
			 className={`${props.onClick ? "w3-ripple " : ""}${props.className || ""}`} onClick={props.onClick}>
			{icon}
		</i>
	</>
}

export default MobileFontAwesome