import React, {useRef, useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS, PAGE_STATES} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {Elements} from "@stripe/react-stripe-js"
import {loadStripe} from "@stripe/stripe-js/pure"
import {getFromStorage, stringDateAndTimeToDate, translate} from "../../../../globals/functions/client/localFunctions"
import {createStripeConnectPaymentIntent} from "../../../../globals/functions/client/serverFunctions"
import Modal from "../../../../globals/components/Modal"
import MobileStripeCreditCardPayment from "../../payment/MobileStripeCreditCardPayment"
import MobileNewOrderSubmit from "../../orders/MobileNewOrderSubmit"
import Currency from "../../../../globals/classes/shared/Currency"
import Time from "../../../../globals/classes/shared/Time"
import Servicio from "../../../../globals/classes/shared/Servicio"

const MobileVesselCleaningPayment = props => {
	const [showTermsOfSale, setShowTermsOfSale] = useState(false)
	const order = getFromStorage("order", "session")
	const currency = new Currency(order.provider.currency)
	const currentState = store.getState()
	const levelOne = currentState.window.current.levelOne
	// console.log(props.services)
	const termsOfSale = <Modal title={translate(DICTIONARY.TERMS_OF_SALE.X)} icon={ICONS.HANDSHAKE} padding={"0vh"}
		onClick={() => setShowTermsOfSale(false)} id={"terms-of-sale"} background={"white"}
		body={
			<div style={{textAlign: "justify", padding: "0vh 2vh"}}>
				<p>{translate(DICTIONARY.TERMS_OF_SALE_MESSAGE_1.X)}</p>
				<p>{translate(DICTIONARY.TERMS_OF_SALE_MESSAGE_2.X)}</p>
				<p>{translate(DICTIONARY.TERMS_OF_SALE_THIRD_PARTY_PART_1.X)}</p>
				<p>{translate(DICTIONARY.TERMS_OF_SALE_THIRD_PARTY_PART_2.X)}</p>
			</div>
		}/>
	// console.log({stripeAccount: order.provider.stripeId})
	const stripePromise = useRef(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY))
	/*
	const stripePromise = useRef(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY,
		{stripeAccount: order.provider.stripeId}))
	 */
	const services = order.items.map(v => {
		const service = new Servicio(props.services.byIndex(v.id))
		// console.log(service, service.lastTwoLabelsAsString, service.labels)
		return <li key={service.label}>{service.lastTwoLabelsAsString}</li>
	})
	const summary = <div className={"paralian-theme-level-1"} style={{padding: "2vh", textAlign: "left"}}>
		<div>{`→ ${translate(DICTIONARY.VESSEL.X)}: ${order.vessels[0].label}`}</div>
		<div>{`→ ${translate(DICTIONARY.MARINA.X)}: ${order.marinas[0].label}`}</div>
		<div>{`→ ${translate(DICTIONARY.PROVIDER.X)}: ${order.provider.label}`}</div>
		<div>{`→ ${translate(DICTIONARY.DUE_DATE.X)}: ` +
		`${stringDateAndTimeToDate(order.dueDate + "-" + (new Time(order.dueTime)).as24hrString)
			.toLocaleString(undefined, {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})}`}</div>
		<div>{`→ ${translate(DICTIONARY.SERVICES.X)}:`}</div>
		<ul style={{margin: "unset"}}>{services}</ul>
		<div style={{marginTop: "2vh"}}>
			{`→ ${translate(DICTIONARY.TOTAL_DUE.X)}: ${currency.asText(order.total)}`}
		</div>
	</div>
	const isOrganization = !!props.organization.id
	const parentObject = isOrganization ? props.organization : props.account
	const refreshParent = isOrganization ? props.refreshOrganization : props.refreshAccount
	const params = {
		items: order.services,
		isOrg: isOrganization,
		...order
	}
	const onSuccess = () => {
		/* TODO: refresh orders & notifications */
		refreshParent()
		store.dispatch({
			type: actions.SET_CURRENT_WINDOW,
			values: {window: PAGE_STATES[levelOne].MAIN.REQUESTS.REQUEST_SERVICE.VESSEL_CLEANING.SUCCESS.X}})
	}
	const getSecret = async paymentMethod => {
		// console.log("getSecret called")
		try {
			params.paymentMethod = paymentMethod
			const response = await createStripeConnectPaymentIntent(params)
			// console.log(response)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				return response.payload
			}
			else if (response.status === SERVER_RESPONSE_STATE.FAIL && response.payload.reason === "paid") {
				// fadingMessage("paymentSuccess", "Success", "", "w3-pale-green", ICONS.CHECK)
				onSuccess()
				return null
			}
			return null
		} catch (e) {
			console.log(e)
			return null
		}
	}
	return order.total === 0 ? <MobileNewOrderSubmit setShowTermsOfSale={setShowTermsOfSale} order={params}
		showTermsOfSale={showTermsOfSale} termsOfSale={termsOfSale} summary={summary} onSuccess={onSuccess}/> : <div>
		<Elements stripe={stripePromise.current}>
			<MobileStripeCreditCardPayment parentObject={parentObject} currency={currency.code} termsOfSale={termsOfSale}
				setShowTermsOfSale={setShowTermsOfSale}	showTermsOfSale={showTermsOfSale} summary={summary} //  setShow={() => {}}
				hideSaveOption={true} onSuccess={() => {
					// console.log("on success")
					store.dispatch({
						type: actions.SET_CURRENT_WINDOW,
						values: {window: PAGE_STATES[levelOne].MAIN.REQUESTS.VESSEL_CLEANING.SUCCESS.X}})
					// setTimeout(() => store.dispatch({type: actions.SET_CURRENT_WINDOW,
						// values: {window: PAGES.COMMON.MAIN.REQUESTS.REQUEST_SERVICE.X}}), 3000)
					/* fadingMessage("vessel-cleaning-payment-success", "Success", "Your payment has been processed " +
					"and your order has been entered. You should be receiving a confirmation from your provider shortly.") */
			}} refreshAccount={async () => {}} isOrganization={isOrganization}
				getSecret={getSecret}/>
		</Elements>
	</div>
}

export default MobileVesselCleaningPayment