import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {addProviderToFavorites} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.favorite
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const SetAsFavoriteButton = props => {
	return props.order.buttons.favorite ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"favorite"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.ADD_PROVIDER_TO_FAVORITES.X)}}
				left={{icon: ICONS.HEART_CIRCLE}} className={"paralian-theme-level-1"}
				padding={"1vh"} style={{width: "100%"}} onClick={async () => {
				try {
					const response = await addProviderToFavorites(props.order.id)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						fadingMessage("provider-favorite-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
						props.refreshOrders()
					}
				} catch (e) {
					console.log(e)
				}
			}}/>
		</div> : <></>
}

export default SetAsFavoriteButton