import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {updateOrgColors} from "../../../globals/functions/client/serverFunctions"
import MobileInputColorPicker from "./MobileInputColorPicker"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import Colors from "../../../globals/classes/shared/Colors"

const MobileInputColor = props => {
	const content = <div className={"w3-animate-opacity"} style={{backgroundColor: "white", padding: "1vh"}}>
		<MobileInputColorPicker id={`color-${props.id}`} color={props.color} style={props.style}/></div>
	return <MobileInputForm id={props.id} content={content} className={" "} onSubmit={async event => {
		try {
			event.preventDefault()
			const primary = props.id === "primary" ? document.getElementById("color-primary-input").value : null
			const secondary = props.id === "secondary" ? document.getElementById("color-secondary-input").value : null
			const colors = new Colors({primary: {hex: primary}, secondary: {hex: secondary}})
			const response = await updateOrgColors(colors)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				if (props.onSubmit) {props.onSubmit(primary ? colors.primary : colors.secondary)}
			}
		} catch (e) {
			console.log(e)
		}
	}}/>
}

export default MobileInputColor