import React, {useEffect} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {ccBrandToLabelAndIcon, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {string} props.id
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {boolean} props.addLabel
 * @param {function} props.setNewPaymentMethod
 * @param {function} [props.setPaymentMethod]
 * @returns {JSX.Element}
 * @constructor
 */
const MobilePaymentMethodsDropdown = props => {
	// const onChange = props.onChange ? props.onChange : () => {}
	useEffect(() => {
		const paymentMethods = props.parentObject ? props.parentObject.payment.methods.cards : []
		if (paymentMethods.length === 0) {props.setNewPaymentMethod(true)}
		if (paymentMethods.length > 0 && props.setPaymentMethod && !props.newPaymentMethod) {
			const defaultPaymentMethod = paymentMethods.find(v => {
				try {
					return v.id === props.defaultValue || props.parentObject.payment.defaultPaymentMethod
				} catch (e) {
					return false
				}
			})
			props.setPaymentMethod(defaultPaymentMethod ? defaultPaymentMethod.id : paymentMethods[0].id)
		}
	}, [props.parentObject])
	const label = props.addLabel ? `${translate(DICTIONARY.PAYMENT_METHOD.X)}: ` : ""
	const paymentMethods = props.parentObject ? props.parentObject.payment.methods.cards : []
	const list = []
	let other = {label: `${label}${translate(DICTIONARY.OTHER.X)}`, value: -1, dataId: -1}, defaultIndex = 0, defaultValue
	try {
		for (let i = 0; i < paymentMethods.length; i++) {
			const brandDisplay = ccBrandToLabelAndIcon(paymentMethods[i].brand)
			list[i] = {label: `${label}${brandDisplay.label} *${paymentMethods[i].last4}`, value: i, dataId: paymentMethods[i].id}
		}
		list.push(other)
	} catch (e) {
		console.log("error generating payment methods list", e)
	}
	try {
		const val = props.defaultValue ? props.defaultValue : props.parentObject.payment.defaultPaymentMethod
		// console.log("val", val)
		defaultIndex = list.findIndex(item => item.dataId === val)
		if (defaultIndex === -1 || defaultIndex > list.length - 1) {defaultIndex = 0}
		defaultValue = list[props.newPaymentMethod ? list.length - 1 : defaultIndex]
		// console.log("defaultIndex", defaultIndex)
		// console.log("defaultValue", defaultValue)
	} catch (e) {
		console.log(e)
	}
	// console.log("defaultIndex", defaultIndex, "list", list)
	return <MobileInputGeneric type={"dropdown"} id={`${props.id}`}
		className={props.className || "paralian-theme-level-1-flipped"} size={"1"} showLabel={props.showLabel} list={list}
		label={translate(DICTIONARY.PAYMENT_METHOD.X)} topRadius={props.topRadius} dataId={defaultValue.dataId}
		name={"marina"} form={props.form} autoFocus={false} required={true} left={props.left} multiple={false}
		defaultValue={defaultValue.value}	bottomRadius={props.bottomRadius} dataIndex={defaultIndex}
	 	onChange={() => {
			const element = document.getElementById(`${props.id}-input`)
			if (`${element.value}` === "-1") {
				element.setAttribute("data-id", "-1")
				props.setNewPaymentMethod(true)
				if (props.setPaymentMethod) {
					props.setPaymentMethod(null)
				}
			}
			else {
				element.setAttribute("data-id", list[element.value].dataId)
				props.setNewPaymentMethod(false)
				if (props.setPaymentMethod) {
					props.setPaymentMethod(list[element.value].dataId)
				}
			}
			element.setAttribute("data-index", element.value)
			/*replaceInStorage("form", "payment", {method: {id: list[element.value].dataId}}, "session")*/
			/*onChange(`${element.value}` === "-1" ? -1 : addresses[element.value])*/
		}}/>
}

export default MobilePaymentMethodsDropdown