import TextInputFilter from './TextInputFilter'
/**
 * @extends TextInputFilter
 */
class FullName extends TextInputFilter {
	minLength = 2
	maxLength = 45
	regexp = /[^\wÀ-ž-'&. ]/gi
}

export default FullName