import React, {useEffect, useState} from "react"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getLatLon} from "../../../globals/functions/client/serverFunctions"
import {setToStorage} from "../../../globals/functions/client/localFunctions"
import Coordinates from "../../../globals/classes/shared/Coordinates"
import googleMapsSetup from "../../../globals/functions/client/googleMapsSetup"
import googleMapsCleanup from "../../../globals/functions/client/mode/googleMapsCleanup"
/**
 *
 * @param {Object} props
 * @param {Coordinates} props.coordinates
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLocationPinSet = props => {
	const [coordinates, setCoordinates] = useState(props.coordinates)
	useEffect(() => {
		if (!props.coordinates.latitude || !props.coordinates.longitude) {
			const add = props.address
			const searchAddress = {city: add.city, state: add.state.name === "Puerto Rico" ? "" : add.state.name,
				country: add.state.name === "Puerto Rico" ? "Puerto Rico" : add.country.code, line1: add.line1,
				line2: add.line2, postCode: add.postCode}
			getLatLon(searchAddress).then(response => {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					if (response.payload.length > 0) {
						const location = response.payload[0].geometry.location
						const coordinates_ = new Coordinates({latitude: location.lat, longitude: location.lng})
						setToStorage("coordinates", coordinates_, "session")
						setCoordinates(coordinates_)
					}
				} else {
					setToStorage("coordinates", {latitude: 25.76166951496344, longitude: -80.19191293568784},
					"session")
					setCoordinates({latitude: 25.76166951496344, longitude: -80.19191293568784})
				}
			})
		} else {
			setToStorage("coordinates", props.coordinates, "session")
			setCoordinates(props.coordinates)
		}
	}, [props.coordinates, props.address])
	useEffect(() => {
		const initMap =
			`initMap = async () => {
				try {
					let latlng = new google.maps.LatLng(${parseFloat(coordinates.latitude)}, ${parseFloat(coordinates.longitude)})
					const myOptions = {
						zoom: 19,
						center: latlng,
						mapTypeId: google.maps.MapTypeId.SATELLITE,
						gestureHandling: 'cooperative'
					}
					map = new google.maps.Map(document.getElementById("map"), myOptions)
					const marker = new google.maps.Marker({
						position: latlng, map: map, title: "location", animation: google.maps.Animation.DROP, 
						draggable: true,
						icon: {url: 'https://maps.google.com/mapfiles/ms/micons/red.png',
							size: new google.maps.Size(32, 32), labelOrigin: new google.maps.Point(16, 40),
							anchor: new google.maps.Point(16, 32), scaledSize: new google.maps.Size(32, 32)}
						})
					google.maps.event.addListener(marker, 'dragend', (e) => {
						const newPos = marker.getPosition().toUrlValue(6)
						const newPosSplit = newPos.split(",")
						window.sessionStorage.setItem("coordinates", '{"latitude": ' + newPosSplit[0] + ', "longitude": ' + newPosSplit[1] + "}")
					})
				} catch (e) {
					console.log(e)
				}
      }`
		const mapScripts = googleMapsSetup(initMap)
		// document.getElementById("form").scrollTo(0,0)
		return googleMapsCleanup(mapScripts)
	}, [coordinates])
	return <div className={"paralian-theme-level-1-flipped"} id={"map"} style={{fontSize: "3vh", minHeight: "46vh",
		maxWidth: "100%"}}/>
}

export default MobileLocationPinSet