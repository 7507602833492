import React from "react"
import {convertToPreferredUnits} from "../../../globals/functions/client/localFunctions"
import MobileInputDecimal from "../../shared/input/text/MobileInputDecimal"
import MobileInputFeetInches from "../../shared/input/text/MobileInputFeetInches"

/**
 *
 * @param {Object} props
 * @param {number|string} props.id
 * @param {string} props.form
 * @param {string} props.units - SI or US
 * @param {number} props.value
 * @param {number} [props.displayDigits = 2]
 * @param {string} props.label
 * @param {React.HTMLProps.className} props.className
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputDistance = props => {
	const value = convertToPreferredUnits(props.value, "m", props.units, props.displayDigits || 2, false)
	const valueSI = props.value
	const defaultLong = valueSI > 0 ? valueSI.toLocaleString() : null
	const defaultFeet = valueSI > 0 ? value.split("'")[0] : null
	const defaultInches = valueSI > 0 ? (value.split("'")[1] ?
		`${value.split("'")[1]}`.replace(/[ "]/g,'') : 0) : null
	return props.units === "SI" ? <MobileInputDecimal className={props.className} label={props.label} autoFocus={false}
		defaultLong={defaultLong} required={false} units={"m"} form={props.form} topRadius={"0vh"}
		bottomRadius={"0vh"} name={props.id} id={props.id} digits={3} decimals={2}/> :
		<MobileInputFeetInches className={props.className} label={props.label} autoFocus={false} style={{}}
		defaultFeet={defaultFeet} required={false} form={props.form} topRadius={"0vh"}
		defaultInches={defaultInches}	bottomRadius={"0vh"} name={props.id} id={props.id}/>
	/*defaultLong={value.split('.')[0].replace(/[a-zA-Z ]/g,'')} */
	/*defaultDecimal={value.split('.')[1] ? `${value.split('.')[1]}`.replace(/[a-zA-Z ]/g,'') : 0}*/
}

export default MobileInputDistance