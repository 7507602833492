import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {deleteMessage, fadingMessage, newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {addVesselsAsCustomers} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileFontAwesome from "../../MobileFontAwesome"
import submitToServer from "../../../../globals/functions/client/submitToServer"
/**
 *
 * @param {Object} props
 * @param {string} props.back
 * @param {{vessel: Vessel, hasOwner: boolean, isActiveCustomer: boolean}[]} props.vesselsFound
 * @param {function} props.setPage
 * @param {function} props.refreshVessels
 * @return {JSX.Element}
 * @constructor
 */
const MobileVesselInviteSelect = props => {
	const [selected, setSelected] = useState([])
	useEffect(() => {
		newMessage("select-vessels", translate(DICTIONARY.SELECT_VESSELS.X),
			translate(DICTIONARY.CUSTOMER_INVITE_SELECT_VESSELS_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
		return () => {
			try {
				deleteMessage("select-vessels", false)
			} catch (e) {
				console.log(e)
			}
		}
	}, [])
	const onSubmit = async event => {
		event.preventDefault()
		const onSuccess = () => {
			props.refreshVessels().then()
			props.setInvite(false)
		}
		// props.setPage("VESSEL_SELECT")
		const params = selected.map(v => {
			return {
				id: v.vessel.id,
				isTemporary: v.isTemporary
			}
		})

		if (selected.length > 0) {
			// submit to server
			await submitToServer(event, params, addVesselsAsCustomers, onSuccess)
		} else {
			/* TODO: make page on pages JSON */
			props.setPage("MORE_ABOUT_OWNER")
		}
	}
	const identifier = (key, value) => value ?
		<div style={{margin: "1vh 0vh", padding: "1vh"}} className={"paralian-theme-level-1"}>
			<div style={{opacity: 0.7, fontSize: "2vh"}}>
				{translate(key)}
			</div>
			<div>
				{value}
			</div>
		</div> : <></>
	const vessels = props.vesselsFound.length > 0 ? props.vesselsFound.map(vessel => {
		const selectedIndex = selected.findIndex(v => v.vessel.id === vessel.vessel.id)
		const isSelected = selectedIndex !== -1 ? selected[selectedIndex] : null
		const isTemporary = isSelected ? isSelected.isTemporary : false
		const isPermanent = !!isSelected && !isTemporary
		const selectedCopy = [...selected]
		const style = isSelected || vessel.isActiveCustomer ?
			{border: isTemporary ? "dashed" : "solid", borderColor: "rgb(251,240,214)", borderWidth: "2px"} : {}
		return <div key={`vessel-${vessel.vessel.id}`} style={{margin: "2vh", ...style}}
			className={"paralian-mobile-info-card"}
			onClick={() => {
				console.log(selected, isSelected, !!isSelected, isTemporary, isPermanent, selectedIndex)
				if (vessel.isActiveCustomer) {
					fadingMessage("vessel-is-active-customer-message", translate(DICTIONARY.ACTIVE_CUSTOMER.X),
						translate(DICTIONARY.VESSEL_IS_ACTIVE_CUSTOMER_MESSAGE.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
				} else {
					if (isSelected) {
						if (isPermanent) {
							setSelected(selectedCopy.filter(v => v.vessel.id !== vessel.vessel.id))
						} else {
							selectedCopy[selectedIndex].isTemporary = false
							setSelected(selectedCopy)
						}
					} else {
						setSelected([...selected, {...vessel, isTemporary: true}])
					}
				}
			}}>
			{
				isSelected || vessel.isActiveCustomer ?
					<div className={isTemporary ? "paralian-theme-level-1" : "paralian-theme-level-1-flipped"}
					 	style={{padding: "1vh",borderRadius: "1vh", marginBottom: "1vh", fontSize: "2vh"}}>
						<MobileFontAwesome icon={ICONS.STAR[1]}	label={vessel.isActiveCustomer ? translate(DICTIONARY.ACTIVE_CUSTOMER.X) :
							isTemporary ? translate(DICTIONARY.ADD_AS_TEMPORARY_CUSTOMER.X) :
								translate(DICTIONARY.ADD_AS_PERMANENT_CUSTOMER.X)}
							style={{fontSize: "2vh", marginRight: "1vh"}}	onClick={() => {
								/* TODO: fix message */
							if (!vessel.isActiveCustomer) {
								newMessage("defaultPM", translate(isTemporary ? DICTIONARY.TEMPORARY.X :
										DICTIONARY.PERMANENT.X),
									translate(DICTIONARY.TEMPORARY.X), "w3-pale-blue", ICONS.CREDIT_CARD)
							}
							}}/>
						<span>{vessel.isActiveCustomer ? translate(DICTIONARY.ACTIVE_CUSTOMER.X) :
							isTemporary ? translate(DICTIONARY.ADD_AS_TEMPORARY_CUSTOMER.X) :
								translate(DICTIONARY.ADD_AS_PERMANENT_CUSTOMER.X)}</span>
					</div> : <></>
			}
			<div className={"paralian-mobile-info-card-header"}>
				{vessel.vessel.name.display}
			</div>
			<div style={{fontSize: "4vh"}}>
				{vessel.vessel.hull.brand}
			</div>
			<div style={{fontSize: "3vh"}}>
				{vessel.vessel.hull.model}
			</div>
			<div style={{fontSize: "2.5vh"}}>
				{vessel.vessel.hull.year}
			</div>
			{identifier(DICTIONARY.HULL_IDENTIFICATION_NUMBER.X, vessel.vessel.hull.HIN)}
			{identifier(DICTIONARY.REGISTRATION_NUMBER.X, vessel.vessel.registration)}
			{identifier(DICTIONARY.DOCUMENTATION_NUMBER.X, vessel.vessel.documentation)}
			{identifier(DICTIONARY.MMSI_NUMBER.X, vessel.vessel.ais.mmsi)}
			{identifier(DICTIONARY.IMO_NUMBER.X, vessel.vessel.imo)}
			{identifier(DICTIONARY.CALL_SIGN.X, vessel.vessel.callSign)}
		</div>
	}) : <div style={{padding: "2vh"}}>
		{translate(DICTIONARY.VESSEL_INVITE_NO_RECORDS_FOUND_MESSAGE.X)}
	</div>
	return <>
		<MobileInputForm id={"vessel-select"} className={" "} onSubmit={onSubmit} content={vessels} style={{paddingBottom: "2vh"}}/>
		{/*<div style={{padding: "2vh", opacity: 0.7, textAlign: "left"}} onClick={() => props.setPage(props.back)}>
			<MobileFontAwesome icon={ICONS.BACK} label={translate(DICTIONARY.BACK.X)} style={{paddingRight: "2vh"}}/>
			<i>{translate(DICTIONARY.BACK.X)}</i>
		</div>*/}
	</>
}

export default MobileVesselInviteSelect