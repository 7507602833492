import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileStarRater from "./MobileStarRater"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"

const MobileBusinessRater = props => {
	const [overall, setOverall] = useState(0)
	const [value, setValue] = useState(0)
	const [quality, setQuality] = useState(0)
	const [service, setService] = useState(0)
	// console.log(overall, quality, service, value)
	const onSubmit = () => {
		if (overall ===  0 || ([overall, value, quality, service].some(item => item === 0) && props.isCustomer)) {
			fadingMessage("no-rating-message", translate(DICTIONARY.ZERO_RATINGS.X),
				translate(DICTIONARY.ZERO_RATINGS_MESSAGE.X), "w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
		} else if (!!props.onSubmit) {
			props.onSubmit({
				overall: overall,
				value: value,
				quality: quality,
				service: service
			})
		}
		// console.log("submitted", overall, quality, service, value)
	}
	return <div className={"paralian-theme-level-2"} style={{padding: "2vh 0vh", margin: "0vh 2vh"}}>
		<div className={"rating"} style={props.style || {fontSize: "3vh", margin: "1vh"}}>
			<div onClick={() => fadingMessage("overall-message", translate(DICTIONARY.OVERALL.X),
				translate(DICTIONARY.OVERALL_MESSAGE.X), "w3-pale-blue", ICONS.QUESTION_CIRCLE)}>
				{translate(DICTIONARY.OVERALL_RATING.X)}
			</div>
			<MobileStarRater setRating={setOverall} style={props.padding ? {padding: props.padding} : {padding: "0.25vh"}}/>
		</div>
		{props.isCustomer ?
		<div id={"rating-details"} className={"w3-animate-opacity"}>
			<div style={{padding: "1vh 0vh"}}>
				<div className="rating" style={{fontSize: "2vh", padding: "0.5vh"}}>
					<span onClick={() => fadingMessage("quality-message", translate(DICTIONARY.QUALITY.X),
						translate(DICTIONARY.QUALITY_MESSAGE.X), "w3-pale-blue", ICONS.QUESTION_CIRCLE)}>
						{`${translate(DICTIONARY.QUALITY_RATING.X)}: `}
					</span>
					<MobileStarRater id={"quality"} setRating={setQuality} style={{padding: "0.25vh"}}/>
				</div>
				<div className={"rating"} style={{fontSize: "2vh", padding: "0.5vh"}}>
					<span onClick={() => fadingMessage("service-message", translate(DICTIONARY.SERVICE_NOUN.X),
						translate(DICTIONARY.SERVICE_MESSAGE.X), "w3-pale-blue",
						ICONS.QUESTION_CIRCLE)}>
						{`${translate(DICTIONARY.SERVICE_RATING.X)}: `}
					</span>
					<MobileStarRater id={"service"} setRating={setService} style={{padding: "0.25vh"}}/>
				</div>
				<div className={"rating"} style={{fontSize: "2vh", padding: "0.5vh"}}>
					<span onClick={() => fadingMessage("value-message", translate(DICTIONARY.VALUE.X),
						translate(DICTIONARY.VALUE_MESSAGE.X), "w3-pale-blue", ICONS.QUESTION_CIRCLE)}>
						{`${translate(DICTIONARY.VALUE_RATING.X)}: `}
					</span>
					<MobileStarRater id={"value"} setRating={setValue} style={{padding: "0.25vh"}}/>
				</div>
			</div>
		</div> : <></>}
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"rate"} type={"button"} text={{style: {}, content: translate(DICTIONARY.RATE.X)}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%"}} onClick={onSubmit}/>
		</div>
	</div>
}

export default MobileBusinessRater