import React, {useEffect, useState} from "react"
import {store} from "../../../index"
import {actions} from "../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {handleLoginPrivate, translate} from "../../../globals/functions/client/localFunctions"
import {getCurrentAccount} from "../../../globals/functions/client/serverFunctions"
import MobileInputPassword from "../../shared/input/text/MobileInputPassword"
import MobileFontAwesome from "../../main/MobileFontAwesome"
import Account from "../../../globals/classes/shared/Account"

const MobileLoginCard = () => {
	const [account, setAccount] = useState(new Account())
	useEffect(() => {
		getCurrentAccount(true).then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setAccount(new Account(response.payload))
			}
		})
	},[])
	return <form id={"loginCard-form"} className={"w3-display-middle paralian-theme-level-1 w3-animate-opacity"}
		style={{zIndex: "5000", width: "92vw", maxWidth: "60vmax", padding: "2vh", border: "solid", borderRadius: "1vh",
			boxShadow: "0vh 0vh 20vh 20vh rgb(0 0 40)"}}
		 	onSubmit={handleLoginPrivate} autoComplete={"on"}>
		<div style={{display: "inline-flex"}}>
			<div style={{padding: "0vh 1vh 2vh 1vh", textAlign: "center"}}>
				{translate(DICTIONARY.CONFIRM_PASSWORD_MESSAGE.X)}
			</div>
			<div style={{textAlign: "right"}} onClick={() => store.dispatch({type: actions.HIDE_LOGIN_CARD})}><b>{"X"}</b></div>
		</div>
		<MobileInputPassword id={"loginCard"} form={"loginCard-form"} className={"paralian-theme-level-1-flipped"} showLabel={false}
			style={{}} name={"loginCardPassword"} left={{icon: ICONS.KEY, onClick: () => {}}} right={{}}
			autoFocus={true} topRadius={"0vh"} bottomRadius={"0vh"} username={account.username}/>
		<div className="formSubmitContainer w3-center" style={{width: "100%"}}>
			<button form={"loginCard-form"} id={"loginCard-submit"} type="submit" style={{display: "none"}}> </button>
			<MobileFontAwesome icon={ICONS.CHEVRON_CIRCLE_RIGHT} label={translate(DICTIONARY.SUBMIT.X)}
				style={{fontSize: "4vh", marginTop: "2vh"}} onClick={() => {
					document.getElementById("loginCard-submit").click()}}/>
		</div>
	</form>
}

export default MobileLoginCard