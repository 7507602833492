import React from "react"
import {DICTIONARY} from "../constants/client/constants"
import {manageCrash, translate} from "../functions/client/localFunctions"
import MobileFontAwesome from "../../mobile/main/MobileFontAwesome"
import MobileButtonGeneric from "../../mobile/shared/input/buttons/MobileButtonGeneric"
import * as ReactDOM from "react-dom"
/**
 * Creates a modal
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @param {Object} props
 * @param {string} props.background
 * @param {Icon} props.icon
 * @param {string} props.title
 * @param {string} props.minHeight
 * @param {JSX.Element} props.body
 * @param {JSX.Element} props.buttons
 * @param {function} props.onClick
 * @return {React.ReactPortal}
 * @constructor
 */
const Modal = props => {
	try {
		const loader = document.getElementById("mobileParentContainer")
		const content = <div id={`modalContainer-${props.id}`} className={"w3-display-middle"}
			style={{zIndex: "1000", width: "100vw", maxWidth: "76vh", padding: "2vh"}}>
			<div id={`${props.id}-modal`} style={{width: "100%", zIndex: "1000", boxShadow: "0vh 0vh 50vh 12vh black",
				position: "relative", minHeight: "8vh", maxHeight: "96vh", padding: props.padding || "2vh",
				color: props.background ? "black" : "inherit",
				fontSize: "2vh", border: "solid", borderColor: "#fbf0d6", borderRadius: "1vh", backgroundColor: props.background}}
				className={`w3-animate-opacity ${props.background ? "" : "paralian-theme-level-1"}`}>
				{props.title ? <div className={"w3-display-container"} style={{width: "100%"}}>
					<h3 style={{fontSize: "3vh", padding: "0vh 2vh", display: "inline-flex"}}>
						{props.icon ? <div style={{margin: "auto 0vh"}}>
							<MobileFontAwesome icon={props.icon} style={{paddingRight: "2vh", display: "block"}}/>
						</div> : <></>}
						<div>{props.title}</div>
					</h3>
				</div> : <></>}
				<div className={"paralian-scroll paralian-hide-scroll-ms paralian-hide-scroll-chrome"}
				 	style={{maxHeight: "65vh", width: "100%", overflowX: props.overflowX ? props.overflowX : null}}>
					{props.body}
				</div>
				<div style={{marginTop: "1vh"}}>
					{props.buttons ? props.buttons : <MobileButtonGeneric id={"close"} type={"button"}
						text={{style: {paddingLeft: "0vh"}, content: translate(DICTIONARY.CLOSE.X)}}
						className={"paralian-theme-level-1-flipped"}
						padding={"1vh"} style={{width: "100%"}} onClick={() => {
						try {
							props.onClick()
						} catch (e) {
							console.log(e)
						}
					}}/>}
				</div>
			</div>
		</div>
		return ReactDOM.createPortal(content, loader)
	} catch (e) {
		manageCrash(e, "Modal", "error")
	}
}

export default Modal