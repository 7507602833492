import React, {useEffect} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage,	translate} from "../../../../globals/functions/client/localFunctions"
import MobileLoginFormScaffolding from "../../frames/MobileLoginFormScaffolding"
import MobileInputEmail from "../../../shared/input/text/MobileInputEmail"
import MobileInputPhoneField from "../../../main/contact/MobileInputPhoneField"
import {requestPrimaryEmailUpdate, requestPrimaryPhoneUpdate, requestSecondaryEmailUpdate, requestSecondaryPhoneUpdate
} from "../../../../globals/functions/client/serverFunctions"
import Phones from "../../../../globals/classes/shared/Phones"
import Emails from "../../../../globals/classes/shared/Emails"

/**
 *
 * @param {Object} props
 * @param {Object} props.next
 * @param {Object} props.back
 * @param {string} props.message
 * @returns {JSX.Element}
 * @constructor
 */
const MobileLoginOrgContactInfo = props => {
	useEffect(() => {
		if (props.message) {
			newMessage("contact-info-message", translate(DICTIONARY.CONTACT_INFO.X), props.message,
				"w3-pale-blue", ICONS.INFO)
		}
	}, [props.message])
	const onSubmit = async event => {
		event.preventDefault()
		
		const phones = new Phones({
			primary: {
				countryCode: document.getElementById("primary-phone-country-code-input").getAttribute("data-a"),
				nationalNumber: document.getElementById("primary-phone-national-number-input").value,
				iso: document.getElementById("primary-phone-country-code-input").getAttribute("data-b")
			},
			secondary: {
				countryCode: document.getElementById("support-phone-country-code-input").getAttribute("data-a"),
				nationalNumber: document.getElementById("support-phone-national-number-input").value,
				iso: document.getElementById("support-phone-country-code-input").getAttribute("data-b")
			}
		})
		const emails = new Emails({
			primary: {address: document.getElementById("primary-email-input").value},
			secondary: {address: document.getElementById("support-email-input").value}
		})
		let primaryEmailOk = true, supportEmailOk = true, primaryPhoneOk = true, supportPhoneOk = true
		if (emails.primary.address) {
			const primaryEmailResponse = await requestPrimaryEmailUpdate(emails.primary, true, true)
			primaryEmailOk = primaryEmailResponse.status === SERVER_RESPONSE_STATE.SUCCESS
		}
		if (emails.secondary.address) {
			const supportEmailResponse = await requestSecondaryEmailUpdate(emails.secondary, true)
			supportEmailOk = supportEmailResponse.status === SERVER_RESPONSE_STATE.SUCCESS
		}
		if (phones.primary.nationalNumber) {
			const primaryPhoneResponse = await requestPrimaryPhoneUpdate(phones.primary, true, true)
			primaryPhoneOk = primaryPhoneResponse.status === SERVER_RESPONSE_STATE.SUCCESS
		}
		if (phones.secondary.nationalNumber) {
			const supportPhoneResponse = await requestSecondaryPhoneUpdate(phones.secondary, true)
			supportPhoneOk = supportPhoneResponse.status === SERVER_RESPONSE_STATE.SUCCESS
		}
		
		if (primaryEmailOk && supportEmailOk && primaryPhoneOk && supportPhoneOk) {
			store.dispatch({type: actions.SET_CURRENT_WINDOW, values: {window: props.next}})
		}
	}
	const content =
		<>
			<div style={{fontSize: "3vh"}}>
				<div style={{margin: "1vh 0vh"}}>
					<MobileInputEmail id={"primary"} form={"form"} autoComplete={"email"} showLabel={false} name={"primary-email"}
						className={"paralian-theme-level-1-flipped"} label={translate(DICTIONARY.PRIMARY_EMAIL.X)}
						autoFocus={true} required={true} placeholder={translate(DICTIONARY.PRIMARY_EMAIL.X)}
						right={{icon: " ",	onClick: () => {}}}	left={{icon: ICONS.ENVELOPE, onClick: () => {
							newMessage("primary-email-message", translate(DICTIONARY.PRIMARY_EMAIL.X), "",
								"w3-pale-blue", ICONS.INFO_CIRCLE)}}}/>
				</div>
				<div style={{margin: "1vh 0vh"}}>
					<MobileInputEmail id={"support"} form={"form"} autoComplete={"off"} autoFocus={false} required={false}
						className={"paralian-theme-level-1-flipped"} placeholder={translate(DICTIONARY.SUPPORT_EMAIL.X)}
						label={translate(DICTIONARY.SUPPORT_EMAIL.X)} showLabel={false} name={"support-email"}
						right={{icon: " ",	onClick: () => {}}}	left={{icon: ICONS.ENVELOPE_FAR, onClick: () => {
							newMessage("support-email-message", translate(DICTIONARY.SUPPORT_EMAIL.X), "",
								"w3-pale-blue", ICONS.INFO_CIRCLE)}}}/>
				</div>
				<div style={{margin: "1vh 0vh"}}>
					<MobileInputPhoneField id={"primary-phone"} form={"form"} required={true} autoFocus={false}
						className={"paralian-theme-level-1-flipped"} left={{icon: ICONS.PHONE, onClick: () => {
							newMessage("support-email-message", translate(DICTIONARY.PRIMARY_PHONE.X), "",
								"w3-pale-blue", ICONS.INFO_CIRCLE)}}}
					 	right={{icon: "",	onClick: () => {}}} showLabel={false}
						countryCode={{placeholder: translate(DICTIONARY.COUNTRY_CODE.X), defaultValue: null,
							label: translate(DICTIONARY.COUNTRY_CODE.X)}} label={translate(DICTIONARY.PRIMARY_PHONE.X)}
					 	nationalNumber={{defaultValue: null, autoFocus: false, label: translate(DICTIONARY.PRIMARY_PHONE.X),
							placeholder: translate(DICTIONARY.PRIMARY_PHONE.X)}}/>
				</div>
				<div style={{margin: "1vh 0vh"}}>
					<MobileInputPhoneField id={"support-phone"} form={"form"} required={false} autoFocus={false}
						className={"paralian-theme-level-1-flipped"} left={{icon: ICONS.PHONE_FAR, onClick: () => {
							newMessage("support-email-message", translate(DICTIONARY.SUPPORT_PHONE.X), "",
								"w3-pale-blue", ICONS.INFO_CIRCLE)}}}
					 	right={{icon: "",	onClick: () => {}}} showLabel={false}
						countryCode={{placeholder: translate(DICTIONARY.COUNTRY_CODE.X), defaultValue: null,
							label: translate(DICTIONARY.COUNTRY_CODE.X)}} label={translate(DICTIONARY.SUPPORT_PHONE.X)}
						nationalNumber={{defaultValue: null, autoFocus: false, label: translate(DICTIONARY.SUPPORT_PHONE.X),
							placeholder: translate(DICTIONARY.SUPPORT_PHONE.X)}}/>
				</div>
			</div>
		</>
	return <MobileLoginFormScaffolding header={props.header} content={content} back={props.back} onSubmit={onSubmit}
		style={{maxHeight: "46vh"}}/>
}

export default MobileLoginOrgContactInfo