import React from "react"

/**
 *
 * @param props {Object}
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.labelStyle {Object}
 * @param props.className {string}
 * @param props.defaultChecked {boolean}
 * @param props.width {string}
 * @param props.height {string}
 * @param props.padding {string}
 * @param props.onChange {Object} - function
 * @param props.onClick {Object} - function
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputSlider = props => {
	const onChange = props.onChange ? props.onChange : () => {}
	const onClick = props.onClick ? props.onClick : () => {}
	// console.log("default", props.defaultChecked)
	return <>
		<label htmlFor={props.id} className={props.className} style={{display: "flex", width: "fit-content"}}>
			<input id={props.id} type={"checkbox"} data-is-checked={`${props.defaultChecked}`}
				style={{width: props.width, height: props.height, margin: "auto 0vh"}}
				defaultChecked={props.defaultChecked} onChange={() => {
				const element = document.getElementById(props.id)
				const state = element.getAttribute("data-is-checked") === "true"
				// console.log(element.getAttribute("data-is-checked"))
				state ?
					element.setAttribute("data-is-checked", "false") :
					element.setAttribute("data-is-checked", "true")
					// element.checked = !state
				onChange(element)
			}} onClick={() => {
				onClick(document.getElementById(props.id))
				// console.log("clicked")
			}} form={props.form}/>
			<span id={`${props.id}-slider`} className={"slider round"} style={{width: props.width, height: props.height,
				margin: "auto 0vh"}}/>
			<div style={{padding: props.padding, ...props.labelStyle}}>{props.label}</div>
		</label>
	</>
}

export default MobileInputSlider