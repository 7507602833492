import React from "react"
import MobileInputGeneric from "../MobileInputGeneric"
/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.id
 * @param {string} props.form
 * @param {string} props.label
 * @param {boolean} props.showLabel
 * @param {string} props.name
 * @param {string} props.min - (YYYY-MM-DD format)
 * @param {string} props.max - (YYYY-MM-DD format)
 * @param {boolean} props.autoFocus
 * @param {string} props.autoComplete
 * @param {string} props.defaultValue
 * @param {boolean} props.required
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {Icon} props.left
 * @param {Icon} props.right
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputDate = props => <MobileInputGeneric type={"date"} id={props.id} name={props.name} label={props.label}
	className={props.className} style={props.style} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
	left={props.left} right={props.right} autoComplete={props.autoComplete} showLabel={props.showLabel}
	required={props.required} defaultValue={props.defaultValue} min={props.min} max={props.max} form={props.form}
	autoFocus={props.autoFocus} onChange={props.onChange}/>

export default MobileInputDate