import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {Object} props.order.buttons
 * @param {Object} props.order.buttons.adjust
 * @param {boolean} props.order.buttons.adjust.propose
 * @param {function} props.setShowProposeAdjustDueDateModal
 * @return {JSX.Element}
 * @constructor
 */
const ProposeAdjustOrderDueDateButton = props => {
	return props.order.buttons.adjust.propose ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"adjust-propose"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.PROPOSE_DUE_DATE_CHANGE.X)}} left={{icon: ICONS.CALENDAR_ALT}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%"}}
				onClick={() => props.setShowProposeAdjustDueDateModal(true)}/>
		</div> : <></>
}

export default ProposeAdjustOrderDueDateButton