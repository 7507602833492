import React from "react"
import {DICTIONARY, IMAGES} from "../../../../globals/constants/client/constants"
import {getUnits, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputDistance from "../../vessels/MobileInputDistance"
import MobileHorizontalPictureAndText from "../../MobileHorizontalPictureAndText"

const VesselCleaningHullSpecifications = props => {
	const length = <MobileInputDistance id={"hull-loa"} label={translate(DICTIONARY.HULL_LENGTH_OVER_ALL.X)}
		value={props.vessel.hull.dimensions.length} units={getUnits(props.account)} form={props.form}/>
/*	const beam = <MobileInputDistance id={"hull-beam"} label={translate(DICTIONARY.BEAM.X)} value={props.dimensions.beam}
		units={props.units} form={form}/>
	const draught = <MobileInputDistance id={"hull-draught"} label={translate(DICTIONARY.DRAUGHT.X)}
		value={props.dimensions.draught} units={props.units} form={form}/>
	const height = <MobileInputDistance id={"hull-height"} label={translate(DICTIONARY.HEIGHT.X)}
		value={props.dimensions.height} units={props.units} form={form}/>*/
	const content = <>
	<MobileHorizontalPictureAndText imagePath={IMAGES.HULL_LOA} label={translate(DICTIONARY.HULL_LENGTH_OVER_ALL.X)}
		content={length} className={props.className}/>
{/*	<MobileHorizontalPictureAndText imagePath={IMAGES.BEAM} label={translate(DICTIONARY.BEAM.X)} content={beam}
		className={props.className}/>
	<MobileHorizontalPictureAndText imagePath={IMAGES.DRAUGHT} label={translate(DICTIONARY.DRAUGHT.X)} content={draught}
		className={props.className}/>
	<MobileHorizontalPictureAndText imagePath={IMAGES.HULL_CLEARANCE} label={translate(DICTIONARY.CLEARANCE.X)} content={height}
		className={props.className}/>*/}
	</>
	return <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh", fontSize: "3vh"}}>{translate(DICTIONARY.HULL_SPECIFICATIONS.X)}</legend>
		{content}
	</fieldset>
}

export default VesselCleaningHullSpecifications