import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import Modal from "../../../globals/components/Modal"
import MobileAdjustRespondModal from "./orderCard/modals/MobileAdjustRespondModal"
import AcceptOrderButton from "./orderCard/buttons/AcceptOrderButton"
import OrderDetailsButton from "./orderCard/buttons/OrderDetailsButton"
import OrderDetailsModal from "./orderCard/modals/OrderDetailsModal"
import ConfirmOrderButton from "./orderCard/buttons/ConfirmOrderButton"
import DeclineOrderButton from "./orderCard/buttons/DeclineOrderButton"
import ProposeAdjustOrderPriceButton from "./orderCard/buttons/ProposeAdjustOrderPriceButton"
import ProposeAdjustOrderDueDateButton from "./orderCard/buttons/ProposeAdjustOrderDueDateButton"
import ProposeAdjustOrderPriceModal from "./orderCard/modals/ProposeAdjustOrderPriceModal"
import ProposeAdjustOrderDueDateModal from "./orderCard/modals/ProposeAdjustOrderDueDateModal"
import RespondToOrderAdjustButton from "./orderCard/buttons/RespondToOrderAdjustButton"
import RejectOrderButton from "./orderCard/buttons/RejectOrderButton"
import AssignOrderButton from "./orderCard/buttons/AssignOrderButton"
import AssignOrderModal from "./orderCard/modals/AssignOrderModal"
import StartOrderButton from "./orderCard/buttons/StartOrderButton"
import CompleteOrderButton from "./orderCard/buttons/CompleteOrderButton"
import IncompleteOrderButton from "./orderCard/buttons/IncompleteOrderButton"
import RefundOrderButton from "./orderCard/buttons/RefundOrderButton"
import RefundOrderModal from "./orderCard/modals/RefundOrderModal"
import DisputeOrderModal from "./orderCard/modals/DisputeOrderModal"
import DisputeOrderButton from "./orderCard/buttons/DisputeOrderButton"
import CancelOrderButton from "./orderCard/buttons/CancelOrderButton"
import SetAsFavoriteButton from "./orderCard/buttons/SetAsFavoriteButton"
import RateOrderButton from "./orderCard/buttons/RateOrderButton"
import RateOrderModal from "./orderCard/modals/RateOrderModal"
import OrderReceiptButtons from "./orderCard/buttons/OrderReceiptButtons"
import ConfirmOrderModal from "./orderCard/modals/ConfirmOrderModal"
import Currency from "../../../globals/classes/shared/Currency"
import ContactInfo from "../../../globals/classes/shared/ContactInfo"
import Websites from "../../../globals/classes/shared/Websites"

const MobileOrderCard = props => {
	/* TODO: show rating for order */
	/* TODO: include customer rating when viewed as provider */
	/* TODO: include special requests message section within order details */
	/* TODO: include upload document button when viewed as provider */
	/* TODO: if there's a problem with the payment, provide a link to where it can be completed - order adjustments */
	const [dropdown, setDropdown] = useState(false)
	const [showOrderDetails, setShowOrderDetails] = useState(false)
	const [showRateModal, setShowRateModal] = useState(false)
	const [showProposeAdjustPriceModal, setShowProposeAdjustPriceModal] = useState(false)
	const [showProposeAdjustDueDateModal, setShowProposeAdjustDueDateModal] = useState(false)
	const [showAssignModal, setShowAssignModal] = useState(false)
	const [showRefundModal, setShowRefundModal] = useState(false)
	const [showDisputeModal, setShowDisputeModal] = useState(false)
	const [showAdjustRespondModal, setShowAdjustRespondModal] = useState(false)
	const [showConfirmOrderModal, setShowConfirmOrderModal] = useState(false)
	const [contactInfo, setContactInfo] = useState(new ContactInfo())
	const [websites, setWebsites] = useState(new Websites())
	const order = props.order
	// console.log(order)
	const currency = new Currency(order.currency)
	// console.log("order.state", order.state, "order.isCustomer", order.isCustomer)
	let status = window.textToIcon(order.state.toLowerCase())
	if ((order.state === "ADJUST" || order.state === "CONFIRM") && !order.isCustomer) {
		status = window.textToIcon("adjust-provider")
	}
	const type = window.textToIcon(order.category.name.replace(/_/g, " "))
	const date = new Date(order.opened).toLocaleString(undefined,
		{day: "numeric", month: "short", year: "numeric"})
	const dueDate = {
		date: new Date(order.dueDate).toLocaleString(undefined,
			{day: "numeric", month: "short", year: "numeric"}),
		time: new Date(order.dueDate).toLocaleString(undefined,
			{hour: "numeric", minute: "numeric",	timeZoneName: "short"})
	}
	const accessStart = {
		date: new Date(order.accessStart).toLocaleString(undefined,
			{day: "numeric", month: "short", year: "numeric"}),
		time: new Date(order.accessStart).toLocaleString(undefined,
			{hour: "numeric", minute: "numeric",	timeZoneName: "short"})
	}
	const update = {
		date: new Date(order.updated).toLocaleString(undefined,
			{day: "numeric", month: "short",	year: "numeric"}),
		time: new Date(order.updated).toLocaleString(undefined,
			{hour: "numeric", minute: "numeric",	timeZoneName: "short"})
	}
	const dataFilterArray = props.dataFilterArray
	/* TODO: include name of provider with onClick to launch modal with profile */
	/* TODO: include name of vessel with onClick to launch modal with profile. gather data from customer vessels or own vessels */
	/* TODO: include name of marina with onClick to launch modal with profile */
	const accept = <AcceptOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const orderDetailsButton = <OrderDetailsButton order={order} setShowOrderDetails={setShowOrderDetails}/>
	const orderDetailsModal = <OrderDetailsModal order={order} setShowOrderDetails={setShowOrderDetails}
		showOrderDetails={showOrderDetails} services={props.services} currency={currency}/>
	const confirm = <ConfirmOrderButton order={order} setShowConfirmOrderModal={setShowConfirmOrderModal}/>
	const confirmOrderModal = <ConfirmOrderModal order={order} showConfirmOrderModal={showConfirmOrderModal}
		setShowConfirmOrderModal={setShowConfirmOrderModal} organization={props.organization} account={props.account}
		refreshOrganization={props.refreshOrganization} refreshAccount={props.refreshAccount}
		refreshOrders={props.refreshOrders} services={props.services}/>
	const decline = <DeclineOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const proposeAdjustPrice = <ProposeAdjustOrderPriceButton order={order}
		setShowProposeAdjustPriceModal={setShowProposeAdjustPriceModal}/>
	const proposeAdjustDueDate = <ProposeAdjustOrderDueDateButton order={order}
		setShowProposeAdjustDueDateModal={setShowProposeAdjustDueDateModal}/>
	const proposeAdjustPriceModal = <ProposeAdjustOrderPriceModal order={order} currency={currency}
		setShowProposeAdjustPriceModal={setShowProposeAdjustPriceModal} services={props.services}
		showProposeAdjustPriceModal={showProposeAdjustPriceModal} refreshOrders={props.refreshOrders}/>
	const proposeAdjustDueDateModal = <ProposeAdjustOrderDueDateModal order={order}
		showProposeAdjustDueDateModal={showProposeAdjustDueDateModal} dueDate={dueDate} account={props.account}
		setShowProposeAdjustDueDateModal={setShowProposeAdjustDueDateModal} refreshOrders={props.refreshOrders}/>
	const adjustRespond = <RespondToOrderAdjustButton order={order} setShowAdjustRespondModal={setShowAdjustRespondModal}/>
	// console.log("indexedServices", indexedServices)
	const adjustRespondModal = <MobileAdjustRespondModal showAdjustRespondModal={showAdjustRespondModal}
		setShowAdjustRespondModal={setShowAdjustRespondModal} order={order}
		refreshOrders={props.refreshOrders} services={props.services}/>
	const reject = <RejectOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const assign = <AssignOrderButton order={order} setShowAssignModal={setShowAssignModal}/>
	const assignModal = <AssignOrderModal order={order} setShowAssignModal={setShowAssignModal}
		showAssignModal={showAssignModal} refreshOrders={props.refreshOrders} organization={props.organization}/>
	const start = <StartOrderButton order={order} refreshOrders={props.refreshOrders}/>
	/*
	const contact = order.buttons.contact ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"block"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: order.isCustomer ? translate(DICTIONARY.CONTACT_PROVIDER.X) :
				translate(DICTIONARY.CONTACT_CUSTOMER.X)}} left={{icon: ICONS.PEOPLE_ARROWS}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%"}} onClick={async () => {
					/!* TODO: contact customer/provider *!/
				try {
					const response = await assignOrder(order.id)
					// console.log(response)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.refreshOrders()
						fadingMessage("contact-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
					}
				} catch (e) {
					console.log(e)
				}
				// console.log("contact")
			}}/>
		</div> : <></>
	 */
	const complete = <CompleteOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const incomplete = <IncompleteOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const refund = <RefundOrderButton order={order} setShowRefundModal={setShowRefundModal}/>
	const refundModal = <RefundOrderModal order={order} showRefundModal={showRefundModal} currency={currency}
		setShowRefundModal={setShowRefundModal} organization={props.organization} refreshOrders={props.refreshOrders}/>
	const favorite = <SetAsFavoriteButton order={order} refreshOrders={props.refreshOrders}/>
	const cancel = <CancelOrderButton order={order} refreshOrders={props.refreshOrders}/>
	const dispute = <DisputeOrderButton order={order} setShowDisputeModal={setShowDisputeModal}
		setContactInfo={setContactInfo} setWebsites={setWebsites}/>
	const disputeModal = <DisputeOrderModal order={order} setShowDisputeModal={setShowDisputeModal} websites={websites}
		showDisputeModal={showDisputeModal} organization={props.organization} contactInfo={contactInfo}/>
	/* TODO: show rating & maybe hide rating button of those already rated */
	const rate = <RateOrderButton order={order} setShowRateModal={setShowRateModal}/>
	const rateModal = <RateOrderModal order={order} setShowRateModal={setShowRateModal} showRateModal={showRateModal}
		organization={props.organization} refreshOrders={props.refreshOrders} />
	const receipt = <OrderReceiptButtons order={order}/>

	const assignedEmployee = order.assignedEmployee.name ?
		<div style={{display: "flex", padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
				{translate(DICTIONARY.ASSIGNED_EMPLOYEE.X)}
			</div>
			<div style={{textAlign: "right", width: "100%"}}>
				{order.assignedEmployee.name.given}
			</div>
		</div> : <></>
	const assignedGroup = order.assignedGroup.name && !order.isCustomer ?
		<div style={{display: "flex", padding: "1vh 0vh"}}>
			<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
				{translate(DICTIONARY.ASSIGNED_GROUP.X)}
			</div>
			<div style={{textAlign: "right", width: "100%"}}>
				{order.assignedGroup.name}
			</div>
		</div> : <></>
	const orderBasics = <div style={{display: "flex", padding: "1vh"}}>
		<div style={{minWidth: "40%"}}>
			<div style={{fontSize: "6.5vh"}}>
				<MobileFontAwesome icon={status.icon} style={{color: status.color}} label={status.text}/>
			</div>
			<div>
				{status.text}
			</div>
		</div>
		<div style={{fontSize: "2.5vh", width: "100%"}}>
			<div style={{margin: "1vh 2vw", textAlign: "right"}}>
				<div>
					{type.text}
				</div>
				<div style={{fontSize: "1.5vh", opacity: "0.75"}}>
					{translate(DICTIONARY.ORDER_TYPE.X)}
				</div>
			</div>
			<div style={{margin: "1vh 2vw", textAlign: "right"}}>
				<div>
					{date}
				</div>
				<div style={{fontSize: "1.5vh", opacity: "0.75"}}>
					{translate(DICTIONARY.ORDER_DATE.X)}
				</div>
			</div>
		</div>
	</div>

	const dropdown_ = !dropdown ? <></> : <Modal id={`order-${order.id}-modal`} icon={ICONS.CALENDAR_ALT}
		onClick={() => setDropdown(false)} padding={"0vh"}
		title={translate(DICTIONARY.ORDER.X) + " " + order.id} body={<div className={"paralian-theme-level-2"}
			style={{margin: "2vh", padding: "2vh 0vh"}}>
		{orderBasics}
		{orderDetailsModal}
		{proposeAdjustPriceModal}
		{proposeAdjustDueDateModal}
		{assignModal}
		{refundModal}
		{rateModal}
		{disputeModal}
		{adjustRespondModal}
		{confirmOrderModal}
		<div style={{padding: "2vh"}}>
			<div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.PROVIDER.X)}
				</div>
				<div style={{textAlign: "right", width: "100%"}}>
					{order.provider.name.display}
				</div>
			</div>
			<div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.VESSEL.X)}
				</div>
				<div style={{textAlign: "right", width: "90%"}}>
					{order.vessel.name.display ? <div style={{textAlign: "right", width: "100%"}}>
						{order.vessel.name.display}
					</div> : <></>}
					{order.vessel.hull.brand ? <div style={{textAlign: "right", width: "100%"}}>
						{order.vessel.hull.brand + (order.vessel.hull.model ? ` | ${order.vessel.hull.model}` : "") +
						 (order.vessel.hull.year ? ` | ${order.vessel.hull.year}` : "")}
					</div> : <></>}
				</div>
			</div>
			<div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.MARINA.X)}
				</div>
				<div style={{textAlign: "right", width: "90%"}}>
					{order.marina.name.display}
				</div>
			</div>
			{order.vessel.home.unit ? <div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.LOCATION.X)}
				</div>
				<div style={{textAlign: "right", width: "90%"}}>
					<div style={{textAlign: "right", width: "100%"}}>
						{order.vessel.home.unit}
					</div>
					{order.vessel.home.facility ? <div style={{textAlign: "right", width: "100%"}}>
						{order.vessel.home.facility}
					</div> : <></>}
				</div>
			</div> : <></>}
			<div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.ACCESS_START_DATE.X)}
				</div>
				<div style={{textAlign: "right", width: "90%"}}>
					<div style={{textAlign: "right", width: "100%"}}>
						{accessStart.date}
					</div>
					<div style={{textAlign: "right", width: "100%"}}>
						{accessStart.time}
					</div>
				</div>
			</div>
			<div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.DUE_DATE.X)}
				</div>
				<div style={{textAlign: "right", width: "90%"}}>
					<div style={{textAlign: "right", width: "100%"}}>
						{dueDate.date}
					</div>
					<div style={{textAlign: "right", width: "100%"}}>
						{dueDate.time}
					</div>
				</div>
			</div>
			<div style={{display: "flex", padding: "1vh 0vh"}}>
				<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75", minWidth: "10%"}}>
					{translate(DICTIONARY.STATUS_UPDATED.X)}
				</div>
				<div style={{textAlign: "right", width: "90%"}}>
					<div style={{textAlign: "right", width: "100%"}}>
						{update.date}
					</div>
					<div style={{textAlign: "right", width: "100%"}}>
						{update.time}
					</div>
				</div>
			</div>
			{order.specialRequests ?
				<div style={{padding: "1vh 0vh"}}>
					<div style={{textAlign: "left", fontSize: "1.5vh", opacity: "0.75"}}>
						{translate(DICTIONARY.CUSTOMER_NOTES.X)}
					</div>
					<div style={{textAlign: "justify", paddingTop: "1vh"}}>
						{order.specialRequests}
					</div>
				</div> : <></>
			}
			{assignedEmployee}
			{assignedGroup}
		</div>
		{orderDetailsButton}
		{confirm}
		{decline}
		{accept}
		{reject}
		{assign}
		{refund}
		{adjustRespond}
		{proposeAdjustPrice}
		{proposeAdjustDueDate}
		{cancel}
		{dispute}
		{rate}
		{/*blockAccount*/}
		{/*blockVessel*/}
		{/*blockOrganization*/}
		{favorite}
		{start}
		{/*contact*/}
		{complete}
		{incomplete}
		{receipt}
	</div>}/>
	return <div id={`${order.id}-orderCard`} style={{width: "100%"}} data-filter-array={dataFilterArray}>
		{dropdown_}
		<div className={"paralian-theme-level-1-flipped"} style={{display: "flex", margin: "1vh 0vw",
			padding: "1vh 2vw", width: "100%"}}>
			<div style={{textAlign: "left", opacity: "0.75", width: "50%"}}>
				<MobileFontAwesome icon={type.icon} style={{fontWeight: "bold", paddingRight: "2vh"}} label={type.text} />
				{translate(DICTIONARY.ORDER_NUMBER.X)}
			</div>
			<div style={{textAlign: "right", width: "50%"}}>
				<b>{order.id}</b>
			</div>
		</div>
		<div className={"paralian-theme-level-2 w3-ripple"} style={{cursor: "pointer"}}
		 	onClick={() => setDropdown(!dropdown)}>
			{orderBasics}
		</div>
	</div>
}

export default MobileOrderCard