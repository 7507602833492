import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import Modal from "../../../../../globals/components/Modal"
import MobileOrderDetailsCard from "../../MobileOrderDetailsCard"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.charges
 * @param {{order: number, item: number, isAdjustment: boolean, quantity: number, charge: number}[]} props.order.charges.items
 * @param {{items: number, taxes: number, total: number}} props.order.charges.total
 * @param {{available: number, captured: number, credits: number, onHold: number, refunded: number, receipts: string[]}} props.order.refunds
 * @param {IndexedArray} props.services
 * @param {Currency} props.currency
 * @param {boolean} props.showOrderDetails
 * @param {function} props.setShowOrderDetails
 * @return {JSX.Element}
 * @constructor
 */
const OrderDetailsModal = props => {
	const order = props.order
	const currency = props.currency
	return props.showOrderDetails ? <Modal title={translate(DICTIONARY.ORDER_DETAILS.X)} padding={"0vh"}
	icon={ICONS.INFO_CIRCLE} onClick={() => props.setShowOrderDetails(false)} id={`order-${order.id}-details`}
	body={<>
	 {
		 order.charges.items.map(item => <MobileOrderDetailsCard key={`order-${order.id}-details-${item.item}`}
			order={order} item={item}	indexedServices={props.services}/>)
	 }
	 <div className={"paralian-theme-level-2"} style={{padding: "2vh", margin: "2vh"}}>
		 <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
			 <div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.SUBTOTAL.X)}</div>
			 <div>{currency.asText(order.charges.total.items)}</div>
		 </div>
		 <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
			 <div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.TAXES.X)}</div>
			 <div>{currency.asText(order.charges.total.taxes)}</div>
		 </div>
		 <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
			 <div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.REFUNDS.X)}</div>
			 <div>{currency.asText(order.charges.total.total - (order.refunds.available + order.refunds.onHold))}</div>
		 </div>
		 <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%"}}>
			 <div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.CREDITS.X)}</div>
			 <div>{currency.asText(order.refunds.credits)}</div>
		 </div>
		 <div style={{display: "inline-flex", padding: "1vh 0vh", width: "100%", fontWeight: "bold"}}>
			 <div style={{textAlign: "left", width: "100%"}}>{translate(DICTIONARY.TOTAL.X)}</div>
			 <div>{currency.asText(order.refunds.available + order.refunds.onHold)}</div>
		 </div>
	 </div>
	</>}/> : <></>
}

export default OrderDetailsModal