import React from "react"
/**
 *
 * @param {Object} props
 * @param {Coordinates} props.coordinates
 * @returns {JSX.Element}
 * @constructor
 */
const MobileStaticMapPin = props => {
	return <img className={"w3-animate-opacity"} style={{padding: "0vh 2vh 1vh 2vh", maxWidth: "100%"}} alt={"map"}
		src={"https://maps.googleapis.com/maps/api/staticmap?center=" +
		props.coordinates.latitude + "," + props.coordinates.longitude + "&zoom=17" +
		"&size=1920x1920&maptype=hybrid&markers=color:red%7C" +
		props.coordinates.latitude + "," + props.coordinates.longitude +
		`&key=${process.env.REACT_APP_GOOGLE_API_KEY}`}/>
}

export default MobileStaticMapPin