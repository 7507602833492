import React from "react"
import {DICTIONARY, ICONS, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import submitDocumentUpload from "./submitDocumentUpload"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInputDocumentFields from "./MobileInputDocumentFields"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileFileUploadCanvas from "../../shared/input/file/MobileFileUploadCanvas"
import appendFileUploadFormData from "../../shared/input/file/appendFileUploadFormData"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.country = ISO 3166 3-letter code
 * @param {Currency} props.currency
 * @param {string} props.docType
 * @param {string} props.container
 * @param {Object} [props.share]
 * @param {number} [props.containerId]
 * @param {function} props.onUndo
 * @param {function} props.onSuccess
 * @returns {JSX.Element}
 * @constructor
 */
const MobileDocumentUploadForm = props => {
	const onSubmit = event => {
		event.preventDefault()
		try {
			const file = document.getElementById(`new-${props.container}-upload-file-input`).files[0]
			if (!file) {
				newMessage("no-file-message", translate(DICTIONARY.NO_FILE_UPLOADED.X),
					translate(DICTIONARY.PLEASE_SELECT_A_FILE_TO_UPLOAD.X),
					"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
			}
		} catch (e) {
			newMessage("no-file-message", translate(DICTIONARY.NO_FILE_UPLOADED.X),
				translate(DICTIONARY.PLEASE_SELECT_A_FILE_TO_UPLOAD.X),
				"w3-pale-yellow", ICONS.EXCLAMATION_TRIANGLE)
		}
		try {
			const formData = new FormData()
			appendFileUploadFormData(`new-${props.container}-upload`, formData).then(updatedFormData => {
				submitDocumentUpload(updatedFormData, `new-${props.container}-upload`, props.onSuccess, props.container,
					props.docType, props.containerId, props.docData ? props.docData.document.id : null, props.share)
			})
		}
		catch(e) {
			console.log(e)
		}
	}
	const content = <>
		<MobileFileUploadCanvas id={`new-${props.container}-upload`} label={translate(DICTIONARY.FILE_UPLOAD.X)}
			className={props.className} form={`new-${props.container}-form`} showLabel={true}/>
		<MobileInputText id={`${props.container}-label`} name={"label"} showLabel={true} bottomRadius={"0vh"} autoFocus={false}
			label={translate(DICTIONARY.LABEL.X)} className={props.className} style={{margin: "1vh 0vw"}} topRadius={"0vh"}
			left={{}} right={{}} form={`new-${props.container}-form`} autoComplete={"off"} required={false}
		 	defaultValue={props.docData ? props.docData.document.name : null}
			placeholder={translate(DICTIONARY.LABEL.X)} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX}
	 		pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE}/>
		<MobileInputDocumentFields docType={props.docType} container={props.container} className={props.className}
			country={props.country} currency={props.currency} docData={props.docData}/>
	</>
	// console.log("MDUF props.containerId", props.containerId)
	return <MobileInputForm id={`new-${props.container}`} content={content} encType={"multipart/form-data"}
		onSubmit={onSubmit}/>
}

export default MobileDocumentUploadForm