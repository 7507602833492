import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileBrandModelYearHullEdit from "../vessels/hull/MobileBrandModelYearHullEdit"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileFontAwesome from "../MobileFontAwesome"
import Vessel from "../../../globals/classes/shared/Vessel"
import Hull from "../../../globals/classes/shared/vessels/Hull"

/**
 *
 * @param {Object} props
 * @param {function} props.onAdd
 * @param {function} props.onUndo
 * @return {JSX.Element}
 * @constructor
 */
const MobileCustomerInvite = props => {
	const content = <>
		<MobileInputText id={"vessel-name"} label={translate(DICTIONARY.NAME.X)} form={"hull-bmy-form"} autoComplete={"off"}
			autoFocus={true} placeholder={translate(DICTIONARY.NAME.X)} maxLength={"64"} required={true} showLabel={false}
			className={"paralian-theme-level-1"} style={{margin: "1vh 0vh"}} name={"name"} pattern={"([a-zA-Z0-9-_.' ]){2,64}"}/>
		<MobileBrandModelYearHullEdit add={true} onSuccess={(id, name, brand, model, year) => {
			const vessel = new Vessel({id: id, name: name, hull: new Hull({brand: brand, model: model, year: year})})
			props.onAdd(vessel)
		}}/>
	</>
	return <div className={"paralian-mobile-info-card"}>
		<div className={"paralian-mobile-info-card-header"} style={{marginBottom: "1vh", padding: "0.5vh 0vh"}}>
			<i>{translate(DICTIONARY.ADD_VESSEL.X)}</i>
			<MobileFontAwesome icon={ICONS.BACK} style={{paddingLeft: "1.5vh"}} label={translate(DICTIONARY.BACK.X)}
				onClick={() => props.onUndo()} className={"w3-animate-opacity"}/>
		</div>
		{content}
	</div>
}

export default MobileCustomerInvite