import React from "react"
import MobileInputPasswordConfirm from "../shared/input/text/MobileInputPasswordConfirm"
import MobileInputPassword from "../shared/input/text/MobileInputPassword"

/**
 *
 * @param {Object} props
 * @param {string} props.username
 * @returns {JSX.Element}
 * @constructor
 */
const MobileNewPassword = props => {
	return <>
		<MobileInputPassword form={"form"} className={"paralian-theme-level-1-flipped"}
			autoFocus={true} showLabel={false} autoComplete={false} username={props.username}/>
		<MobileInputPasswordConfirm form={"form"} showLabel={false} className={"paralian-theme-level-1-flipped"}/>
	</>
}

export default MobileNewPassword