import React from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {MONTH} from "../../../../globals/constants/shared/enumerators"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.defaultValue
 * @param {string} props.form
 * @param {Object} props.left
 * @param {string} props.left.icon
 * @param {Object} props.left.onChange - onChange function
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {boolean} props.showLabel
 * @param {function} props.onChange
 * @returns {JSX.Element}
 * @constructor
 */
const MobileMonthDropdown = props => {
	const className = props.className || "paralian-theme-level-1-flipped"
	const onChange = props.onChange ? props.onChange : () => {}
	const list = [
		{label: translate(DICTIONARY.JANUARY.X), value: MONTH.JANUARY, dataId: 1, index: 0},
		{label: translate(DICTIONARY.FEBRUARY.X), value: MONTH.FEBRUARY, dataId: 2, index: 1},
		{label: translate(DICTIONARY.MARCH.X), value: MONTH.MARCH, dataId: 3, index: 2},
		{label: translate(DICTIONARY.APRIL.X), value: MONTH.APRIL, dataId: 4, index: 3},
		{label: translate(DICTIONARY.MAY.X), value: MONTH.MAY, dataId: 5, index: 4},
		{label: translate(DICTIONARY.JUNE.X), value: MONTH.JUNE, dataId: 6, index: 5},
		{label: translate(DICTIONARY.JULY.X), value: MONTH.JULY, dataId: 7, index: 6},
		{label: translate(DICTIONARY.AUGUST.X), value: MONTH.AUGUST, dataId: 8, index: 7},
		{label: translate(DICTIONARY.SEPTEMBER.X), value: MONTH.SEPTEMBER, dataId: 9, index: 8},
		{label: translate(DICTIONARY.OCTOBER.X), value: MONTH.OCTOBER, dataId: 10, index: 9},
		{label: translate(DICTIONARY.NOVEMBER.X), value: MONTH.NOVEMBER, dataId: 11, index: 10},
		{label: translate(DICTIONARY.DECEMBER.X), value: MONTH.DECEMBER, dataId: 12, index: 11}
	]
	const defaultValue = props.defaultValue || MONTH.JANUARY
	const defaultIndex = list.findIndex(item => item.value === defaultValue)
	const defaultItem = list[defaultIndex]
	return <MobileInputGeneric type={"dropdown"} id={props.id} className={className} size={"1"} showLabel={props.showLabel}
		label={translate(DICTIONARY.MONTH.X)} multiple={false} topRadius={props.topRadius} dataId={defaultItem.dataId}
		name={"month"} form={props.form} autoFocus={false} required={true} list={list} left={props.left}
		defaultValue={props.defaultValue} bottomRadius={props.bottomRadius} dataA={defaultItem.value} onChange={() => {
			const element = document.getElementById(`${props.id}-input`)
			element.setAttribute("data-a", element.value)
			onChange()
		}}/>
}

export default MobileMonthDropdown