import React from "react"
import {DICTIONARY} from "../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../globals/constants/shared/enumerators"
import {uploadImage} from "../globals/functions/client/serverFunctions"
import {translate} from "../globals/functions/client/localFunctions"
import MobileCanvas from "../mobile/shared/input/canvas/MobileCanvas"
/* TODO: look into direct camera capture */
/* TODO: remove id parameter */
/**
 *
 * @param {Object} props
 * @param {number} props.id - id of account/vessel/marina
 * @param {string} props.type
 * @param {function} props.show - function used to show/hide container - boolean
 * @param {function} props.setImage
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const ImageUpload = props => {
	const handleFileUpload = event => {
		// console.log("submit pressed");
		event.preventDefault()
		try {
			const canvas = document.getElementById("canvas")
			canvas.toBlob(fileToSend => {
				uploadImage(fileToSend, props.type, props.id).then(response => {
					// console.log("response", response)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						// props.setImage(new Image({path: response.payload.path, verified: false}))
						props.refresh().then()
						props.show(false)
					}
				})
			}, "image/png")
		}
		catch(e) {
			console.log(e)
		}
	}
	return <div id={"imageEdit"} className={"w3-display-middle w3-display-container w3-animate-opacity"}
		style={{height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0.9)", zIndex: "25"}}>
		<div className="w3-display-middle">
			<form onSubmit={handleFileUpload} encType={"multipart/form-data"}>
				<MobileCanvas/>
				<div style={{display: "flex"}}>
					<button type={"button"} className={"w3-button paralian-theme-level-3 w3-ripple"}
						style={{transition: "background-color 2s", textAlign: "center", fontSize: "3vh",
							margin: "2vh 4vw", padding: "1vh 2vw", width: "39vw", maxWidth: "20vmax"}}
						onClick={() => props.show(false)}>
						{translate(DICTIONARY.CANCEL.X)}
					</button>
					<button type={"submit"} className={"w3-button paralian-theme-level-3 w3-ripple"}
						style={{transition: "background-color 2s", textAlign: "center", fontSize: "3vh",
							margin: "2vh 4vw", padding: "1vh 2vw", width: "39vw", maxWidth: "20vmax"}}>
						{translate(DICTIONARY.SUBMIT.X)}
					</button>
				</div>
			</form>
		</div>
		<div className={"w3-display-bottommiddle"} style={{display: "block", marginBottom: "3vh", width: "90%",
			maxWidth: "60vmax", fontSize: "2vh"}}>
			{translate(DICTIONARY.IMAGE_UPLOAD_MESSAGE.X)}
		</div>
	</div>
}

export default ImageUpload