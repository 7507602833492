import React from "react"
import {SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import {getContactInfoFromServer, getWebsites} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
import ContactInfo from "../../../../../globals/classes/shared/ContactInfo"
import Websites from "../../../../../globals/classes/shared/Websites"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.dispute
 * @param {Account} props.order.provider
 * @param {function} props.setContactInfo
 * @param {function} props.setWebsites
 * @param {function} props.setShowDisputeModal
 * @return {JSX.Element}
 * @constructor
 */
const DisputeOrderButton = props => {
	return props.order.buttons.dispute ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"dispute"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.DISPUTE.X)}} left={{icon: ICONS.EXCLAMATION_TRIANGLE}}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%"}} onClick={async () => {
				const ciResponse = await getContactInfoFromServer(null, props.order.provider.id, true)
				const wsResponse = await getWebsites(props.order.provider.id)
				props.setContactInfo(ciResponse.status === SERVER_RESPONSE_STATE.SUCCESS ?
					new ContactInfo(ciResponse.payload) : new ContactInfo())
				props.setWebsites(wsResponse.status === SERVER_RESPONSE_STATE.SUCCESS ?
					new Websites(wsResponse.payload) : new Websites())
				props.setShowDisputeModal(true)
			}}/>
		</div> : <></>
}

export default DisputeOrderButton