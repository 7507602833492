import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.reject
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const RejectOrderButton = props => {
	const [disableButtons, setDisableButtons] = useState(false)
	return props.order.buttons.reject ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"reject"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.REJECT.X)}} left={{icon: ICONS.BAN}} className={"paralian-theme-level-1"}
				padding={"1vh"} style={{width: "100%"}} disabled={disableButtons} onClick={async () => {
				try {
					const confirmed = window.confirm(translate(DICTIONARY.REJECT_ORDER_PROMPT.X))
					if (confirmed) {
						setDisableButtons(true)
						const response = await editOrder(props.order.id, ORDER_BUTTON.REJECT)
						// console.log(response)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refreshOrders()
							fadingMessage("reject-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
						}
						setDisableButtons(false)
					}
				} catch (e) {
					console.log(e)
					setDisableButtons(false)
				}
				console.log("reject")
			}}/>
		</div> : <></>
}

export default RejectOrderButton