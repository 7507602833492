import React from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import Time from "../../../globals/classes/shared/Time"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {number} props.min
 * @param {number} props.max
 * @param {Time} props.time
 * @param {string} props.topRadius
 * @param {string} props.bottomRadius
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {boolean} props.use24Hr
 * @param {boolean} props.left - true to show left icon, false to hide
 * @param {boolean} props.right - true to show right icon, false to hide
 * @param {string} props.iconPrefix - "fas", "fad", etc.
 * @returns {JSX.Element}
 * @constructor
 */
const MobileTimeField = props => {
	const onChange = () => {
		try {
			const hr = document.getElementById(`${props.id}-hour-input`).value
			const min = document.getElementById(`${props.id}-minute-input`).value
			const xm = document.getElementById(`${props.id}-format-input`).value
			props.setTime(new Time({hour: hr, minute: min, xm: xm}))
		} catch (e) {
			console.log(e)
		}
	}
	const iconPrefix = props.iconPrefix || "fad"
	const defaults = props.time || new Time({hour: null, minute: null, xm: null})
	// console.log(defaults)
	const left = props.left ?
		<MobileFontAwesome icon={[iconPrefix, ICONS.CLOCK[1]]} className={"paralian-field-icons"}/> : <></>
	const right = props.right ?
		<MobileFontAwesome icon={[iconPrefix, ICONS.ALARM_CLOCK[1]]} className={"paralian-field-icons"}
			onClick={() => {
				try {
					const now = new Date()
					let hour = now.getHours()
					let minutes = now.getMinutes()
					document.getElementById(`${props.id}-hour`).value = hour
					document.getElementById(`${props.id}-minute`).value = minutes
				} catch (e) {
					console.log(e)
				}
		}}/> : <></>
	const format = props.use24Hr ? <></> : <>
		<label htmlFor={`${props.id}-format-input`} style={{display: "none"}}>{translate(DICTIONARY.MILITARY_TIME.X)}</label>
		<select id={`${props.id}-format-input`} className={"paralian-input paralian-no-drop-arrow"} size={"1"} name={"format"}
			form={"form"} placeholder={"--"}
			autoFocus={false} required={true}	defaultValue={defaults.xmString} multiple={false}
			style={{color: "inherit", height: "100%", width: "100%", margin: "1vh 0vh 1vh 1vh",
				whiteSpace: "pre", textOverflow: "ellipsis", overflow: "hidden", WebkitAppearance: "none",
				MozAppearance: "none", appearance: "none"}} onChange={onChange}>
			{[{label: "AM", value: "AM"}, {label: "PM", value: "PM"}].map(item => <option
				className={"paralian-theme-level-1-flipped"} key={`${props.id}-${item.value}`} value={item.value}
				label={item.label}>
				{item.label}
			</option>)}
		</select></>

	const min = props.use24Hr ? 0 : 1
	const max = props.use24Hr ? 23 : 12
	const makeList = (min, max, step = 1) => {
		const list = []
		for (let i = 0; i < max; i = i + step) {
			const value = min + i
			list[i] = {label: `${value < 10 ? `0${value}` : value}`, value: `${value}`}
		}
		return list
	}
	const hours = makeList(min, max)
	const minutes = makeList(0, 59, 5)
	minutes.push({label: "59", value: "59"})
	const defaultHour = props.use24Hr ? defaults.hourAs24hr : defaults.hourAsXM
	return <div id={props.id} className={`mobileTimeField w3-center ${props.className}`}
		style={{display: "flex", borderTopRightRadius: props.topRadius,	borderTopLeftRadius: props.topRadius,
			borderBottomRightRadius: props.bottomRadius, borderBottomLeftRadius: props.bottomRadius, ...props.style}}>
		{left}
		<div style={{display: "flex", margin: "auto"}}>
			<label htmlFor={`${props.id}-hour-input`} style={{display: "none"}}>{translate(DICTIONARY.HOUR.X)}</label>
			<select id={`${props.id}-hour-input`} className={"paralian-input paralian-no-drop-arrow"}
				size={"1"} name={"hour"} form={"form"} placeholder={"--"}	autoFocus={false} required={true}
				defaultValue={defaultHour} multiple={false} style={{margin: "1vh 0vh", height: "100%"}} onChange={onChange}>
					{hours.map(item => {return <option className={"paralian-theme-level-1-flipped"} key={`${props.id}-${item.value}`}
						value={item.value} label={item.label}>{item.label}</option>})}
			</select>
			<div className={"paralian-input"} style={{height: "100%", margin: "1vh 0vh"}}>:</div>
			<label htmlFor={`${props.id}-minute-input`} style={{display: "none"}}>{translate(DICTIONARY.MINUTES.X)}</label>
			<select id={`${props.id}-minute-input`} className={"paralian-input paralian-no-drop-arrow"}
				size={"1"} name={"minute"} form={"form"} placeholder={"--"}	autoFocus={false} required={true}
				defaultValue={defaults.minute === 59 ? 59 : Math.ceil(defaults.minute/5)*5}
				multiple={false} style={{margin: "1vh 0vh", height: "100%"}} onChange={onChange}>
					{minutes.map(item => {return <option className={"paralian-theme-level-1-flipped"}	key={`${props.id}-${item.value}`}
						value={item.value} label={item.label}>{item.label}</option>})}
					<option style={{display: "none"}} key={`${props.id}-59`} value={"59"} label={"59"} hidden={true}/>
			</select>
			{format}
		</div>
		{right}
	</div>
}

export default MobileTimeField