import React, {useEffect, useState} from "react"
import {DICTIONARY} from "../../../../globals/constants/client/constants"
import {getFromStorage, replaceInStorage, translate} from "../../../../globals/functions/client/localFunctions"
import RadioButton from "./RadioButton"
import MobileGenericDropdown from "../../../shared/input/dropdown/MobileGenericDropdown"

const EngineFlush = props => {
	const session_ = getFromStorage("order", "session")
	const [selected, setSelected] = useState(session_.engineFlush ? (session_.engineFlush.length > 0 ? "yes" : "no") : "no")
	const [quantity, setQuantity] = useState(props.vessel ? props.vessel.engines.length : 2)
	const onClick = v => setSelected(v)
	useEffect(() => {
		if (session_.engineFlush && session_.engineFlush.length > 0) {
			setQuantity(session_.engineFlush[0].quantity)
		} else {
			replaceInStorage("order", "engineFlush", [], "session")
		}
	}, [])
	return <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh 1vh", fontSize: "3vh", maxWidth: "80%"}}>
			{translate(DICTIONARY.INCLUDE_FLUSH_QUESTION.X)}
		</legend>
		<RadioButton id={"yes"} title={translate(DICTIONARY.YES_CAP.X)} onClick={onClick} selected={selected === "yes"}
			defaultChecked={selected === "yes"} name={"engineFlush"}/>
		<RadioButton id={"no"} title={translate(DICTIONARY.NO_CAP.X)} onClick={onClick} selected={selected === "no"}
			name={"engineFlush"} defaultChecked={selected === "no"}/>
		{selected === "yes" ?
			<div style={{padding: "4vh 4vh 2vh"}}>
				<MobileGenericDropdown id={"engines"} showLabel={true} options={[{id: 1, label: 1}, {id: 2, label: 2},
					{id: 3, label: 3}, {id: 4, label: 4}, {id: 5, label: 5}, {id: 6, label: 6}]}
					className={"paralian-theme-level-2"} defaultValue={quantity}
					label={translate(DICTIONARY.QUANTITY.X)} onChange={id => replaceInStorage("order", "engineFlush",
					[{id: 1013, quantity: id}], "session")}/>
			</div> : <></>}
	</fieldset>
}

export default EngineFlush