import React from "react"
import confirmLoginUsername from "./confirmLoginUsername"
import MobileUsernameField from "../../shared/input/text/MobileUsernameField"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"

/**
 * Account login field. Accepts username, login email, login phone.
 * @copyright Paralian LLC
 * @author fernando@paralian.io
 * @return {JSX.Element}
 * @constructor
 */
const MobileLoginUsernameEntry = () => {
	const content = <MobileUsernameField id={"username"} form={"username-form"} className={"paralian-theme-level-1-flipped"}/>
	return <MobileInputForm id={"username"} content={content} onSubmit={confirmLoginUsername} buttonSize={"6vh"}
		style={{width: "100%", padding: "0vh 4vh"}} className={"w3-display-middle w3-animate-opacity"}/>
}

export default MobileLoginUsernameEntry