import React, {useState} from "react"
import {IMAGES, DICTIONARY} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileStickyBannerExpandDown from "../../MobileStickyBannerExpandDown"
import MobileRigForetriangle from "./MobileRigForetriangle"
import MobileRigInnerForetriangle from "./MobileRigInnerForetriangle"
import MobileRigMainsail from "./MobileRigMainsail"
import MobileRigMizzen from "./MobileRigMizzen"
import MobileRigSpinnaker from "./MobileRigSpinnaker"

const MobileRig = props => {
	const [expanded, setExpanded] = useState(false)
	const content = !expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"} style={{fontSize: "2vh"}}>
		<div style={{width: "100%"}}>
			<picture>
				<img src={IMAGES.RIG} alt={"rig"}/>
			</picture>
		</div>
		<MobileRigForetriangle id={props.id} rig={props.rig} units={props.units} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileRigInnerForetriangle id={props.id} rig={props.rig} units={props.units} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileRigMainsail id={props.id} rig={props.rig} units={props.units} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileRigMizzen id={props.id} rig={props.rig} units={props.units} canEdit={props.canEdit} refresh={props.refresh}/>
		<MobileRigSpinnaker id={props.id} rig={props.rig} units={props.units} canEdit={props.canEdit} refresh={props.refresh}/>
	</div>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} text={translate(DICTIONARY.RIG.X)} top={props.top}
			onClick={() => setExpanded(!expanded)}/>
		{content}
	</>
}

export default MobileRig