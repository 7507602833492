import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../../MobileFontAwesome"
import MobileVesselAccessCard from "./MobileVesselAccessCard"
import MobileAddNewBanner from "../../MobileAddNewBanner"
import MobileVesselAccessAdd from "./MobileVesselAccessAdd"
import Modal from "../../../../globals/components/Modal"

const MobileVesselAccessIndividual = props => {
	const [expanded, setExpanded] = useState(false)
	const [add, setAdd] = useState(false)
	const individualsContent = props.individuals.map(individual =>
			<MobileVesselAccessCard id={props.id} key={`access-${individual.id}-${individual.username}}`} onEdit={props.onEdit}
				accesses={individual} account={props.account} canEdit={props.canEdit} onRemove={props.onRemove}/>)
	return <>
		<div className={"paralian-theme-level-2 w3-ripple w3-center"} style={{padding: "2vh", margin: "2vh", cursor: "pointer"}}
			onClick={() => setExpanded(!expanded)}>
			{translate(DICTIONARY.INDIVIDUALS.X)}
			<MobileFontAwesome icon={expanded ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN} style={{padding: "1.5vh"}}/>
		</div>
		{!expanded ? <></> : <div className={"w3-animate-top w3-animate-opacity"}>
			{individualsContent}
			{add ? <Modal id={"grant-access-individual-modal"} icon={ICONS.KEY} title={translate(DICTIONARY.GRANT_ACCESS.X)}
				padding={"0vh"} onClick={() => setAdd(false)} body={
				<MobileVesselAccessAdd id={props.id} accesses={props.accountAccess} account={props.account}
					onAdd={() => {props.onAdd(); setAdd(false)}} toOrganization={false}/>
			}/> : <></>}
			{props.canEdit ? <MobileAddNewBanner text={translate(DICTIONARY.GRANT_ACCESS.X)} onClick={() => setAdd(!add)}/> : <></>}
		</div>}
	</>
}

export default MobileVesselAccessIndividual