import React from "react"

/**
 *
 * @param {Object} props
 * @param {string|number} props.id
 * @param {string} props.form
 * @param {Currency} props.currency
 * @param {React.HTMLAttributes.className} props.className
 * @param {number} props.defaultValue
 * @param {function} props.setValue
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputCurrency = props => {
	const setValue = props.setValue ? props.setValue : () => {}
	const className = props.className || "paralian-theme-level-1-flipped"
	const form = props.form || "form"
	return <div className={className} style={{width: "fit-content", padding: "0vh 2vh", display: "inline-flex"}}>
		<span style={{padding: "4vh 0vh"}}>{props.currency.asString}</span>
		<input type={"number"} id={`${props.id}-input`} className={"paralian-input-field"} min={"0"} max={"999999.99"}
			name={"name"} form={form} autoFocus={false} maxLength={8} defaultValue={props.defaultValue}
			placeholder={"###.##"} style={{color: "inherit", height: "12vh", width: "12vh"}} required={true} step={"0.01"}
				onInputCapture={() => setValue(Number(document.getElementById(`${props.id}-input`).value))}/>
	</div>
}

export default MobileInputCurrency
