import React from "react"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import MobileInputCountryCodeDropdown from "./MobileInputCountryCodeDropdown"
/**
 * @file creates a generic input field with its corresponding hidden label field
 * @copyright Paralian LLC 2020
 * @author Fernando Velez <fernando@paralian.io>
 * @function MobileInputPhoneField
 * @param props {Object}
 * @param props.id {string}
 * @param props.form {string}
 * @param props.left {Object}
 * @param props.left.icon {string} - FontAwesome classname of left icon
 * @param props.left.onClick {Object} - function to be executed on click of left icon
 * @param props.right.icon {string} - FontAwesome classname of right icon
 * @param props.right.onClick {Object} - function to be executed on click of right icon
 * @param props.style {Object} - style object for the parent element
 * @param props.topRadius {string} - top radius of the parent element
 * @param props.bottomRadius {string} - bottom radius of the parent element
 * @param props.autoComplete {string}
 * @param props.autoFocus {boolean}
 * @param props.required {boolean}
 * @param {string} props.countryCode
 * @param props.nationalNumber {Object}
 * @param props.nationalNumber.placeholder {string}
 * @param props.nationalNumber.defaultValue {string}
 * @param props.nationalNumber.autoFocus {boolean}
 * @param props.nationalNumber.label {string}
 * @param {string} props.label
 * @param {boolean} props.showLabel
 * @param {Object} props.labelStyle
 * @param {React.HTMLAttributes.className} props.className
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputPhoneField = props => {
	const ccAutoComplete = props.autoComplete === "off" ? "off" : "tel-country-code"
	const nnAutoComplete = props.autoComplete === "off" ? "off" : "tel-national"
	return <div style={{...props.style, borderBottomLeftRadius: props.bottomRadius, borderBottomRightRadius: props.bottomRadius,
		borderTopLeftRadius: props.topRadius, borderTopRightRadius: props.topRadius, width: "100%"}}>
		<div className={"paralian-label-field"} style={{display: props.showLabel ? "block" : "none", ...props.labelStyle}}>
			{props.label}
		</div>
		<MobileInputCountryCodeDropdown left={props.left} id={`${props.id}-country-code`}
			name={`${props.id}-country-code`} autoComplete={ccAutoComplete} showLabel={false} right={props.right}
			form={props.form} defaultValue={props.countryCode.defaultValue} required={props.required}
			className={props.className}/>
		<MobileInputGeneric type={"tel"} id={`${props.id}-national-number`} className={props.className}
			label={props.nationalNumber.label} showLabel={false} name={`${props.id}-national-number`} maxLength={15}
			autoFocus={false} autoComplete={nnAutoComplete}	required={props.required} form={props.form}
			placeholder={props.nationalNumber.placeholder} defaultValue={props.nationalNumber.defaultValue}/>
	</div>
}
export default MobileInputPhoneField