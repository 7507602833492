import React, {useState} from "react"
import {store} from "../../../../index"
import {actions} from "../../../../globals/constants/client/reduxData"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {DICTIONARY, PAGE_STATES, PAGES} from "../../../../globals/constants/client/constants"
import {
  getFromStorage,
  getTimeFormat,
  parsedLengthToMeters,
  replaceInStorage,
  translate
} from "../../../../globals/functions/client/localFunctions"
import {setHullDimensions} from "../../../../globals/functions/client/serverFunctions"
import MobileVesselCleaningProviders from "./MobileVesselCleaningProviders"
import MobileVesselCleaningPayment from "./MobileVesselCleaningPayment"
import Success from "../../vessels/Success"
import VesselSelect from "./VesselSelect"
import VesselCleaningForm from "./VesselCleaningForm"
import MarinaSelect from "./MarinaSelect"
import CleaningType from "./CleaningType"
import DueDate from "./DueDate"
import Message from "./Message"
import StartDate from "./StartDate"
import VesselLocation from "./VesselLocation"
import VesselCleaningHullSpecifications from "./VesselCleaningHullSpecifications"
import SurfaceState from "./SurfaceState"
import SurfaceProtection from "./SurfaceProtection"
import ExteriorCleaningAdvanced from "./ExteriorCleaningAdvanced"
import EngineFlush from "./EngineFlush"
/* TODO: confirm email address and phone number before generating order */
const MobileVesselCleaning = props => {
  const currentState = store.getState()
  const levelOne = currentState.window.current.levelOne
  const parentPage = PAGE_STATES[levelOne].MAIN.REQUESTS
  const isOrganization = props.account.isAdminOrEmployee
  const use24Hr = getTimeFormat(props.account) === "24Hr"
  const session = getFromStorage("order", "session")
  const [disabled, setDisabled] = useState(false)
  // console.log("session", session)
  let content, back, next, onSubmit, onSubmitPromise
  let onSubmit_ = event => {
    try {
      event.preventDefault()
      if (event.target.id === "form") {
        if (onSubmitPromise) {onSubmitPromise().then(() =>
          store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})).catch(e => console.log(e))
        } else {
          if (onSubmit) {onSubmit()}
          store.dispatch({type: actions.SET_CURRENT_WINDOW,	values: {window: next}})
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  /* TODO: vessel location (checkmark for if in water) & length */
  switch (currentState.window.current.levelFive) {
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.LENGTH.X:
      const vessel_ = [...props.vessels.owned, ...props.vessels.customer].find(v => v.id === session.vessels[0].id)
      content = <VesselCleaningHullSpecifications form={"form"} vessel={vessel_}/>
      back = parentPage.VESSEL_CLEANING.LOCATION.X
      next = parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X
      onSubmitPromise = async () => {
        let lengthFraction = 0 // , beamFraction = 0, draughtFraction = 0, heightFraction = 0
        const lengthWhole = Number(document.getElementById("hull-loa-whole-input").value)
        try {
          lengthFraction = Number(document.getElementById("hull-loa-fraction-input").value)
        } catch (e) {}
        /*
        const beamWhole = Number(document.getElementById("hull-beam-whole-input").value)
        try {
          beamFraction = Number(document.getElementById("hull-beam-fraction-input").value)
        } catch (e) {}
        const draughtWhole = Number(document.getElementById("hull-draught-whole-input").value)
        try {
          draughtFraction = Number(document.getElementById("hull-draught-fraction-input").value)
        } catch (e) {}
        const heightWhole = Number(document.getElementById("hull-height-whole-input").value)
        try {
          heightFraction = Number(document.getElementById("hull-height-fraction-input").value)
        } catch (e) {}
        */
        // console.log(parsedLengthToMeters(lengthWhole, lengthFraction, props.units))
        const length = props.units === "SI" ? lengthWhole : parsedLengthToMeters(lengthWhole, lengthFraction, props.units)
        /*
        const beam = props.units === "SI" ? beamWhole : parsedLengthToMeters(beamWhole, beamFraction, props.units)
        const draught = props.units === "SI" ? draughtWhole : parsedLengthToMeters(draughtWhole, draughtFraction, props.units)
        const height = props.units === "SI" ? heightWhole : parsedLengthToMeters(heightWhole, heightFraction, props.units)
        */
        // console.log(lengthWhole, lengthFraction, beamWhole, beamFraction, draughtWhole, draughtFraction, heightWhole, heightFraction, props.units)
        // console.log(props.id, length, beam, draught, height)
        const response = await setHullDimensions(vessel_.id, length, vessel_.hull.dimensions.beam,
          vessel_.hull.dimensions.draught, vessel_.hull.dimensions.clearance)
        if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {
          throw new Error("setHullDimensions failed")
        } else {
          props.refreshVessels()
        }
      }
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.PROVIDERS.X:
      content = <MobileVesselCleaningProviders setDisabled={setDisabled}/>
      back = parentPage.VESSEL_CLEANING.MESSAGE.X
      next = parentPage.VESSEL_CLEANING.PAYMENT.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.PAYMENT.X:
      content = <MobileVesselCleaningPayment account={props.account} refreshAccount={props.refreshAccount}
        organization={props.organization} refreshOrganization={props.refreshOrganization} services={props.services}/>
/*      return <MobileVesselCleaningPaymentTest account={props.account} organization={props.organization}
        refreshAccount={props.refreshAccount} refreshOrganization={props.refreshOrganization}/>*/
      back = parentPage.VESSEL_CLEANING.PROVIDERS.X
      next = parentPage.VESSEL_CLEANING.SUCCESS.X
      onSubmit_ = null
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SUCCESS.X:
      content = <Success label={translate(DICTIONARY.ORDER_ENTERED_EXCLAMATION.X)}/>
      onSubmit_ = null
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.ADVANCED.X:
      content = <ExteriorCleaningAdvanced/>
      back = parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X
      next = parentPage.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X
      onSubmit = () => {
        const items = document.getElementById("multiple-select-exterior").getAttribute("data-a") || ""
        replaceInStorage("order", "servicesExterior", items.split(",").filter(Number).map(v =>
          {return{id: v, quantity: 1}}), "session")
      }
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.VESSEL.X:
      content = <VesselSelect account={props.account} vessels={props.vessels} refresh={props.refreshVessels}
        setDisabled={setDisabled}/>
      back = parentPage.VESSEL_CLEANING.MARINA.X
      next = parentPage.VESSEL_CLEANING.LOCATION.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.LOCATION.X:
      const vessel = [...props.vessels.owned, ...props.vessels.customer].find(v => v.id === session.vessels[0].id)
      content = <VesselLocation account={props.account} vessels={props.vessels} marinas={props.marinas}
        refresh={props.refreshVessels} setDisabled={setDisabled}/>
      back = parentPage.VESSEL_CLEANING.VESSEL.X
      /* TODO: beam - maybe */
      next = vessel.hull.dimensions.length ? parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X :
        parentPage.VESSEL_CLEANING.LENGTH.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X:
      content = <CleaningType area={"exterior"} isOrganization={isOrganization}/>
      back = parentPage.VESSEL_CLEANING.LOCATION.X
      next = parentPage.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.START.X:
      content = <StartDate use24Hr={use24Hr} setDisabled={setDisabled}/>
      back = parentPage.VESSEL_CLEANING.DUE.X
      next = parentPage.VESSEL_CLEANING.MESSAGE.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.DUE.X:
      if (session.servicesExterior.some(v => v.id === -1) && session.servicesInterior.some(v => v.id === -1)) {
        content = <CleaningType area={"exterior"}/>
        back = parentPage.VESSEL_CLEANING.VESSEL.X
        next = parentPage.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X
        break
      }
      content = <DueDate use24Hr={use24Hr}/>
      back = !session.servicesExterior.some(v => v.id === -1 || v.id === 1015) ? parentPage.VESSEL_CLEANING.ENGINE_FLUSH.X :
        parentPage.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X
      next = parentPage.VESSEL_CLEANING.START.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.ENGINE_FLUSH.X:
      content = <EngineFlush vessel={[...props.vessels.owned, ...props.vessels.customer]
        .find(v => v.id === session.vessels[0].id)}/>
      back = parentPage.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X
      next = parentPage.VESSEL_CLEANING.DUE.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.MESSAGE.X:
      content = <Message id={"vessel-cleaning"} legend={translate(DICTIONARY.SPECIAL_INSTRUCTIONS.X)}
      placeholder={translate(DICTIONARY.SPECIAL_INSTRUCTIONS_VESSEL_CLEANING_PLACEHOLDER.X)} onChange={() => {
        replaceInStorage("order", "message",
          document.getElementById("vessel-cleaning-message").value, "session")
      }}/>
      back = parentPage.VESSEL_CLEANING.START.X
      next = parentPage.VESSEL_CLEANING.PROVIDERS.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X:
      if (!session.servicesExterior.some(v => v.id === -1)) {
        if (session.servicesExterior[0].id === 1015) {
          // bottom scrub
          content = <DueDate use24Hr={use24Hr}/>
          back = parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X
          next = parentPage.VESSEL_CLEANING.START.X
          break
        }
        if (session.servicesExterior[0].id === 999999) {
          // detail
          replaceInStorage("order", "servicesExterior",
            [{id: 1099, quantity: 1}, {id: 1101, quantity: 1}, {id: 1007, quantity: 1}, {id: 1105, quantity: 1}], "session")
          content = <SurfaceState onClick={id => {
            let ids
            switch (id) {
              case "stained":
                ids = [{id: 1099, quantity: 1}, {id: 1101, quantity: 1}, {id: 1007, quantity: 1}, {id: 1105, quantity: 1}]
                break
              case "scratched":
                ids = [{id: 1101, quantity: 1}, {id: 1007, quantity: 1}, {id: 1105, quantity: 1}]
                break
              case "dull":
                ids = [{id: 1007, quantity: 1}, {id: 1105, quantity: 1}]
                break
              case "shiny":
                ids = [{id: 999999, quantity: 1}, {id: 1105, quantity: 1}]
                break
            }
            replaceInStorage("order", "servicesExterior", ids, "session")
          }}/>
          back = parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X
          next = parentPage.VESSEL_CLEANING.PROTECTION_LEVEL.X
          break
        }
      }
      content = <CleaningType area={"interior"}/>
      back = session.servicesExterior.some(v => [1006, 1100, 1012, 1102, 1103, 1104].includes(v.id)) ?
        parentPage.VESSEL_CLEANING.PROTECTION_LEVEL.X :
        parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X
      next = !session.servicesExterior.some(v => v.id === -1) ? parentPage.VESSEL_CLEANING.ENGINE_FLUSH.X :
        parentPage.VESSEL_CLEANING.DUE.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.SURFACE_STATE.X:
      const filtered_ = session.servicesExterior.filter(v => !([1099, 1101, 1007, 1105, 999999].includes(v.id)))
      if (filtered_.length === session.servicesExterior.length) {
        replaceInStorage("order", "servicesExterior",
          [...session.servicesExterior, {id: 1099, quantity: 1}, {id: 1101, quantity: 1},
            {id: 1007, quantity: 1}, {id: 1105, quantity: 1}], "session")
      }
      content = <SurfaceState onClick={id => {
        let ids
        switch (id) {
          case "stained":
            ids = [{id: 1099, quantity: 1}, {id: 1101, quantity: 1}, {id: 1007, quantity: 1}, {id: 1105, quantity: 1}]
            break
          case "scratched":
            ids = [{id: 1101, quantity: 1}, {id: 1007, quantity: 1}, {id: 1105, quantity: 1}]
            break
          case "dull":
            ids = [{id: 1007, quantity: 1}, {id: 1105, quantity: 1}]
            break
          case "shiny":
            ids = [{id: 999999, quantity: 1}, {id: 1105, quantity: 1}]
            break
        }
        const session = getFromStorage("order", "session")
        const filtered = session.servicesExterior.filter(v => !([1099, 1101, 1007, 1105, 999999].includes(v.id)))
        replaceInStorage("order", "servicesExterior", [...filtered, ...ids], "session")
      }}/>
      back = parentPage.VESSEL_CLEANING.SERVICE_TYPE_EXTERIOR.X
      next = parentPage.VESSEL_CLEANING.PROTECTION_LEVEL.X
      break
    case PAGES.COMMON.MAIN.REQUESTS.VESSEL_CLEANING.PROTECTION_LEVEL.X:
      const filtered__ = session.servicesExterior.filter(v => !([1006, 1100, 1012, 1102, 1103, 1104, 999999].includes(v.id)))
      if (filtered__.length === session.servicesExterior.length) {
        replaceInStorage("order", "servicesExterior",
          [...session.servicesExterior, {id: 1100, quantity: 1}], "session")
      }
      content = <SurfaceProtection onClick={id => {
        const session = getFromStorage("order", "session")
        let ids
        switch (id) {
          case "wax":
            ids = session.servicesExterior.some(v => v.id === 1007) ?
              [{id: 1006, quantity: 1}] : [{id: 1104, quantity: 1}]
            break
          case "sealant":
            ids = [{id: 1100, quantity: 1}]
            break
          case "ceramic-1":
            ids = [{id: 1012, quantity: 1}]
            break
          case "ceramic-2":
            ids = [{id: 1102, quantity: 1}]
            break
          case "ceramic-3":
            ids = [{id: 1103, quantity: 1}]
            break
        }
        const filtered = session.servicesExterior.filter(v => !([1006, 1100, 1012, 1102, 1103, 1104, 999999].includes(v.id)))
        replaceInStorage("order", "servicesExterior", [...filtered, ...ids], "session")
      }}/>
      back = parentPage.VESSEL_CLEANING.SURFACE_STATE.X
      next = parentPage.VESSEL_CLEANING.SERVICE_TYPE_INTERIOR.X
      break
    default:
      content = <MarinaSelect account={props.account} marinas={props.marinas} refresh={props.refreshMarinas}
        setDisabled={setDisabled}/>
      back = PAGE_STATES[levelOne].MAIN.REQUESTS.X
      next = parentPage.VESSEL_CLEANING.VESSEL.X
  }
  return <VesselCleaningForm content={content} back={back} isOrganization={isOrganization} onSubmit={onSubmit_}
    disabled={disabled}/>
}

export default MobileVesselCleaning