import React, {useState} from "react"
import {ICONS} from "../../../globals/constants/client/constants"
import MobileFontAwesome from "../MobileFontAwesome"

/**
 *
 * @param {Object} props
 * @param {function} props.setRating
 * @return {JSX.Element}
 * @constructor
 */
const StarRating = props => {
	const filled = ["fas", ICONS.STAR[1]]
	const empty = ["far", ICONS.STAR[1]]
	const [rating, setRating] = useState([empty, empty, empty, empty, empty])
	const zero = () => {
		try {
			setRating([empty, empty, empty, empty, empty])
			props.setRating(0)
		} catch (e) {console.log(e)}
	}
	const style = props.style || {padding: "1.5vh"}
	return <>
		<MobileFontAwesome icon={rating[0]} style={style} onClick={() => {
			const setTo1 = () => {
				try {
					setRating([filled, empty, empty, empty, empty])
					props.setRating(1)
				} catch (e) {console.log(e)}
			}
			if (rating[0][0] === "far") {
				setTo1()
			} else {
				if (rating[1][0] === "fas") {
					setTo1()
				} else {
					zero()
				}
			}
		}}/>
		<MobileFontAwesome icon={rating[1]} style={style} onClick={() => {
			const setTo2 = () => {
				try {
					setRating([filled, filled, empty, empty, empty])
					props.setRating(2)
				} catch (e) {console.log(e)}
			}
			if (rating[1][0] === "far") {
				setTo2()
			} else {
				if (rating[2][0] === "fas") {
					setTo2()
				} else {
					zero()
				}
			}
		}}/>
		<MobileFontAwesome icon={rating[2]} style={style} onClick={() => {
			const setTo3 = () => {
				try {
					setRating([filled, filled, filled, empty, empty])
					props.setRating(3)
				} catch (e) {console.log(e)}
			}
			if (rating[2][0] === "far") {
				setTo3()
			} else {
				if (rating[3][0] === "fas") {
					setTo3()
				} else {
					zero()
				}
			}
		}}/>
		<MobileFontAwesome icon={rating[3]} style={style} onClick={() => {
			const setTo4 = () => {
				try {
					setRating([filled, filled, filled, filled, empty])
					props.setRating(4)
				} catch (e) {console.log(e)}
			}
			if (rating[3][0] === "far") {
				setTo4()
			} else {
				if (rating[4][0] === "fas") {
					setTo4()
				} else {
					zero()
				}
			}
		}}/>
		<MobileFontAwesome icon={rating[4]} style={style} onClick={() => {
			const setTo5 = () => {
				try {
					setRating([filled, filled, filled, filled, filled])
					props.setRating(5)
				} catch (e) {console.log(e)}
			}
			if (rating[4][0] === "far") {
				setTo5()
			} else {
				zero()
			}
		}}/>
	</>
}

export default StarRating