import React, {useEffect, useRef, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {IMAGE_CATEGORY, SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {Elements} from "@stripe/react-stripe-js"
import {stripePromise} from "../../../index"
import {
	getFromStorage,
	getUnits,
	newMessage,
	setToStorage,
	translate
} from "../../../globals/functions/client/localFunctions"
import {
	getCurrentAccount,
	getOrganization,
	stripeOnboardConnectAccount,
} from "../../../globals/functions/client/serverFunctions"
import ImageUpload from "../../../tools/ImageUpload"
import MobileAddressList from "../address/MobileAddressList"
import MobileColors from "../color/MobileColors"
import MobileCurrencyAndTimezone from "../currency/MobileCurrencyAndTimezone"
import MobileServiceMarinas from "../../login/new/organization/MobileServiceMarinas"
import MobileSchedule from "../calendar/MobileSchedule"
import MobileBusinessRating from "../MobileBusinessRating"
import MobileImageAndLogo from "./MobileImageAndLogo"
import MobileOrganizationDocuments from "../MobileOrganizationDocuments"
import MobileContactInfo from "../contact/MobileContactInfo"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileServiceArea from "../service/MobileServiceArea"
import MobileServices from "../service/MobileServices"
import MobileOrgLocation from "../address/MobileOrgLocation"
import MobileAdmins from "../employee/MobileAdmins"
import MobileEmployees from "../employee/MobileEmployees"
import MobileParalianSubscriptions from "../products/MobileParalianSubscriptions"
import MobilePaymentMethods from "../payment/MobilePaymentMethods"
import MobileMarinaFacilities from "../marinas/facilities/MobileMarinaFacilities"
import MobileRequiredDocumentsSection from "../documents/MobileRequiredDocumentsSection"
import MobileOrganizationControl from "./MobileOrganizationControl"
import Organization from "../../../globals/classes/shared/Organization"
import Account from "../../../globals/classes/shared/Account"
import imagePlaceholder from "../../../images/image-duotone.svg"
import logoPlaceholder from "../../../images/pencil-paintbrush-duotone.svg"

/**
 *
 * @param {Object} props
 * @param {number} props.id
 * @param {Account} props.account
 * @param {function} props.setAccount
 * @return {JSX.Element}
 * @constructor
 */
const MobileOrganization = props => {
	/* TODO: profile X% complete */
	/* TODO: response statistics - usually responds within x minutes */
	/* TODO: flash sections which are incomplete to prompt user to provide the data */
	const [organization, setOrganization] = useState(props.id ? new Organization() : props.organization)
	// console.log("organization", organization)
	const [showImageUpload, setShowImageUpload] = useState(false)
	const [imageUploadType, setImageUploadType] = useState()
	const scheduleRef = useRef(null)
	const pricingRef = useRef(null)
	const paymentRef = useRef(null)
	const subscriptionRef = useRef(null)
	const refresh = async () => {
		if (props.id) {
			const response = await getOrganization(false, props.id )
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				setOrganization(new Organization(response.payload))
			}
		} else {
			await props.refreshOrganization()
		}
	}
	const refreshAccount = async () => {
		const response = getCurrentAccount(false)
		if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
			props.setAccount(new Account(response.payload))
		}
	}
	useEffect(() => {
		if (props.id) {
			getOrganization(true, props.id).then(response => {
				if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
					const organization_ = new Organization(response.payload)
					setOrganization(organization_)
				}})
		} else {
			setOrganization(props.organization)
		}
	}, [props.organization, props.account.type, props.id])
	const canEdit = props.account.isAdmin
	const units = getUnits(props.account)
	const top = "9.5vh"
	const addressArray = organization.addresses.asArray
	const showAddressAddBanner = !(
		(organization.addresses.physical.isMailing && organization.addresses.physical.isSupport) ||
		(organization.addresses.mailing.isSupport || organization.addresses.support.isMailing) ||
		(organization.addresses.physical.isPhysical && organization.addresses.mailing.isMailing &&
			organization.addresses.support.isSupport)
	)
	const upload = showImageUpload && canEdit ? <ImageUpload show={setShowImageUpload} type={imageUploadType}
		refresh={refresh}/> : <></>
	const logo_ = getFromStorage("logo")
	if (!logo_.path && organization.logo.path && organization.isParalianCustomer) {
		setToStorage("logo", organization.logo.path)
	}
	const isAdmin = props.account.isAdmin
	const paymentMethods = isAdmin ?
		<Elements stripe={stripePromise}>
			<MobilePaymentMethods parentObject={organization} isOrganization={true} refresh={refresh} top={top} zIndex={17}/>
		</Elements> : <></>
	const subscriptionIsTrial = organization.subscription.status === "TRIAL"
	const noCardOnFile = organization.payment.methods.cards.length === 0
	const allServicesDeactivated = organization.services.every(v => !v.isActive)
	const noPhysicalAddress = !organization.addresses.physical.id
	return !organization.id ?
		<div style={{paddingTop: "20vh"}}>
			<div>
				<MobileFontAwesome icon={ICONS.SYNC} style={{fontSize: "20vh"}} label={translate(DICTIONARY.REFRESH.X)}
					onClick={() => refresh()}/>
			</div>
			<div style={{fontSize: "3vh"}}>
				{translate(DICTIONARY.REFRESH.X)}
			</div>
		</div> :
	<>
		{upload}
		{/* TODO: customer credits - in use/available - expiration date - renew - add */}
		<div id={"mobileOrganization"} className={"w3-display-middle w3-animate-opacity paralian-mobile-content " +
			"paralian-scroll paralian-hide-scroll-chrome paralian-hide-scroll-ms"}>
			{/* TODO: icon */}
			{/* TODO: facilities & facility units */}
			{/* Image and Logo */}
			<MobileImageAndLogo image={organization.image.path ? organization.image : {path: imagePlaceholder}}
				imagePlaceholder={imagePlaceholder} logoPlaceholder={logoPlaceholder}
				logo={organization.logo.path ? organization.logo : {path: logoPlaceholder}}
				showUpload={canEdit} onClick={type => {
					setImageUploadType(type === "profile" ? IMAGE_CATEGORY.ORGANIZATION_IMAGE :
						IMAGE_CATEGORY.ORGANIZATION_LOGO)
					setShowImageUpload(!showImageUpload)
			}}/>
			{/* Display name */}
			<div style={{fontSize: "4vh", padding: "0vh 1vh 1vh 1vh"}} translate={"no"}>{organization.name.display}</div>
			{/* Legal name, business type, business structure */}
			<div className={"paralian-theme-level-1 sticky"} style={{zIndex: "20", padding: "1vh 0vw"}}>
				<i style={{margin: "1vh 2vw"}} translate={"no"}>{organization.name.legal}</i>
				<div style={{fontSize: "2vh", opacity: "0.5"}}><i>{translate(organization.type)}</i></div>
				<div style={{fontSize: "2vh", opacity: "0.5", minHeight: "2.3vh"}}>
					<i>{translate(organization.structure) || " "}</i>
				</div>
			</div>
			{/* Paralian Subscriptions - Warning if < 5% available */}
			{/* Rating */}
			{/* TODO: Job Acceptance Rate & Response Time (w/ percentile) */}
			<MobileBusinessRating rating={organization.metrics.rating}/>
			{/* Icons */}
			<div style={{fontSize: "3vh", display: "inline-flex"}}>
				<MobileFontAwesome icon={!organization.stripeStatus ? ICONS.STRIPE_BEAT : ICONS.STRIPE}
					style={{padding: "0vh 1vh", color: !organization.stripeStatus ? "red" : ""}} onClick={async () => {
						if (organization.stripeStatus === null) {
							const confirmed = window.confirm(translate(DICTIONARY.STRIPE_SETUP_LINK_MESSAGE.X))
							if (confirmed) {
								const response = await stripeOnboardConnectAccount()
								// console.log("response", response)
								if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
									if (response.payload.url) {
										window.location.assign(response.payload.url)
									}
								}
							}
						} else {
							const confirmed = window.confirm(translate(DICTIONARY.STRIPE_LINK_MESSAGE.X))
							if (confirmed) {
								window.open(organization.stripeLink)
							}
						}
					}
				}/>
				{noCardOnFile ? <MobileFontAwesome icon={ICONS.CREDIT_CARD_SHAKE} style={{padding: "0vh 1vh",
					color: "red"}} onClick={() => {
						newMessage("no-card-on-file-message", translate(DICTIONARY.NO_CARD_ON_FILE.X), "",
							"w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE)
						document.getElementById("mobileOrganization").scrollTo(0, paymentRef.current.offsetTop - 120)
				}}/> : <></>}
				{subscriptionIsTrial ? <MobileFontAwesome icon={ICONS.BELL_ON_SHAKE} style={{padding: "0vh 1vh",
					color: "red"}} onClick={() => {
						newMessage("subscription-is-trial-message", translate(DICTIONARY.INACTIVE_SUBSCRIPTION.X),
							translate(DICTIONARY.INACTIVE_SUBSCRIPTION_MESSAGE.X), "w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE)
						document.getElementById("mobileOrganization").scrollTo(0,
							subscriptionRef.current.offsetTop - 120)
				}}/> : <></>}
				{noPhysicalAddress ? <MobileFontAwesome icon={ICONS.MAP_MARKER_ALT_BOUNCE} style={{padding: "0vh 1vh",
					color: "red"}} onClick={() => {
					newMessage("no-physical-address-message", translate(DICTIONARY.PHYSICAL_ADDRESS_MISSING.X),
						translate(DICTIONARY.PHYSICAL_ADDRESS_MISSING_MESSAGE.X), "w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE)
				}}/> : <></>}
				<MobileFontAwesome icon={ICONS.CLOCK}	style={{padding: "0vh 1vh"}} onClick={() => {
					document.getElementById("mobileOrganization").scrollTo(0, scheduleRef.current.offsetTop - 120)
				}}/>
				<MobileFontAwesome icon={allServicesDeactivated ? ICONS.SAC_DOLLAR_SHAKE : ICONS.SAC_DOLLAR}
					style={{padding: "0vh 1vh", color: allServicesDeactivated ? "red" : ""}} onClick={() => {
						newMessage("all-services-deactivated-message", translate(DICTIONARY.ALL_SERVICES_DEACTIVATED.X),
							translate(DICTIONARY.ALL_SERVICES_DEACTIVATED_MESSAGE.X), "w3-pale-yellow", ICONS.EXCLAMATION_CIRCLE)
						document.getElementById("mobileOrganization").scrollTo(0, pricingRef.current.offsetTop - 120)
				}}/>
				<MobileFontAwesome icon={ICONS.SYNC}	style={{padding: "0vh 1vh"}} onClick={() => refresh().then()}/>
			</div>
			{/* Contact Info */}
			<MobileContactInfo contactInfo={organization.contactInfo} websites={organization.websites}
				isOrganization={true} refresh={refresh} canEdit={canEdit}/>
			{/* Address */}
			<MobileAddressList addresses={addressArray} top={top} zIndex={2} allowDelete={false} isOrganization={true}
				showAddBanner={showAddressAddBanner} refresh={refresh} canEdit={canEdit}/>
			{/* Location */}
			<MobileOrgLocation coordinates={organization.coordinates} canVisit={organization.serviceArea.welcomesVisitors}
				top={top} zIndex={3} refresh={refresh} canEdit={canEdit} physicalAddress={organization.addresses.physical}/>
			{/* Facilities */}
			{
				organization.serviceArea.welcomesVisitors ?
					<MobileMarinaFacilities physicalFacilities={organization.physicalFacilities} canEdit={canEdit} units={units}
						vesselStorageFacilities={organization.vesselStorageFacilities}
						refresh={refresh} zIndex={4} top={top}/> : <></>
			}
			{/* Service Area */}
			<MobileServiceArea area={organization.serviceArea} top={top} zIndex={5} refresh={refresh} canEdit={canEdit}/>
			{/* Currency & Timezone */}
			<MobileCurrencyAndTimezone currency={organization.currency} timezone={organization.timezone}
				country={organization.addresses.physical.country} top={top} zIndex={6} canEdit={canEdit} refresh={refresh}/>
			{/* Marina Service Access Status */}
				{/* TODO: application/acceptance fee, accept fee?, take cut */}
				{/* TODO: adjust for case where organization is Marina */}
				{/* TODO: forbidden services */}
				{/* TODO: include on-premise (at-home) services */}
			{props.account.isMarina ? <></> :
				<MobileServiceMarinas serviceApprovals={organization.serviceApprovals.sent} services={organization.categories}
					top={top}	zIndex={7} canEdit={canEdit} refresh={refresh} documents={organization.documents} currency={organization.currency}
					country={organization.addresses.physical.country} state={organization.addresses.physical.state}/>}
			{/* Required Documents */}
				{props.account.isMarina ? <MobileRequiredDocumentsSection documentRequirements={organization.documentRequirements}
					canEdit={canEdit} refresh={refresh} top={top}	zIndex={7}/> : <></>}
			{/* Schedule */}
			<div ref={scheduleRef}/>
			<MobileSchedule schedule={organization.schedule} holidays={organization.holidays} format={"amPM"}
				top={top} zIndex={8} canEdit={canEdit} refresh={refresh}/>
			{/* Services & Pricing */}
			<div ref={pricingRef}/>
			<MobileServices services={organization.services} top={top} zIndex={9} canEdit={canEdit}
				country={organization.addresses.physical.country}	currency={organization.currency} refresh={refresh}/>
			{/* TODO: Custom Services & Custom Service Pricing -- be careful about fraud -- */}
			{/* Colors */}
			<MobileColors colors={organization.colors} top={top} zIndex={10} canEdit={canEdit} refresh={refresh}/>
			{/* Organization Accounts */}
				{/* TODO: resend code button for accounts with pending status */}
				{/* Admin Accounts */}
				<MobileAdmins admins={organization.admins} employees={organization.employees} account={props.account}
					services={organization.services}
					onAdd={() => {}} top={top} zIndex={11} canEdit={canEdit} refresh={refresh} refreshAccount={refreshAccount}/>
				{/* Employees - services & access */}
				<MobileEmployees admins={organization.admins} employees={organization.employees} account={props.account}
					services={organization.services} canEdit={canEdit} onAdd={() =>{}} zIndex={12} top={top} refresh={refresh}
					refreshAccount={refreshAccount}/>
			{/* Documents */}
			<MobileOrganizationDocuments documents={organization.documents} top={top} zIndex={13} refresh={refresh}
	 			canEdit={canEdit}/>
			{/* Payment Methods */}
			<div ref={paymentRef}/>
			{paymentMethods}
			{/* Paralian Products */}
			<div ref={subscriptionRef}/>
			{canEdit ? <MobileParalianSubscriptions canEdit={canEdit} isOrganization={true} parentObject={organization}
				refresh={refresh} top={top} zIndex={18} canDelete={true}/> : <></>}
			{/* TODO: access groups */}
			{/* TODO: Privacy - specify which companies have access to which types of documents? */}
			{/*<MobilePrivacy access={getFromStorage("accounts")[0].access} zIndex={14}/>*/}
			{/* TODO: Required Documents - in general for all business & specifically for each provided service */}
			{/* Organization Account Control */}
			<MobileOrganizationControl organization={organization} refresh={refresh} top={top} zIndex={19}/>
		</div>
	</>
}

export default MobileOrganization