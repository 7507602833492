import React from "react"
import ICONS from "../../../../globals/constants/client/icons"
import {DICTIONARY, TEXT_FIELD_PROPERTIES} from "../../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../MobileInputGeneric"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.style} props.style
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.form
 * @param {boolean} props.showLabel
 * @param {boolean} props.autoFocus
 * @param {Object} props.onChange - function
 * @param {boolean} props.autoComplete - if true, will be set to "username"
 * @param {string} props.defaultValue
 * @param {boolean} props.required
 * @param {boolean} props.disabled
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputUsername = props => {
	const autoComplete = props.autoComplete ? "username" : null
	return <MobileInputGeneric id={"username"} type={"username"} form={props.form} label={translate(DICTIONARY.USERNAME.X)}
		showLabel={props.showLabel} autoFocus={props.autoFocus} autoComplete={autoComplete} name={"username"}
		style={props.style} className={props.className}	onChange={props.onChange} left={{icon: ICONS.USER}}
		required={props.required} defaultValue={props.defaultValue} disabled={props.disabled}
		right={{icon: ICONS.INFO, label: translate(DICTIONARY.INFORMATION.X), onClick: () => newMessage("login",
			translate(DICTIONARY.USERNAME.X), translate(TEXT_FIELD_PROPERTIES.username.hoverMessage), "w3-pale-blue",
			ICONS.INFO)}}/>
}

export default MobileInputUsername