import React, {useState} from "react"
import {DICTIONARY} from "../../../globals/constants/client/constants"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileHolidayDropdown from "../../shared/input/dropdown/MobileHolidayDropdown"
import MobileInputNewHoliday from "./MobileInputNewHoliday"
import MobileInputHours from "./MobileInputHours"
import Time from "../../../globals/classes/shared/Time"
import Day from "../../../globals/classes/shared/Day"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.form
 * @param {React.HTMLAttributes.className} props.className
 * @param {Day|null} props.day
 * @param {Object} props.left
 * @param {Holiday} props.holiday
 * @param {Country} props.country
 * @param {string[]} props.left.icon
 * @param {function} props.left.onClick
 * @param {boolean} props.defaultOther
 * @returns {JSX.Element}
 * @constructor
 */
const MobileHolidayHoursOfOperation = props => {
	const [state, setState] = useState("closed")
	const [other, setOther] = useState(false)
	const className = props.className || "paralian-theme-level-1-flipped"
	const day_ = new Day(props.day)
	let subtitle, next, color = "paralian-theme-level-4-flipped", open, close
	switch (state) {
		case "closed":
			subtitle = translate(DICTIONARY.CLOSED.X)
			color = "paralian-theme-level-2"
			next = "open"
			open = new Time({hour: 0, minute: 0, xm: null})
			close = new Time({hour: 0, minute: 0, xm: null})
			break
		case "open 24 hours":
			subtitle = translate(DICTIONARY.OPEN_24_HR.X)
			next = "closed"
			color = "paralian-theme-level-1-flipped"
			open = new Time({hour: 0, minute: 0, xm: null})
			close = new Time({hour: 23, minute: 59, xm: null})
			break
		default:
			subtitle = translate(DICTIONARY.OPEN_ALT.X)
			next = "open 24 hours"
			open = typeof day_.open.hour === "number" ? day_.open : new Time({hour: 8, minute: 0, xm: null})
			close = typeof day_.close.hour === "number" ? day_.close : new Time({hour: 17, minute: 0, xm: null})
	}
	const _other = other ? <MobileInputNewHoliday id={`${props.id}-new`}/> : <></>
	return <div style={{textAlign: "center", margin: "2vh 0vh"}}>
		<input id={`${props.id}-state`} style={{display: "none"}} type={"text"} name={"state"} data-state={state}/>
		<MobileHolidayDropdown id={`${props.id}-holiday`} left={props.left} className={color} showLabel={false}
			defaultOther={props.defaultOther} form={props.form} setOther={setOther} style={{fontWeight: "bold"}}
			holiday={props.holiday} country={props.country}/>
		<div style={{fontSize: "4vh", padding: "1vh 0vh"}} className={`${color} w3-ripple`}
			onClick={() => {setState(next)}}>
			<div style={{fontSize: "2vh"}}>{subtitle}</div>
		</div>
		{state === "open" ? <MobileInputHours id={props.id} form={props.form} use24Hr={props.use24Hr}
			open={open} close={close} className={className} /> : <></>}
		{_other}
	</div>
}

export default MobileHolidayHoursOfOperation