import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../../globals/constants/shared/enumerators"
import {newMessage, translate} from "../../../../globals/functions/client/localFunctions"
import {addVessel, getVesselBrands, setVesselHull} from "../../../../globals/functions/client/serverFunctions"
import MobileInputForm from "../../../shared/input/forms/MobileInputForm"
import MobileVesselBrandDropdown from "./MobileVesselBrandDropdown"
import MobileVesselModelDropdown from "./MobileVesselModelDropdown"
import MobileInputYear from "../../../shared/input/text/MobileInputYear"

/**
 *
 * @param {Object} props
 * @param {string} props.brand
 * @param {string} props.model
 * @param {string} props.year
 * @param {Object} [props.owner]
 * @param {Email} props.owner.email
 * @param {Phone} props.owner.phone
 * @param {string} props.owner.name
 * @param {boolean} props.owner.isOrganization
 * @param {number} props.owner.action
 * @param {function} props.onSuccess
 * @param {boolean} props.isInvite
 * @return {JSX.Element}
 * @constructor
 */
const MobileBrandModelYearHullEdit = props => {
	const [brand, setBrand] = useState({label: props.brand, value: props.brand, code: props.brand})
	const [brands, setBrands] = useState([])
	const [disabled, setDisabled] = useState(true)
	useEffect(() => {
		getVesselBrands().then(response => {
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				const brandsList = response.payload.map(brand_ => {
					return {label: brand_.brand, value: brand_.brand, code: brand_.brand}
				})
				const filteredBrandsList = brandsList.filter(item => item.value !== "na")
				filteredBrandsList.push({label: "*" + translate(DICTIONARY.OTHER.X) + "*", value: "na", code: "na"})
				setBrands(filteredBrandsList)
				setBrand(filteredBrandsList[0])
			}
		})
	},[])
	const onSubmit = async event => {
		try {
			event.preventDefault()
			if (props.add && !props.isInvite) {
				const checkbox = document.getElementById("vessel-add-checkbox")
				if (checkbox.getAttribute("data-a") !== "accepted") {
					newMessage("not-legal-owner", translate(DICTIONARY.MY_FLEET.X),
						translate(DICTIONARY.ADD_VESSEL_LEGAL_OWNER_MESSAGE.X), "w3-pale-red", ICONS.OCTAGON)
					return
				}
			}
			const _brand = document.getElementById("vessel-brand-input").value === "na" ?
				document.getElementById("vessel-brand-text-input").getAttribute("data-a") :
				document.getElementById("vessel-brand-input").value
			const model = document.getElementById("vessel-brand-input").value === "na" ?
				document.getElementById("vessel-model-text-input").getAttribute("data-a") :
				document.getElementById("vessel-model-input").value === "na" ?
					document.getElementById("vessel-model-text-input").getAttribute("data-a") :
					document.getElementById("vessel-model-input").value
			const year = document.getElementById("vessel-year-input").value
			const name = props.add ? document.getElementById("vessel-name-input").value : ""
			console.log({name: name, brand: _brand, model: model, year: year})
			const response = props.add ?
				await addVessel(name, _brand, model, year, props.isOrganization, props.isInvite, props.owner) :
				await setVesselHull(props.id, _brand, model, year)
			if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
				props.add ? props.onSuccess({id: response.payload, name: name, brand: _brand, model: model, year: year}) :
					props.onSuccess({brand: _brand, model: model, year: year})
			}
		} catch (e) {
			console.log(e)
		}
	}
	const agreement = props.add && !props.isInvite ? <div style={{display: "flex", paddingTop: "2vh"}}>
		<div>
			<input id={"vessel-add-checkbox"} style={{height: "2.5vh", width: "2.5vh", margin: "0vh 1vh"}} type={"checkbox"}
				 name={"vessel-add-checkbox"} data-a={"denied"} onChange={() => {
				const checkbox = document.getElementById("vessel-add-checkbox")
				if (checkbox.getAttribute("data-a") === "denied") {
					checkbox.setAttribute("data-a", "accepted")
					document.getElementById("hull-bmy-form-submit").disabled = false
					setDisabled(false)
				} else {
					checkbox.setAttribute("data-a", "denied")
					document.getElementById("hull-bmy-form-submit").disabled = true
					setDisabled(true)
				}
			}}/>
		</div>
		<div style={{width: "100%", textAlign: "left"}}>
			<label htmlFor={"vessel-add-checkbox"} style={{textAlign: "left", margin: "auto"}}>
				{translate(DICTIONARY.VESSEL_LEGAL_OWNER_MESSAGE.X)}
			</label>
		</div>
	</div> : <></>
	const content = <div className={"w3-animate-opacity w3-center"}>
		<MobileVesselBrandDropdown required={true} label={translate(DICTIONARY.BRAND.X)} list={brands}
			placeholder={translate(DICTIONARY.BRAND.X)} name={"brand"} className={"paralian-theme-level-1"}
			id={`vessel-brand`} showLabel={true} autoFocus={false} topRadius={"0vh"} bottomRadius={"0vh"} setBrand={setBrand}
			form={`hull-bmy-form`} defaultValue={brand.value} autoComplete={"off"} style={{margin: "1vh 0vh", fontSize: "3vh"}}/>
		<MobileVesselModelDropdown required={true} label={translate(DICTIONARY.MODEL.X)} form={`hull-bmy-form`}
			placeholder={translate(DICTIONARY.MODEL.X)} name={"model"} className={"paralian-theme-level-1"}
			id={`vessel-model`} showLabel={true} autoFocus={false} topRadius={"0vh"} bottomRadius={"0vh"}
		 	brand={brand.value}
			defaultValue={props.model} autoComplete={"off"} style={{margin: "1vh 0vh", fontSize: "3vh"}}/>
		<MobileInputYear id={`vessel-year`} name={"year"} showLabel={true} bottomRadius={"0vh"} topRadius={"0vh"}
			form={`hull-bmy-form`} autoComplete={"off"} required={true} defaultValue={props.year}
			placeholder={translate(DICTIONARY.MODEL_YEAR.X)} autoFocus={false} style={{margin: "1vh 0vh", fontSize: "3vh"}}
			label={translate(DICTIONARY.MODEL_YEAR.X)} className={"paralian-theme-level-1"}/>
		{agreement}
	</div>
	return <MobileInputForm id={`hull-bmy`} content={content} className={" "} onSubmit={onSubmit} disabled={disabled}/>
}

export default MobileBrandModelYearHullEdit