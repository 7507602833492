import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE} from "../../../globals/constants/shared/enumerators"
import {getFromStorage, translate} from "../../../globals/functions/client/localFunctions"
import {updateOrgLocation, updateOrgWelcomesVisitors} from "../../../globals/functions/client/serverFunctions"
import MobileStickyBannerExpandDown from "../MobileStickyBannerExpandDown"
import MobileStaticMapPin from "./MobileStaticMapPin"
import MobileLocationPinSet from "./MobileLocationPinSet"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import MobileInfoCardHeader from "../MobileInfoCardHeader"
import Coordinates from "../../../globals/classes/shared/Coordinates"

/**
 *
 * @param {Object} props
 * @param {Coordinates} props.coordinates
 * @param {Address} props.physicalAddress
 * @param {string} props.top - top margin for sticky property of banner
 * @param {string} props.zIndex
 * @param {boolean} props.canEdit
 * @param {boolean} props.canVisit
 * @param {function} props.refresh
 * @returns {JSX.Element}
 * @constructor
 */
const MobileOrgLocation = props => {
	/* TODO: full interactive map for admins/employees on organizations with kml */
	const [expanded, setExpanded] = useState(false)
	const [coordinates, setCoordinates] = useState(props.coordinates)
	const [canVisit, setCanVisit] = useState(props.canVisit)
	const [edit, setEdit] = useState(false)
	useEffect(() => {setCoordinates(props.coordinates)}, [props.coordinates])
	useEffect(() => {setCanVisit(props.canVisit)}, [props.canVisit])
	const initialCoordinates = new Coordinates(props.coordinates)
	const onSubmit = async event => {
		event.preventDefault()
		try {
			let ok = true
			const xy = new Coordinates(getFromStorage("coordinates", "session"))
			const coordinates_ = new Coordinates(getFromStorage("coordinates_", "session"))
			// console.log(xy, coordinates_)
			if (xy.latitude && xy.longitude) {
				coordinates_.latitude = xy.latitude
				coordinates_.longitude = xy.longitude
			}
			const coordinatesAvailable = coordinates_.latitude && coordinates_.longitude
			const coordinatesChanged = coordinates_.latitude !== initialCoordinates.latitude || coordinates_.longitude !== initialCoordinates.longitude
			// console.log(xy, coordinates_, coordinatesAvailable, coordinatesChanged)
			if (coordinatesAvailable && coordinatesChanged) {
				const locationResponse = await updateOrgLocation(coordinates_)
				// console.log(locationResponse)
				if (locationResponse.status !== SERVER_RESPONSE_STATE.SUCCESS) {
					ok = false
				}
			}
			const canVisit_ = document.getElementById("org-location-canVisit").getAttribute("data-is-checked") === "true"
			const canVisitChanged = canVisit_ !== props.canVisit
			// console.log(canVisit_, canVisitChanged)
			if (canVisitChanged) {
				const response = await updateOrgWelcomesVisitors(canVisit_)
				if (response.status !== SERVER_RESPONSE_STATE.SUCCESS) {
					ok = false
				}
			}
			if (ok && (canVisitChanged || coordinatesChanged)) {
				window.sessionStorage.removeItem("coordinates")
				props.refresh().then()
				setCoordinates(new Coordinates(xy))
				setEdit(false)
				setCanVisit(canVisit_)
			}
		} catch (e) {
			console.log(e)
		}
	}
	const label = translate(DICTIONARY.LOCATION.X)
	const visit = <div style={{margin: "1vh", textAlign: "center", fontSize: "2vh"}} className={"w3-animate-opacity"}>
		<div>
			<MobileFontAwesome icon={canVisit !== null ? canVisit ? ICONS.CHECK : ICONS.BAN : ICONS.EXCLAMATION_TRIANGLE}
				style={{paddingRight: "1.5vh"}}/>
			{translate(canVisit ? DICTIONARY.CUSTOMER_VISITS_ALLOWED.X : DICTIONARY.NO_VISITORS_ALLOWED.X)}
		</div>
		{coordinates.latitude && coordinates.longitude ? <></> :
			<div style={{paddingTop: "1vh", fontSize: "1.5vh"}}>
				<i>{`- ${translate(DICTIONARY.LOCATION_DATA_UNAVAILABLE.X)} -`}</i>
			</div>}
	</div>
	const header = !expanded ? <></> : <div id={"service-area"} className={"w3-animate-opacity paralian-mobile-info-card"}
		style={{textAlign: "left", padding: "2vh"}}>
		<MobileInfoCardHeader setEdit={setEdit} edit={edit} canEdit={props.canEdit}
			labels={[translate(DICTIONARY.LOCATION.X)]}/>
		{edit && props.canEdit ? <div className={"w3-display-container"} style={{height: "3vh", margin: "1vh 0vw"}}>
			<MobileInputSlider id={"org-location-canVisit"} label={translate(DICTIONARY.ALLOW_CUSTOMER_VISITS.X)} value={true}
				padding={"0.25vh 0vh 0.25vh 2vh"}	height={"3vh"} width={"8vh"} className={"w3-display-middle"}
				defaultChecked={canVisit} onChange={() => {}}/>
		</div> : visit}
	</div>

	const content = !expanded ? <></> : edit && props.canEdit ? <div style={{padding: "0vh 2vh 1vh 2vh", maxWidth: "100vw"}}>
			<MobileLocationPinSet coordinates={coordinates} address={props.physicalAddress}/>
			<MobileInputForm id={`location-form`} content={<></>} className={" "} onSubmit={onSubmit}/></div> :
		coordinates.latitude && coordinates.longitude ? <MobileStaticMapPin coordinates={coordinates}/> : <></>
	return <>
		<MobileStickyBannerExpandDown expanded={expanded} zIndex={props.zIndex} top={props.top} text={label} onClick={() => setExpanded(!expanded)}/>
		{header}
		{content}
	</>
}

export default MobileOrgLocation