import React from "react"
import {getFromStorage} from "../../../../globals/functions/client/localFunctions"

const Message = props => {
	const session = getFromStorage("order", "session")
	const defaultValue = session.message ? session.message : null
	return <fieldset style={{margin: "1vh 0vh"}}>
		<legend style={{padding: "0vh 1vh", fontSize: "2.5vh"}} >{props.legend}</legend>
		<div style={{padding: "1vh"}}>
      <textarea className={"paralian-theme-level-2"} id={`${props.id}-message`} rows={8} defaultValue={defaultValue}
				style={{width: "100%", padding: "1vh", resize: "vertical"}}	name={"request-description"} maxLength={255}
				placeholder={props.placeholder} required={true} onChange={props.onChange}>
      </textarea>
		</div>
	</fieldset>
}

export default Message