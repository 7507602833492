import React from "react"
import MobileImage from "../../main/account/MobileImage"
import Image from "../../../globals/classes/shared/Image"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {JSX.Element} [props.topLeft]
 * @param {string} [props.title]
 * @param {JSX.Element} [props.topRight]
 * @param {JSX.Element} [props.middle]
 * @param {Image} [props.image]
 * @param {string} [props.imagePlaceholder] - file
 * @param {string[]} [props.text]
 * @param {JSX.Element} [props.bottom]
 * @param {boolean} props.isSelected
 * @param {function} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
const ImageAndTextCard = props => {
	return <div className={props.isSelected ? "paralian-theme-level-2-flipped" : "paralian-theme-level-2"}
		style={{padding: "2vh", width: "100%"}} onClick={props.onClick}>
		{props.title || props.topLeft || props.topRight ?
			<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
				textAlign: "center", marginBottom: "1vh"}}>
			<div style={{minWidth: "10%"}}>
				{props.topLeft ? props.topLeft : <></>}
			</div>
			<div style={{width: "100%"}}>
				<i>{props.title ? props.title : <></>}</i>
			</div>
			<div style={{minWidth: "10%"}}>
				{props.topRight ? props.topRight : <></>}
			</div>
		</div> : <></>
		}
		{props.middle ? props.middle : <></>}
		<div style={{display: "inline-flex", width: "100%", maxWidth: "36vh"}}>
			{
				props.image ?
					<MobileImage image={new Image(props.image)} size={"10vh"} alt={"profile"} style={{margin: "auto 1vh"}}
						placeholder={props.imagePlaceholder}/> :
					<></>
			}
			{
				props.text ?
					<div style={{width: "100%", textAlign: "right", margin: "auto"}}>
						{props.text.map((v, i) => <div key={`${props.id}-${i}`} style={{fontSize: "2vh"}}>{v}</div>)}
					</div> :
					<></>
			}
		</div>
		{props.bottom ? props.bottom : <></>}
	</div>
}

export default ImageAndTextCard