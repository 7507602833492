import React, {useEffect, useState} from "react"
import {useElements, useStripe} from "@stripe/react-stripe-js"
import MobileInputCreditCard from "./MobileInputCreditCard"
import MobileInputForm from "../../shared/input/forms/MobileInputForm"
import submitNewPaymentMethod from "./submitNewPaymentMethod"
import Address from "../../../globals/classes/shared/Address"
/**
 *
 * @param {Object} props
 * @param {Account|Organization} props.parentObject
 * @param {PaymentMethods} props.parentObject.payment
 * @param {Name} props.parentObject.name
 * @param {function} props.onUndo
 * @param {function} props.onSuccess
 * @param {boolean} props.isOrganization
 * @return {JSX.Element}
 * @constructor
 */
const StripePaymentMethodAdd = props => {
	// console.log(props.account)
	const id = "new-payment-method"
	const stripe = useStripe()
	const elements = useElements()
	const [error, setError] = useState(null)
	const [cardComplete, setCardComplete] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [billingDetails, setBillingDetails] = useState({name: props.parentObject ? props.isOrganization ?
			props.parentObject.name.display :	props.parentObject.contact.name.given : null})
	const [billingAddress, setBillingAddress] = useState(props.parentObject ?
		props.parentObject.addresses.asArray.length > 0 ?
			props.parentObject.addresses.asArray.find(address => address.isBilling) ||  new Address({id: -1}) :
			new Address({id: -1}) : new Address({id: -1}))
	useEffect(() => {
		try {
			const ok = props.isOrganization ? props.parentObject.name.display : props.parentObject.contact.name.given
			setBillingDetails({name: ok ? ok : null})
		} catch (e) {
			setBillingDetails({name: null})
		}
	}, [props.parentObject, props.isOrganization])
	useEffect(() => {
		try {
			const ok = props.parentObject.addresses.asArray.length > 0
			setBillingAddress(ok ? props.parentObject.addresses.asArray.find(address => address.isBilling) ||
				new Address({id: -1}) : new Address({id: -1}))
		} catch (e) {
			setBillingAddress(new Address({id: -1}))
		}
	}, [props.parentObject])
	// console.log(billingDetails)
	const onSubmit = async event => {
		event.preventDefault()
		if (!error && cardComplete) {
			await submitNewPaymentMethod(stripe, elements, setProcessing, billingAddress, billingDetails, props.onSuccess,
				props.isOrganization)
		}
	}
	const content = <MobileInputCreditCard id={id} setCardComplete={setCardComplete} isOrganization={props.isOrganization}
			setError={setError} parentObject={props.parentObject}	setBillingDetails={setBillingDetails}
			setBillingAddress={setBillingAddress}	className={"paralian-theme-level-1"}/>
	return <MobileInputForm id={id} content={content}	disabled={processing} className={"paralian-theme-level-2"}
		style={{padding: "2vh 0vh", margin: "2vh"}} onSubmit={onSubmit}/>
}

export default StripePaymentMethodAdd