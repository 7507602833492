import React from "react"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {translate} from "../../../../../globals/functions/client/localFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.refund
 * @param {Object} props.order.refunds
 * @param {number} props.order.refunds.available
 * @param {function} props.setShowRefundModal
 * @return {JSX.Element}
 * @constructor
 */
const RefundOrderButton = props => {
	return props.order.buttons.refund && props.order.refunds.available > 0 ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"refund"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.REFUND.X)}} padding={"1vh"} left={{icon: ICONS.HAND_HOLDING_USD}}
				className={"paralian-theme-level-1"} style={{width: "100%"}} onClick={() => props.setShowRefundModal(true)}/>
		</div> : <></>
}

export default RefundOrderButton