import React, {useEffect} from "react"
/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {function} props.onClick
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {boolean} props.selected
 * @param {boolean} props.defaultChecked
 * @param {boolean} props.disabled
 * @param {function} props.onClickDisabled
 * @return {JSX.Element}
 * @constructor
 */
const RadioButton = props => {
	const onClick = id => {
		if (!props.disabled) {
			document.getElementById(id).click()
			props.onClick(id)
		} else {
			if (props.onClickDisabled) {props.onClickDisabled(id)}
		}
	}
	useEffect(() => {if (props.selected && !props.disabled) {document.getElementById(props.id).click()}},
		[props.selected, props.id])
	return <div style={{margin: "0vh 0vh", display: "inline-flex", width: "100%", padding: "1vh 2vh"}}
			 className={props.selected ? "paralian-theme-level-2" : ""} onClick={() => onClick(props.id)}>
		<input style={{margin: "auto 0vh"}} name={props.name} type={"radio"} id={props.id} value={props.id}
			disabled={props.disabled}/>
		<label htmlFor={props.id} style={{paddingLeft: "2vh", textAlign: "left"}}>
			<div style={{fontSize: "2.5vh", opacity: props.disabled ? "0.5" : null}}>{props.title}</div>
			{props.subtitle ? <div style={{opacity: 0.75}}><i>{props.subtitle}</i></div> : <></>}
		</label>
	</div>
}

export default RadioButton