import React, {useEffect, useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {newMessage, translate} from "../../../globals/functions/client/localFunctions"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileInputSlider from "../../shared/input/switch/MobileInputSlider"

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {Servicio} props.service
 * @param {number} props.service.id
 * @param {string} props.service.name
 * @returns {JSX.Element}
 * @constructor
 */
const MobileServiceAccess = props => {
	const [level, setLevel] = useState(["no-access"])
	const svc = props.employee ? props.employee.services.find(service => service.itemCategory === props.service.id) : null
	const access = svc ? svc.access.split(",") : []
	useEffect(() => {
		const svc = props.employee ? props.employee.services.find(service => service.itemCategory === props.service.id) : null
		const access_ = svc ? svc.access.split(",") : []
		const level_ = []
		if (access_.length === 0) {level_.push("no-access")}
		if (access_.includes("ASSIGN")) {level_.push("assign")}
		if (access_.includes("ACCEPT")) {level_.push("accept-reject")}
		setLevel(level_)
	}, [props.employee, props.service.id])
	// console.log("level", "access", level, access)
	const updateLevel = _level => {
		// console.log("pre", level)
		const arr = [...level]
		if (level.includes("no-access") && !level.includes(_level)) {
			const indexNoAccess = level.findIndex(item => item === "no_access")
			arr.splice(indexNoAccess, 1)
			arr.push(_level)
			if (level.length === 1) {document.getElementById(`no-access-${props.id}`).click()}
		} else if (!level.includes("no-access") && level.includes(_level)) {
			const index = level.findIndex(item => item === _level)
			arr.splice(index, 1)
			if (level.length === 1) {
				document.getElementById(`no-access-${props.id}`).click()
				arr.push("no-access")
			}
		} else {
			arr.push(_level)
		}
		document.getElementById(`service-access-${props.id}`).setAttribute("data-access",`${arr}`)
		// console.log("post", arr)
		setLevel(arr)
	}
	const translatedLabels = props.service.labelsAsString
	return <div className={props.className || "paralian-theme-level-1-flipped"} style={{padding: "1vh", margin: "1vh 0vh"}}>
		<div id={`service-access-${props.id}`} className={props.className === "paralian-theme-level-1" ?
			"paralian-theme-level-1-flipped" : "paralian-theme-level-4"} data-id={props.service.id}
			style={{margin: "1vh", padding: "1vh", textAlign: "center"}} data-access={"no-access"}>{translatedLabels}</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => newMessage(`assign-message`, translate(DICTIONARY.ASSIGN_ORDER.X),
					translate(DICTIONARY.EMPLOYEE_ASSIGN_ORDERS_MESSAGE.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				<MobileInputSlider id={`assign-${props.id}`} label={translate(DICTIONARY.ASSIGN_ORDER.X)}
					padding={"0.25vh 0vh 0.25vh 2vh"}	height={"3vh"} width={"8vh"} defaultChecked={access.includes("ASSIGN")}
					className={"w3-display-left"} form={"form"} onChange={() => updateLevel("assign")}/>
			</div>
		</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => newMessage(`accept-reject-message`, translate(DICTIONARY.ACCEPT_REJECT.X),
				translate(DICTIONARY.EMPLOYEE_ACCEPT_REJECT_MESSAGE.X),	"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				<MobileInputSlider id={`accept-reject-${props.id}`} label={translate(DICTIONARY.ACCEPT_REJECT.X)} form={"form"}
					height={"3vh"} width={"8vh"} defaultChecked={access.includes("ACCEPT")} className={"w3-display-left"}
				 	padding={"0.25vh 0vh 0.25vh 2vh"} onChange={() => updateLevel("accept-reject")}/>
			</div>
		</div>
		<div style={{display: "flex", margin: "0.5vh"}}>
			<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{fontSize: "3vh", marginRight: "1vh"}}
				onClick={() => newMessage(`no-access-message`,
				translate(DICTIONARY.NO_ACCESS.X), translate(DICTIONARY.EMPLOYEE_NO_ACCESS_MESSAGE.X),
				"w3-pale-blue", ICONS.INFO_CIRCLE)}/>
			<div style={{width: "100%"}} className={"w3-display-container"}>
				<div className={"w3-display-left"} style={{height: "100%", width: "100%", zIndex: "10"}}>{""}</div>
				<MobileInputSlider id={`no-access-${props.id}`} label={translate(DICTIONARY.NO_ACCESS.X)} padding={"0.25vh 0vh 0.25vh 2vh"}
					height={"3vh"} width={"8vh"} defaultChecked={access.length === 0} className={"w3-display-left"} form={"form"}/>
			</div>
		</div>
	</div>
}

export default MobileServiceAccess