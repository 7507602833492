import React, {useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.incomplete
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const IncompleteOrderButton = props => {
	const [disableButtons, setDisableButtons] = useState(false)
	return props.order.buttons.incomplete ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"block"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.MARK_INCOMPLETE.X)}} left={{icon: ICONS.MINUS_CIRCLE}} disabled={disableButtons}
				className={"paralian-theme-level-1"} padding={"1vh"} style={{width: "100%"}} onClick={async () => {
				try {
					const confirmed = window.confirm(translate(DICTIONARY.INCOMPLETE_ORDER_CONFIRM.X))
					if (confirmed) {
						setDisableButtons(true)
						const response = await editOrder(props.order.id, ORDER_BUTTON.INCOMPLETE)
						// console.log(response)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.refreshOrders()
							fadingMessage("incomplete-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
						}
						setDisableButtons(false)
					}
				} catch (e) {
					console.log(e)
					setDisableButtons(false)
				}
				// console.log("incomplete")
			}}/>
		</div> : <></>
}

export default IncompleteOrderButton