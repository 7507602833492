import React, {useState} from "react"
import {DOCUMENT_TYPE} from "../../../globals/constants/shared/enumerators"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../globals/constants/client/constants"
import {dateToYYYYMMDD, getFromStorage, translate} from "../../../globals/functions/client/localFunctions"
// import {valOrNA} from "../../../globals/functions/localFunctions"
import MobileInputText from "../../shared/input/text/MobileInputText"
import MobileInputDate from "../../shared/input/text/MobileInputDate"
import MobileInputCountryDropdown from "../address/MobileInputCountryDropdown"
import MobileInputStateDropdown from "../address/MobileInputStateDropdown"
import MobileInputCurrencyDropdownAndSymbol from "../currency/MobileInputCurrencyDropdownAndSymbol"
import Currency from "../../../globals/classes/shared/Currency"
// import Birthdate from "../../../shared/es6/classes/Birthdate"
// import MobileHeaderAndContent from "../MobileHeaderAndContent"
import MobileUpkeepCategoryDropdown from "../../shared/input/dropdown/MobileUpkeepCategoryDropdown"
import MobileVesselSystemDropdown from "../../shared/input/dropdown/MobileVesselSystemDropdown"
import Country from "../../../globals/classes/shared/Country"
import MobileInputDecimal from "../../shared/input/text/MobileInputDecimal"

/**
 *
 * @param {Object} props
 * @param {React.HTMLAttributes.className} props.className
 * @param {string} props.container
 * @param {string} props.docType
 * @param {Country} [props.country]
 * @param {Currency} [props.currency]
 * @return {JSX.Element}
 * @constructor
 */
const MobileInputDocumentFields = props => {
	// console.log("MobileInputDocumentFields props", props)
	const [country, setCountry] = useState(new Country(props.country ? props.country : props.docData ?
		props.docData.country : null))
	const className = props.className || "paralian-theme-level-1-flipped"
	const form = `new-${props.container}-form`
	const _form = getFromStorage("form", "session")
	let tmpCountry = "USA"
	try {
		tmpCountry = _form.organization.address.physical.country.code
	} catch (e) {
		console.log(e)
	}
	const getAmountField = (name, label, defaultValue = null) => {
		const value_ = defaultValue > 0 ? defaultValue.toLocaleString() : null
		return <MobileInputDecimal className={props.className} label={label} defaultValue={value_} required={false}
			units={null} form={form} name={name} id={`${props.container}-${name}`} showLabel={true}
			digits={18} decimals={0} autoFocus={false} defaultLong={value_}/>
	}
	const getTextField = (maxLength, name, label, pattern, title, defaultValue = null) => <MobileInputText
		id={`${props.container}-${name}`} name={name}	showLabel={true} bottomRadius={"0vh"}	label={label}
		className={className} style={{margin: "1vh 0vw"}}	pattern={pattern || INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN}
		title={title || INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE}	topRadius={"0vh"}	left={{}} right={{}} form={form}
		autoComplete={"off"} required={false} defaultValue={defaultValue}	placeholder={label} maxLength={maxLength}
		autoFocus={false}/>
	const getDateField = (name, label, min, max) => <MobileInputDate
		id={`${props.container}-${name}`} name={name}	showLabel={true} label={label} className={className}
		style={{margin: "1vh 0vw"}} bottomRadius={"0vh"} topRadius={"0vh"}	autoComplete={"off"} required={false}
		min={min} max={max}	autoFocus={false} left={{}} right={{}} form={form}/>
	const getCountryField = (name, label) => <MobileInputCountryDropdown id={`${props.container}`}
		form={form}	className={className} style={{margin: "1vh 0vw", fontSize: "2.5vh"}} label={label} showLabel={true}
	 	setCountry={setCountry} defaultValue={country.code || "USA"}/>
	const getFirstOrderAdminDivField = () => <MobileInputStateDropdown id={`${props.container}`}
		form={form} className={className}	style={{margin: "1vh 0vw", fontSize: "2.5vh"}} label={null} showLabel={true}
		country={country} defaultValue={props.state ? props.state.code : props.docData ? props.docData.state ? props.docData.state.code : null : null}/>
	const currency = <MobileInputCurrencyDropdownAndSymbol id={props.container}
		country={country || props.country || tmpCountry}
		defaultCurrency={props.currency ||
		(props.docData ? props.docData.currency ? new Currency(props.docData.currency) : null : null) ||
		new Currency({code: "USD", symbol: 36})}
		showLabel={true} className={"paralian-theme-level-1"}/>
	const countryOfIssue = getCountryField("country", translate(DICTIONARY.COUNTRY_OF_ISSUE.X))
	const countryOfRegistration = getCountryField("country",
		translate(DICTIONARY.COUNTRY_OF_REGISTRATION.X))
	const stateOfIssue = getFirstOrderAdminDivField("state",
		`${translate(DICTIONARY.STATE.X)} (${translate(DICTIONARY.OR_EQUIVALENT.X)})`)
	const stateOfRegistration = getFirstOrderAdminDivField("state",
		`${translate(DICTIONARY.STATE.X)} (${translate(DICTIONARY.OR_EQUIVALENT.X)})`)
	const startDate = getDateField("start", translate(DICTIONARY.START_DATE.X), null, null)
	const expirationDate = getDateField("expiration", translate(DICTIONARY.EXPIRATION_DATE.X),
		dateToYYYYMMDD(new Date()), null)
	const issuerOrganizationName = getTextField("120", "issuerOrganizationName",
		translate(DICTIONARY.ISSUING_ORGANIZATION.X), null, null, props.docData ?
			props.docData.issuerOrganizationName : null)
	const insuranceAgencyName = getTextField("120", "insuranceAgencyName",
		translate(DICTIONARY.INSURANCE_AGENCY.X), null, null, props.docData ?
			props.docData.insuranceAgencyName : null)
	const issueDate = getDateField("issued", translate(DICTIONARY.ISSUE_DATE.X), null,
		dateToYYYYMMDD(new Date()), props.docData ? props.docData.issued : null)
	const registrationAuthorityName = getTextField("120", "registrationAuthorityName",
		translate(DICTIONARY.REGISTRATION_AUTHORITY.X), null, null, props.docData ?
			props.docData.registrationAuthorityName : null)
	const registrationNumber = getTextField("45", "registration",
		translate(DICTIONARY.REGISTRATION_NUMBER.X), null, null, props.docData ?
			props.docData.number : null)

	switch (props.docType) {
		case DOCUMENT_TYPE.UPKEEP:
			return <>
				{getTextField("45", "id", translate(DICTIONARY.IDENTIFICATION_NUMBER.X), null, null,
					props.docData ? props.docData.id : null)}
				{getTextField("225", "notes", translate(DICTIONARY.NOTES.X), null, null,
					props.docData ? props.docData.notes : null)}
				{getTextField("45", "vendor", translate(DICTIONARY.VENDOR.X), null, null,
					props.docData ? props.docData.vendor : null)}
				{issueDate}
				<MobileUpkeepCategoryDropdown id={`${props.container}`} defaultValue={props.docData ? props.docData.category ?
					props.docData.category[0] : null : null} showLabel={true} className={"paralian-theme-level-1"}/>
				<MobileVesselSystemDropdown id={`${props.container}`} defaultValue={props.docData ? props.docData.system ?
					props.docData.system[0] : null : null} showLabel={true} className={"paralian-theme-level-1"}/>
				</>
		case DOCUMENT_TYPE.CERTIFICATES:
			return <>
				{countryOfIssue}
				{stateOfIssue}
				{getTextField("120", "certifyingEntity", translate(DICTIONARY.CERTIFYING_ENTITY.X), null,
					null, props.docData ? props.docData.certifyingEntityName : null)}
				{getTextField("45", "type", translate(DICTIONARY.CERTIFICATE_TYPE.X), null, null,
					props.docData ? props.docData.variety : null)}
				{getTextField("45", "number", translate(DICTIONARY.CERTIFICATE_NUMBER.X), null, null,
					props.docData ? props.docData.number : null)}
				{getDateField("awarded", translate(DICTIONARY.CERTIFICATION_DATE.X), null,
					dateToYYYYMMDD(new Date()))}
				{expirationDate}
			</>
		case DOCUMENT_TYPE.BONDS:
			return <>
				{issuerOrganizationName}
				{insuranceAgencyName}
				{getTextField("120", "purpose", translate(DICTIONARY.PURPOSE.X), null, null,
					props.docData ? props.docData.purpose : null)}
				{startDate}
				{getTextField("45", "number", translate(DICTIONARY.BOND_NUMBER.X), null, null,
					props.docData ? props.docData.number : null)}
				{getAmountField("amount", translate(DICTIONARY.AMOUNT.X),
					props.docData ? props.docData.amount : null)}
				{currency}
				{expirationDate}
			</>
		case DOCUMENT_TYPE.INSURANCE:
			return <>
				{issuerOrganizationName}
				{insuranceAgencyName}
				{getTextField("45", "number", translate(DICTIONARY.POLICY_NUMBER.X), null, null,
					props.docData ? props.docData.number : null)}
				{startDate}
				{expirationDate}
				{currency}
			</>
		case DOCUMENT_TYPE.VESSEL_TITLES:
			return <>
				{getTextField("120", "titlingAuthorityName", translate(DICTIONARY.TITLING_AUTHORITY.X),
					null, null, props.docData ? props.docData.titlingAuthorityName : null)}
				{getTextField("45", "titleNumber", translate(DICTIONARY.TITLE_NUMBER.X), null, null,
					props.docData ? props.docData.number : null)}
				{getDateField("vesselPurchaseDate", translate(DICTIONARY.PURCHASE_DATE.X), null,
					dateToYYYYMMDD(new Date()))}
			</>
		case DOCUMENT_TYPE.VESSEL_REGISTRATIONS:
			return <>
				{registrationAuthorityName}
				{getTextField(INPUT_ELEMENT_PARAMETERS.VESSEL_REGISTRATION.LENGTH.MAX, "registration",
					translate(DICTIONARY.REGISTRATION_NUMBER.X),
					INPUT_ELEMENT_PARAMETERS.VESSEL_REGISTRATION.PATTERN, INPUT_ELEMENT_PARAMETERS.VESSEL_REGISTRATION.TITLE,
					props.docData ? props.docData.registration : null)}
				{getTextField("45", "tag", translate(DICTIONARY.TAG_NUMBER.X), null, null,
					props.docData ? props.docData.tag : null)}
				{issueDate}
				{expirationDate}
			</>
		case DOCUMENT_TYPE.VESSEL_DOCUMENTATIONS:
			return <>
				{getTextField("120", "documentationAuthorityName",
					translate(DICTIONARY.DOCUMENTATION_AUTHORITY.X), null, null, props.docData ?
						props.docData.documentationAuthorityName : null)}
				{getTextField(INPUT_ELEMENT_PARAMETERS.VESSEL_DOCUMENTATION.LENGTH.MAX, "documentation",
					translate(DICTIONARY.DOCUMENTATION_NUMBER.X),
					INPUT_ELEMENT_PARAMETERS.VESSEL_DOCUMENTATION.PATTERN, INPUT_ELEMENT_PARAMETERS.VESSEL_DOCUMENTATION.TITLE,
					props.docData ? props.docData.documentation : null)}
				{getTextField(INPUT_ELEMENT_PARAMETERS.IMO.LENGTH.MAX, "IMO", translate(DICTIONARY.IMO_NUMBER.X),
					INPUT_ELEMENT_PARAMETERS.IMO.PATTERN, INPUT_ELEMENT_PARAMETERS.IMO.TITLE, props.docData ?
						props.docData.imo : null)}
				{getTextField(INPUT_ELEMENT_PARAMETERS.CALL_SIGN.LENGTH.MAX, "callSign",
					translate(DICTIONARY.CALL_SIGN.X),
					INPUT_ELEMENT_PARAMETERS.CALL_SIGN.PATTERN, INPUT_ELEMENT_PARAMETERS.CALL_SIGN.TITLE,
					props.docData ? props.docData.callSign : null)}
				{issueDate}
				{expirationDate}
			</>
		case DOCUMENT_TYPE.ORGANIZATION_REGISTRATIONS:
			return <>
				{/*
					getTextField("120", "legalName", translate(DICTIONARY.LEGAL_NAME.X), null, null, props.docData ? props.docData.registrationAuthorityName : null)
				*/}
				{countryOfRegistration}
				{stateOfRegistration}
				{registrationAuthorityName}
				{registrationNumber}
				{issueDate}
				{expirationDate}
			</>
		case DOCUMENT_TYPE.CONTRACTS:
			return <>
				{startDate}
				{expirationDate}
			</>
		case DOCUMENT_TYPE.PUBLIC:
			return <>
				{getDateField("published", translate(DICTIONARY.PUBLICATION_DATE.X), null,
					dateToYYYYMMDD(new Date()))}
				{expirationDate}
			</>
		default:
			return <></>
	}
}

export default MobileInputDocumentFields