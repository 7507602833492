import React, {useState} from "react"
import {DICTIONARY, INPUT_ELEMENT_PARAMETERS} from "../../../../globals/constants/client/constants"
import {translate} from "../../../../globals/functions/client/localFunctions"
import MobileInputGeneric from "../../../shared/input/MobileInputGeneric"

/**
 *
 * @param props {object}
 * @param props.style {Object} - style object of container's top level element
 * @param props.className {string} - className(s) of container's top level element
 * @param props.id {string}
 * @param props.form {string}
 * @param props.label {string}
 * @param props.showLabel {boolean}
 * @param props.name {string}
 * @param props.autoFocus {boolean}
 * @param props.autoComplete {string}
 * @param props.placeholder {string}
 * @param props.defaultValue {string}
 * @param props.required {boolean}
 * @param props.topRadius {boolean}
 * @param props.bottomRadius {boolean}
 * @param props.onChange {function}
 * @param props.left {Icon}
 * @param props.right {Icon}
 * @param props.setBrand {function}
 * @param props.frozenValue {string}
 * @returns {JSX.Element}
 * @constructor
 */
const MobileVesselBrandDropdown = props => {
	const onChange = props.onChange ? props.onChange : () => {}
	const [showText, setShowText] = useState(false)
	return <>
		<MobileInputGeneric type={"dropdown"} id={props.id} className={props.className} style={props.style}
			label={props.label} showLabel={props.showLabel} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
			left={props.left} right={props.right} frozenValue={props.frozenValue} name={props.name} form={props.form}
			autoFocus={props.autoFocus} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX} defaultValue={props.defaultValue}
			autoComplete={props.autoComplete} pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} translate={"no"}
			title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE} required={true} placeholder={props.placeholder} list={props.list}
			onChange={() => {
				const element = document.getElementById(`${props.id}-input`)
				// element.setAttribute("data-a", element.value)
				props.setBrand({label: element.value, value: element.value, code: element.value})
				setShowText(element.value === "na")
				onChange()
		}}/>
		{
			showText ?
				<MobileInputGeneric type={"text"} id={props.id + "-text"} className={props.className} style={props.style}
					label={props.label} showLabel={false} bottomRadius={props.bottomRadius} topRadius={props.topRadius}
					left={props.left} right={props.right} frozenValue={props.frozenValue} name={props.name} form={props.form}
					autoFocus={props.autoFocus} maxLength={INPUT_ELEMENT_PARAMETERS.FULL_NAME.LENGTH.MAX}
					autoComplete={props.autoComplete} pattern={INPUT_ELEMENT_PARAMETERS.FULL_NAME.PATTERN} translate={"no"}
					title={INPUT_ELEMENT_PARAMETERS.FULL_NAME.TITLE} required={true} placeholder={translate(DICTIONARY.BRAND.X)}
					onChange={() => {
						const element = document.getElementById(`${props.id}-text-input`)
						element.setAttribute("data-a", element.value)
						if (props.list.find(item => item.value === element.value)) {
							props.setBrand({label: element.value, value: element.value, code: element.value})
							setShowText(false)
						}
						onChange()
					}}/>
			: <></>
		}
	</>
}

export default MobileVesselBrandDropdown