import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SERVER_RESPONSE_STATE, VERIFICATION_TYPE} from "../../../globals/constants/shared/enumerators"
import {fadingMessage, newMessage, translate} from "../../../globals/functions/client/localFunctions"
import {
	deleteNotification,
	getContactInfoFromServer,
	requestMainWebsiteUpdate,
	requestPrimaryEmailUpdate,
	requestPrimaryPhoneUpdate,
	requestSecondaryEmailUpdate,
	requestSecondaryPhoneUpdate
} from "../../../globals/functions/client/serverFunctions"
import MobileInputPhoneField from "./MobileInputPhoneField"
import MobileInputEmail from "../../shared/input/text/MobileInputEmail"
import MobileInputCode from "../../shared/input/text/MobileInputCode"
import MobileInputGeneric from "../../shared/input/MobileInputGeneric"
import ContactInfo from "../../../globals/classes/shared/ContactInfo"
import Email from "../../../globals/classes/shared/Email"
import Phone from "../../../globals/classes/shared/Phone"
import Modal from "../../../globals/components/Modal"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"

/**
 *
 * @param {Object} props
 * @param {ContactInfo} props.contactInfo
 * @param {Websites} props.websites
 * @param {boolean} props.isOrganization
 * @param {function} props.setContactInfo
 * @param {function} props.setWebsites
 * @param {function} props.refresh
 * @returns {JSX.Element}
 */
const MobileInputContactInfo = props => {
	/* TODO: disable all other fields when one is clicked */
	/* TODO: consider changing to submitting all at once */
	/* TODO: include language info with code request */
	/* TODO: if currently confirmed email/phone is being submitted, prevent this */
	const [primaryEmailField, setPrimaryEmailField] = useState("prompt")
	const [secondaryEmailField, setSecondaryEmailField] = useState("prompt")
	const [primaryPhoneField, setPrimaryPhoneField] = useState("prompt")
	const [secondaryPhoneField, setSecondaryPhoneField] = useState("prompt")
	const [verificationMethod, setVerificationMethod] = useState({show: false, type: null, method: null})
	// console.log(verificationMethod)
	const getLabel = verificationType => {
		return verificationType === "secondary-email" ?
			props.isOrganization ? translate(DICTIONARY.SUPPORT_EMAIL.X) :
				translate(DICTIONARY.RECOVERY_EMAIL.X) : props.isOrganization ?
				translate(DICTIONARY.SUPPORT_PHONE.X) : translate(DICTIONARY.RECOVERY_PHONE.X)
	}
	const showMessage = (type, data) => {newMessage(`${type}Code-message`, translate(DICTIONARY.VERIFICATION_CODE.X),
		translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + data +
		translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}
	const code = (type, icon, data, label) => {
		return <div className={"w3-animate-left w3-animate-opacity"} style={{margin: "1vh 0vh"}}>
			<MobileInputCode id={`${type}-code`} className={"paralian-theme-level-1"} name={`${type}-code`}
				inputMode={(type === "primaryPhone" || type === "secondaryPhone") ? "numeric" : null}
				label={label} form={null} isOrganization={props.isOrganization}	left={{icon: icon, onClick: () => {
					newMessage(`${type}Code-message`, translate(DICTIONARY.VERIFICATION_CODE.X),
						translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + data +
						translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)}}}
				bottomRadius={"0vh"} topRadius={"0vh"} verificationType={type} autoFocus={true}
				required={true} showLabel={false} placeholder={translate(DICTIONARY.CODE.X)} onFailure={()=>{}}
				onSuccess={async () => {
					props.refresh().then()
					fadingMessage("success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X),
						"", "w3-pale-green", ICONS.CHECK)
					const acc = props.isOrganization ? null : -1
					const org = props.isOrganization ? -1 : null
					const response = await getContactInfoFromServer(acc, org)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.setContactInfo(new ContactInfo(response.payload))
					}}}/>
		</div>
	}
	const prompt = type => {
		let onSubmit, placeholder, inputField // properties
		switch (type) {
			case "primaryEmail":
				// properties = USERNAME_FIELD_PROPERTIES.eMail
				placeholder = translate(DICTIONARY.PRIMARY_EMAIL.X)
				onSubmit = async event => {
					event.preventDefault()
					const data = document.getElementById(`account${type}-email-input`).value
					
					const response = await requestPrimaryEmailUpdate(new Email({address: data}),
						false, props.isOrganization)
					// console.log(response)
					
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						showMessage(type, data)
						setPrimaryEmailField("code")
					} else if (response.status === SERVER_RESPONSE_STATE.FAIL) {
						try {
							if (response.payload.message === "Login Email Conflict") {
							const confirmed = window.confirm(translate(DICTIONARY.LOGIN_EMAIL_CONFLICT_MESSAGE.X))
								if (confirmed) {
									
									const newResponse = await requestPrimaryEmailUpdate(new Email({address: data}),
										true, props.isOrganization)
									
									if (newResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
										newMessage(`${type}Code-message`, translate(DICTIONARY.VERIFICATION_CODE.X),
											translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + data +
											translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
										setPrimaryEmailField("code")
									}
								}
							}
						} catch (e) {
							console.log(e)
						}
					}
				}
				inputField = <MobileInputEmail form={`account${type}-form`}
					right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT, onClick: () => {
						document.getElementById(`account${type}-submit`).click()}}
					} bottomRadius={"0vh"} name={`account${type}`} className={"paralian-theme-level-1"} id={`account${type}`}
					left={{icon: ICONS.ENVELOPE, onClick: () => {}}} showLabel={false} topRadius={"0vh"}
					autoFocus={false} required={true} placeholder={placeholder} autoComplete={"email"}
					defaultValue={props.contactInfo.emails.primary.address} label={placeholder}/>
				break
			case "secondaryEmail":
				// properties = USERNAME_FIELD_PROPERTIES.eMail
				placeholder = getLabel("secondary-email")
				onSubmit = async event => {
					event.preventDefault()
					const data = document.getElementById(`account${type}-email-input`).value
					
					const response = await requestSecondaryEmailUpdate(new Email({address: data}), props.isOrganization)
					
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						newMessage(`${type}Code-message`, translate(DICTIONARY.VERIFICATION_CODE.X),
							translate(DICTIONARY.VERIFICATION_MESSAGE_PT_1.X) + data +
							translate(DICTIONARY.VERIFICATION_MESSAGE_PT_2.X), "w3-pale-blue", ICONS.INFO_CIRCLE)
						setSecondaryEmailField("code")
					}
				}
				inputField = <MobileInputEmail form={`account${type}-form`} style={{}}
					right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT, onClick: () => {
						document.getElementById(`account${type}-submit`).click()}}
					} bottomRadius={"0vh"} name={`account${type}`} className={"paralian-theme-level-1"} id={`account${type}`}
					left={{icon: ICONS.ENVELOPE_FAR, onClick: () => {}}} showLabel={false} topRadius={"0vh"}
					autoFocus={false} required={true} placeholder={placeholder} autoComplete={"off"}
					defaultValue={props.contactInfo.emails.secondary.address} label={getLabel("secondary-email")}/>
				break
			case "primaryPhone":
				// properties = USERNAME_FIELD_PROPERTIES.phone
				placeholder = translate(DICTIONARY.PRIMARY_PHONE.X)
				onSubmit = async event => {
					event.preventDefault()
					if (verificationMethod.method) {
						const cc = document.getElementById(`primary-phone-country-code-input`).getAttribute("data-a")
						const iso = document.getElementById(`primary-phone-country-code-input`).getAttribute("data-b")
						const nn = document.getElementById(`primary-phone-national-number-input`).value
						const phone = new Phone({countryCode: cc, nationalNumber: nn, iso: iso})
						const data = phone.fullNumber
						// console.log(verificationMethod)
						const response = await requestPrimaryPhoneUpdate(phone, false, props.isOrganization, verificationMethod.method)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							showMessage(type, data)
							setPrimaryPhoneField("code")
						}
						else if (response.status === SERVER_RESPONSE_STATE.FAIL) {
							try {
								if (response.payload.message === "Login Phone Conflict") {
									const confirmed = window.confirm(translate(DICTIONARY.LOGIN_PHONE_CONFLICT_MESSAGE.X))
									if (confirmed) {
										const newResponse = await requestPrimaryPhoneUpdate(phone, true, props.isOrganization, verificationMethod.method)
										if (newResponse.status === SERVER_RESPONSE_STATE.SUCCESS) {
											showMessage(type, data)
											setPrimaryPhoneField("code")
										}
									}
								}
							} catch (e) {
								console.log(e)
							}
						}
					} else {
						setVerificationMethod({show: true, method: verificationMethod.method, type: type})
					}
				}
				inputField = <MobileInputPhoneField id={"primary-phone"} form={`account${type}-form`} style={{fontSize: "2vh"}}
					left={{icon: ICONS.PHONE, onClick: () => {}}}
					right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT, onClick: () => {
						setVerificationMethod({show: true, type: type, method: verificationMethod.method})
						// document.getElementById(`account${type}-submit`).click()
					}}}
					required={true} topRadius={"0vh"} bottomRadius={"0vh"} className={"paralian-theme-level-1"}
					countryCode={{defaultValue: {number: props.contactInfo.phones.primary.countryCode,
							code: props.contactInfo.phones.primary.iso}}}
					nationalNumber={{defaultValue: props.contactInfo.phones.primary.nationalNumber, autoFocus: false,
						label: placeholder,	placeholder: placeholder}}/>
				break
			case "secondaryPhone":
				onSubmit = async event => {
					event.preventDefault()
					if (verificationMethod.method) {
						const cc = document.getElementById(`secondary-phone-country-code-input`).getAttribute("data-a")
						const iso = document.getElementById(`secondary-phone-country-code-input`).getAttribute("data-id")
						const nn = document.getElementById(`secondary-phone-national-number-input`).value
						const phone = new Phone({countryCode: cc, nationalNumber: nn, iso: iso})
						const data = phone.fullNumber
						const response = await requestSecondaryPhoneUpdate(phone, props.isOrganization, verificationMethod.method)
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							showMessage(type, data)
							setSecondaryPhoneField("code")
						}
					} else {
						setVerificationMethod({show: true, method: verificationMethod.method, type: type})
					}
				}
				// properties = USERNAME_FIELD_PROPERTIES.phone
				placeholder = getLabel("secondary-phone")
				inputField = <MobileInputPhoneField id={"secondary-phone"} form={`account${type}-form`} autoComplete={"off"}
					left={{icon: ICONS.PHONE_FAR, onClick: () => {}}}
					right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT, onClick: () => {
							setVerificationMethod({show: true, type: type, method: verificationMethod.method})
							// document.getElementById(`account${type}-submit`).click()
						}}}
					required={true} topRadius={"0vh"} bottomRadius={"0vh"} className={"paralian-theme-level-1"}
					countryCode={{defaultValue: {number: props.contactInfo.phones.secondary.countryCode,
						code: props.contactInfo.phones.secondary.iso}}} style={{fontSize: "2vh"}}
					nationalNumber={{defaultValue: props.contactInfo.phones.secondary.nationalNumber, autoFocus: false,
						label: placeholder, placeholder: placeholder}}/>
				break
			case "website":
				placeholder = translate(DICTIONARY.WEBSITE.X)
				onSubmit = async event => {
					event.preventDefault()
					try {
						const website = document.getElementById("website-input").value
						
						const response = await requestMainWebsiteUpdate(website)
						
						if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
							props.websites.main = website
							props.setWebsites(props.websites)
						}
					} catch (e) {
						console.log(e)
					}
				}
				inputField = <MobileInputGeneric type={"website"} id={"website"} form={`account${type}-form`} autoComplete={"url"}
					left={{icon: ICONS.BROWSER}} right={{icon: ICONS.CHEVRON_CIRCLE_RIGHT, onClick: () => {
						document.getElementById(`account${type}-submit`).click()}}} defaultValue={props.websites.main}
					className={"paralian-theme-level-1"} required={true} label={placeholder} placeholder={placeholder}/>
				break
			default:
				break
		}
		return <div id={`account${type}-div`} className={"paralian-theme-level-1-flipped"}>
			<form id={`account${type}-form`} style={{display: "flex", margin: "1vh 0vw"}} onSubmit={onSubmit}>
				{inputField}
				<button form={`account${type}-form`} id={`account${type}-submit`} type={"submit"} style={{display: "none"}}/>
			</form>
		</div>
	}
	const verificationMethodModal = verificationMethod.show ? <Modal icon={ICONS.SHIELD} padding={"0vh"}
		title={translate(DICTIONARY.VERIFICATION_METHOD.X)} onClick={() => setVerificationMethod({show: false, method: verificationMethod.method, type: verificationMethod.type})}
		body={<div>
			<div style={{margin: "1vh 2vh"}}>
				<MobileButtonGeneric id={"sms"} type={"button"} left={{icon: ICONS.MESSAGE_TEXT}}
				 text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.SMS_TEXT.X)}}
				 className={"paralian-theme-level-2"}
				 padding={"1vh"} style={{width: "100%"}} onClick={() => {
					setVerificationMethod({show: false, method: "SMS", type: verificationMethod.type})
					setTimeout( () => document.getElementById(`account${verificationMethod.type}-submit`).click(), 500)
					//
				}}/>
			</div>
			<div style={{margin: "1vh 2vh"}}>
				<MobileButtonGeneric id={"whatsapp"} type={"button"} left={{icon: ICONS.WHATSAPP}}
					text={{style: {paddingLeft: "2vh"}, content: "WhatsApp"}}
					className={"paralian-theme-level-2"}
					padding={"1vh"} style={{width: "100%"}} onClick={() => {
						setVerificationMethod({show: false, method: "WHATSAPP", type: verificationMethod.type})
						setTimeout( () => document.getElementById(`account${verificationMethod.type}-submit`).click(), 500)
					//
				}}/>
			</div>
			<div style={{margin: "1vh 2vh"}}>
				<MobileButtonGeneric id={"call"} type={"button"} left={{icon: ICONS.PHONE}}
					text={{style: {paddingLeft: "2vh"}, content: translate(DICTIONARY.VOICE_CALL.X)}}
					className={"paralian-theme-level-2"}
					padding={"1vh"} style={{width: "100%"}} onClick={() => {
						setVerificationMethod({show: false, method: "CALL", type: verificationMethod.type})
						setTimeout( () => document.getElementById(`account${verificationMethod.type}-submit`).click(), 500)
					//
				}}/>
			</div>
		</div>}/> : <></>
	const primaryEmailContent = primaryEmailField === "code" ?
		code(VERIFICATION_TYPE.PRIMARY_EMAIL, ICONS.ENVELOPE, props.contactInfo.emails.primary.address,
		`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.PRIMARY_EMAIL.X)}`) :	prompt("primaryEmail")
	const secondaryEmailContent = secondaryEmailField === "code" ?
		code(VERIFICATION_TYPE.SECONDARY_EMAIL, ICONS.ENVELOPE_FAR, props.contactInfo.emails.secondary.address,
		`${translate(DICTIONARY.CODE.X)} - ${getLabel("secondary-email")}`) : prompt("secondaryEmail")
	const primaryPhoneContent = primaryPhoneField === "code" ?
		code(VERIFICATION_TYPE.PRIMARY_PHONE, ICONS.PHONE, props.contactInfo.phones.primary.fullNumber,
		`${translate(DICTIONARY.CODE.X)} - ${translate(DICTIONARY.PRIMARY_PHONE.X)}`) :
		prompt("primaryPhone")
	const secondaryPhoneContent = secondaryPhoneField === "code" ?
		code(VERIFICATION_TYPE.SECONDARY_PHONE, ICONS.PHONE_FAR, props.contactInfo.phones.secondary.fullNumber,
		`${translate(DICTIONARY.CODE.X)} - ${getLabel("secondary-phone")}`) :
		prompt("secondaryPhone")
	const websiteContent = props.websites ? <div className={"w3-display-container"}>
		<div style={{width: "100%"}}>{prompt("website")}</div></div> : <></>

	return <>
		{verificationMethodModal}
		<div className={"w3-animate-opacity"}>
			<div>
				<div style={{width: "100%"}}>
					{primaryEmailContent}
					{secondaryEmailContent}
				</div>
			</div>
			<div>
				<div style={{width: "100%"}}>
					{primaryPhoneContent}
					{secondaryPhoneContent}
				</div>
			</div>
			{websiteContent}
		</div>
	</>
}

export default MobileInputContactInfo