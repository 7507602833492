import React, {useState} from "react"
import {ORDER_BUTTON, SERVER_RESPONSE_STATE} from "../../../../../globals/constants/shared/enumerators"
import {DICTIONARY, ICONS} from "../../../../../globals/constants/client/constants"
import {fadingMessage, translate} from "../../../../../globals/functions/client/localFunctions"
import {editOrder} from "../../../../../globals/functions/client/serverFunctions"
import MobileButtonGeneric from "../../../../shared/input/buttons/MobileButtonGeneric"
/**
 *
 * @param {Object} props
 * @param {Object} props.order
 * @param {number} props.order.id
 * @param {Object} props.order.buttons
 * @param {boolean} props.order.buttons.accept
 * @param {function} props.refreshOrders
 * @return {JSX.Element}
 * @constructor
 */
const AcceptOrderButton = props => {
	const [disableButtons, setDisableButtons] = useState(false)
	return props.order.buttons.accept ?
		<div style={{margin: "1vh 2vh"}}>
			<MobileButtonGeneric id={"accept"} type={"button"} text={{style: {paddingLeft: "2vh"},
				content: translate(DICTIONARY.ACCEPT.X)}} left={{icon: ICONS.CHECK_CIRCLE}}	className={"paralian-theme-level-1"}
				padding={"1vh"} style={{width: "100%"}} disabled={disableButtons} onClick={async () => {
				try {
					setDisableButtons(true)
					const response = await editOrder(props.order.id, ORDER_BUTTON.ACCEPT)
					if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
						props.refreshOrders()
						fadingMessage("accept-success-message", translate(DICTIONARY.SUCCESS_EXCLAMATION.X), "")
					}
					setDisableButtons(false)
				} catch (e) {
					console.log(e)
					setDisableButtons(false)
				}
			}}/>
		</div> : <></>
}

export default AcceptOrderButton