import React, {useState, useEffect} from "react"
import {DICTIONARY} from "../globals/constants/client/constants"
import {getNoaaTextForecast} from "../globals/functions/client/serverFunctions"
import {translate} from "../globals/functions/client/localFunctions"
import Modal from "../globals/components/Modal"
/* TODO: check if online, if not, check if there's something in cache/state to display */
const TextForecast = props => {
	const [content, setContent] = useState(<></>)
	const [subContent, setSubContent] = useState(null)
	const location = props.location
	useEffect(() => {
		getNoaaTextForecast(location).then(text => {
			let subContentArray = []
			for (let i = 0; i < text.headers.length; i++) {
				subContentArray[i] = [text.headers[i], text.productText[i + 1]]
			}
			// console.log(subContentArray)
			const subContent = subContentArray.map((contentPair, i) => <div key={`textForecastSubcontent${i}`}>
				<div className={"paralian-theme-level-3 w3-ripple"} style={{margin: "2vh 0vh", padding: "1vh",
					cursor: "pointer", textAlign: "center"}} onClick={() => setSubContent({
						content: <div style={{margin: "2vh", whiteSpace: "pre-line", textAlign: "left"}}>
								{contentPair[1]}
							</div>,
						header: contentPair[0]}
				)}>
					{contentPair[0]}
				</div>
			</div>)
			return <div style={{margin: "2vh", whiteSpace: "pre-line", textAlign: "left"}}>
				<div className={"paralian-theme-level-1"} id={"textForecast"}>
					{text.productText[0]}
				</div>
				{subContent}
			</div>
		}).then(response => {setContent(response)}).catch(() => <div style={{margin: "2vh", whiteSpace: "pre-line",
			textAlign: "left"}}>
				<div className={"paralian-theme-level-1"} id={"textForecast"}>
					{translate(DICTIONARY.ERROR_406_MESSAGE.X)}
				</div>
			</div>)
	}, [location])
	const modal = subContent ? <Modal id={"forecast-subcontent-modal"} padding={"0vh"} title={subContent.header}
		onClick={() => setSubContent(null)} body={subContent.content}/> : <></>
	return <>{modal}{content}</>
}

export default TextForecast