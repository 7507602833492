import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../../globals/constants/client/constants"
import {SUBSCRIPTION_TYPE} from "../../../globals/constants/shared/enumerators"
import {translate} from "../../../globals/functions/client/localFunctions"
import MobileImage from "../account/MobileImage"
import MobileButtonGeneric from "../../shared/input/buttons/MobileButtonGeneric"
import MobileParalianProductDescription from "./MobileParalianProductDescription"
import MobileFontAwesome from "../MobileFontAwesome"
import MobileParalianProductPricing from "./ParalianProductPricing"
import Image from "../../../globals/classes/shared/Image"
import ancreGoldRedDot from "../../../images/ancre_logo_circle_gold_red_dot.svg"
/**
 *
 * @param {Object} props
 * @param {string} props.product
 * @param {function} props.onClick
 * @return {JSX.Element}
 * @constructor
 */
const MobileSubscriptionSignupCard = props => {
	const [showDescription, setShowDescription] = useState(false)
	const [showPricing, setShowPricing] = useState(false)
	// console.log("props.paralianProducts", props.paralianProducts)
	// console.log("props.product", props.product)
	// console.log("props.subscription", props.subscription)
	try {
		const subscription = props.subscription
		subscription.type = SUBSCRIPTION_TYPE.PERSONAL
		let title, buttonText, description, pricing, image
		title = translate(DICTIONARY.PERSONAL_PREMIUM.X)
		buttonText = translate(DICTIONARY.PURCHASE.X)
		description = <MobileParalianProductDescription subscription={props.subscription}
			onClick={() => setShowDescription(false)}/>
		pricing = <MobileParalianProductPricing subscription={props.subscription} paralianProducts={props.subscription.products}
			onClick={() => setShowPricing(false)}/>
		image = ancreGoldRedDot
		/*switch (props.product) {
			case PARALIAN_PRODUCT_NAME.ORGANIZATION_ORDER_TOKEN:
				title = translate(DICTIONARY.ORDER_CREDITS.X)
				buttonText = translate(DICTIONARY.SIGN_UP.X)
				description = <MobileParalianProductDescription subscription={props.subscription}
					onClick={() => setShowDescription(false)}/>
				pricing = <MobileParalianProductPricing subscription={props.subscription}
					onClick={() => setShowPricing(false)}/>
				image = ancreBlue
				break
			case PARALIAN_PRODUCT_NAME.ORGANIZATION_CUSTOMER_TOKEN:
				title = translate(DICTIONARY.CUSTOMER_CREDITS.X)
				buttonText = translate(DICTIONARY.SIGN_UP.X)
				description = <MobileParalianProductDescription subscription={props.subscription}
					onClick={() => setShowDescription(false)}/>
				pricing = <MobileParalianProductPricing subscription={props.subscription}
					onClick={() => setShowPricing(false)}/>
				image = ancreGold
				break
			case PARALIAN_PRODUCT_NAME.PERSONAL_PREMIUM:
				title = translate(DICTIONARY.PERSONAL_PREMIUM.X)
				buttonText = translate(DICTIONARY.PURCHASE.X)
				description = <MobileParalianProductDescription product={props.product}
					onClick={() => setShowDescription(false)}/>
				pricing = <MobileParalianProductPricing subscription={props.subscription}
					onClick={() => setShowPricing(false)}/>
				image = ancreGoldRedDot
				break
			default:
				return <></>
		}*/
		return <div style={{padding: "2vh 0vh"}} className={"paralian-theme-level-2"}>
			<div style={{marginBottom: "2vh", fontSize: "2.5vh"}}>
				{title}
			</div>
			<div style={{display: "inline-flex"}}>
				<div>
					<MobileImage image={new Image({path: image})} size={"10vh"} alt={"profile"} style={{margin: "auto 1vh"}}/>
				</div>
				<div style={{margin: "auto 0vh auto 2vh", width: "100%"}}>
					<div style={{padding: "0.5vh 0vh"}} onClick={() => setShowDescription(true)}>
						{translate(DICTIONARY.DESCRIPTION.X)}
						<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{paddingLeft: "1vh"}}/>
					</div>
					<div style={{padding: "0.5vh 0vh"}} onClick={() => setShowPricing(true)}>
						{translate(DICTIONARY.PRICING.X)}
						<MobileFontAwesome icon={ICONS.INFO_CIRCLE} style={{paddingLeft: "1vh"}}/>
					</div>
				</div>
			</div>
			<div style={{padding: "0vh 2vh"}}>
				<MobileButtonGeneric id={"customer-token-purchase"} type={"button"}
					padding={"2vh"} style={{margin: "2vh 0vh ", width: "100%"}} className={"paralian-theme-level-1"}
					onClick={props.onClick ? props.onClick : () => {}}
					text={{content: buttonText}}/>
			</div>
			{showDescription ? description : <></>}
			{showPricing ? pricing : <></>}
		</div>
	} catch (e) {
		return <></>
	}
}

export default MobileSubscriptionSignupCard