import React, {useEffect, useState} from "react"
import MobileInputCurrencyDropdown from "./MobileInputCurrencyDropdown"
/**
 *
 * @param {Object} props
 * @param {Object} props.defaultCurrency
 * @param {number} props.defaultCurrency.symbol
 * @param {string} props.defaultCurrency.iso
 * @param {string} props.country - iso 3166 3-letter code
 * @param {React.HTMLAttributes.className} props.className
 * @param {boolean} props.showLabel
 * @param {boolean} props.appendToLabel
 * @returns {JSX.Element}
 * @constructor
 */
const MobileInputCurrencyDropdownAndSymbol = props => {
	const [currency, setCurrency] = useState(props.defaultCurrency)
	const country = props.country || "USA"
	const id = props.id ? `${props.id}-currency` : "currency"
	useEffect(() => {setCurrency(props.defaultCurrency)}, [props.defaultCurrency])
	return <div style={{margin: "1vh 0vh"}}>
		<MobileInputCurrencyDropdown id={id} form={"form"} country={country} showLabel={props.showLabel}
			onChange={currency => setCurrency(currency)} style={{fontWeight: "bold"}} className={props.className}
			appendToLabel={props.appendToLabel}/>
		<div className={props.className || "paralian-theme-level-1-flipped"}
			style={{textAlign: "center", padding: "1vh 0vh", fontSize: "5vh"}}>
			{`${currency.asString} (${currency.code})`}
		</div>
	</div>
}

export default MobileInputCurrencyDropdownAndSymbol