import React, {useState} from "react"
import {DICTIONARY, ICONS} from "../../globals/constants/client/constants"
import {REQUEST_STATUS, SERVER_RESPONSE_STATE} from "../../globals/constants/shared/enumerators"
import {
	acceptAllOrgAccessRequestsToMarina,
	cancelOrgAccessRequestsToMarina, respondToOrgAccessRequestsToMarina
} from "../../globals/functions/client/serverFunctions"
import {newMessage, translate} from "../../globals/functions/client/localFunctions"
import MobileFontAwesome from "./MobileFontAwesome"
import MobileButtonGeneric from "../shared/input/buttons/MobileButtonGeneric"
import MobileRequiredDocumentDocumentCard from "./documents/MobileRequiredDocumentDocumentCard"
import MobileRequiredDocumentVerificationCard from "./documents/MobileRequiredDocumentVerificationCard"
import Servicio from "../../globals/classes/shared/Servicio"
import IndexedArray from "../../globals/classes/shared/IndexedArray"
import containerAndMinimalTypeToType from "./documents/containerAndMinimalTypeToType"
/**
 *
 * @param {Object} props
 * @param {Servicio[]} props.services
 * @param {Object} props.request
 * @param {MinimalProfile} props.request.profile
 * @param {Object[]} props.request.services
 * @param {number} props.request.services[].service
 * @param {string} props.request.services[].status
 * @param {DocumentRequirements} props.documentRequirements
 * @param {boolean} props.isRequester
 * @param {function} props.setServiceApprovals
 * @return {JSX.Element}
 * @constructor
 */
const MobileVerificationStatusCard = props => {
	const [edit, setEdit] = useState(false)
	const [showCategories, setShowCategories] = useState(!props.simple)
	const [showDocuments, setShowDocuments] = useState(!props.simple)
	const onDelete = props.onDelete ? props.onDelete : () => {}
	const resolved = !props.request.categories.some(v => v.status !== "APPROVED" && v.status !== "DENIED")
	// console.log("props.request.categories", props.request.categories, "resolved", resolved)
	return <div className={`paralian-mobile-info-card w3-center ${props.simple && props.canEdit ? "w3-ripple" : ""}`}
		style={{cursor: props.simple && props.canEdit ? "pointer" : null}}
		onClick={() => {if (props.simple && props.canEdit) {props.onClick()}}}>
		{
			resolved || !props.canEdit ? <></> :
			<div style={{margin: "0vh auto 2vh auto", padding: "1vh", color: "black", backgroundColor: "orange",
				borderRadius: "1vh"}} onClick={() => newMessage(`${props.request.profile.id}-verification-card-resolved-message`,
				translate(DICTIONARY.UNRESOLVED.X),	"", "w3-pale-blue", ICONS.MINUS_CIRCLE)}>
				{translate(DICTIONARY.UNRESOLVED.X)}
				<MobileFontAwesome icon={ICONS.MINUS_CIRCLE} style={{paddingLeft: "1.5vh"}}/>
			</div>
		}
		<div className={"paralian-mobile-info-card-header"} style={{display: "inline-flex", width: "100%",
			textAlign: "center", marginBottom: "1vh"}}>
			<div style={{minWidth: "10%"}}>
				{edit ?
					<MobileFontAwesome icon={props.isRequester ? ICONS.TRASH : ICONS.CHECK_DOUBLE} style={{fontSize: "3vh"}}
						label={props.isRequester ? translate(DICTIONARY.DELETE.X) : translate(DICTIONARY.APPROVE_ALL.X)}
						className={"w3-animate-opacity"} onClick={async () => {
						if (props.isRequester) {
							const confirmed = window.confirm(translate(DICTIONARY.DELETE_MARINA_QUERY.X))
							if (confirmed) {
								const response = await cancelOrgAccessRequestsToMarina(props.request.profile.id)
								if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
									onDelete()
								}
							}
						} else {
							const response = await acceptAllOrgAccessRequestsToMarina(props.request.profile.id)
							if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
								props.refresh()
							}
						}
						}}/> : <></>}
			</div>
			<div style={{width: "100%", padding: "0.5vh 0vh"}}>
				<div><i>{props.request.profile.name.display}</i></div>
				<div><i>{props.request.profile.address.city + ", " + props.request.profile.address.state.name}</i></div>
			</div>
			<div style={{minWidth: "10%"}}>
				{!props.simple ? <MobileFontAwesome icon={edit ? ICONS.BACK : ICONS.PENCIL} style={{paddingLeft: "1.5vh"}}
					 label={edit ? translate(DICTIONARY.BACK.X) : translate(DICTIONARY.EDIT.X)}
					 onClick={() => setEdit(!edit)} className={"w3-animate-opacity"}/> : <></>}
			</div>
		</div>
		<div className={"accountProfileImage w3-display-container"} style={{minHeight: "14vh", minWidth: "14vh"}}>
			<picture className={"w3-display-middle"}>
				{/* TODO: onClick => show public profile of organization (without prices) */}
				<img src={props.request.profile.image.path} alt={props.request.profile.name.display}
						 style={{height: "14vh", width: "14vh", padding: "2vh", backgroundColor: "white", objectFit: "contain"}}/>
			</picture>
		</div>
		{
			!props.simple ? <>
				<div className={"paralian-theme-level-1 w3-ripple"} style={{padding: "1vh", margin: "2vh 0vh",
					cursor: "pointer"}} onClick={() => setShowCategories(!showCategories)}>
					{translate(DICTIONARY.CATEGORIES.X)}
					<MobileFontAwesome icon={showCategories ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN}
						style={{paddingLeft: "1.5vh"}}
						label={showCategories ? translate(DICTIONARY.RETRACT.X) : translate(DICTIONARY.EXPAND.X)}
						 className={"w3-animate-opacity"}/>
				</div>
				{
					showCategories ? <div>
						{
							props.request.categories.map(category => {
								// console.log("category.id", category.id)
								// const service_ = new Servicio(props.services ? props.services.asIndexedArray.obj[category.id] : null)
								const service_ = new Servicio(props.services ? props.services.byIndex(category.id) : null)
								// console.log(service_)
								const label = service_.labelsAsString
								return <div className={"paralian-theme-level-3 w3-animate-opacity"}
									style={{textAlign: "center", padding: "1vh"}}
									key={`vmsc-${category.id}-${props.request.profile.id}`}>
									<div style={{opacity: "0.5"}}>{label}</div>
									<div style={{fontSize: "4vh", padding: "1vh 0vh"}}>{translate(category.status)}</div>
									{
										edit && !props.isRequester ?
											<div className={"w3-animate-opacity"}>
												<MobileButtonGeneric id={"accept"} type={"button"} text={{
													style: {paddingLeft: "2vh"},
													content: translate(DICTIONARY.ACCEPT.X)
												}} left={{icon: ICONS.CHECK_CIRCLE}}
													className={category.status === "APPROVED" ? "paralian-theme-level-2" : "paralian-theme-level-1"}
													padding={"1vh"} style={{width: "100%", margin: "1vh 0vh"}} disabled={false}
													onClick={async () => {
														try {
															const response = await respondToOrgAccessRequestsToMarina(props.request.profile.id,
																category.id, REQUEST_STATUS.APPROVED)
															if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
																props.refresh()
															}
														} catch (e) {
															console.log(e)
														}
													}}/>
												<MobileButtonGeneric id={"reject"} type={"button"} text={{
													style: {paddingLeft: "2vh"},
													content: translate(DICTIONARY.REJECT.X)
												}} left={{icon: ICONS.BAN}}
													className={category.status === "DENIED" || category.status === "PROVISIONAL" ?
														"paralian-theme-level-2" : "paralian-theme-level-1"}
													disabled={category.status === "DENIED" || category.status === "PROVISIONAL"}
													padding={"1vh"} style={{width: "100%"}} onClick={async () => {
													try {
														const response = await respondToOrgAccessRequestsToMarina(props.request.profile.id,
															category.id, REQUEST_STATUS.DENIED)
														if (response.status === SERVER_RESPONSE_STATE.SUCCESS) {
															props.refresh()
														}
													} catch (e) {
														console.log(e)
													}
												}}/>
											</div> : <></>
									}
								</div>
							})
						}
						{/* TODO: add category */}
					</div> : <></>
				}
				<div className={"paralian-theme-level-1 w3-ripple"} style={{padding: "1vh", margin: "2vh 0vh",
					cursor: "pointer"}} onClick={() => setShowDocuments(!showDocuments)}>
					{translate(DICTIONARY.DOCUMENTS.X)}
					<MobileFontAwesome icon={showDocuments ? ICONS.ANGLE_DOUBLE_UP : ICONS.ANGLE_DOUBLE_DOWN}
						style={{paddingLeft: "1.5vh"}}
						label={showDocuments ? translate(DICTIONARY.RETRACT.X) : translate(DICTIONARY.EXPAND.X)}
						className={"w3-animate-opacity"}/>
				</div>
				{
					showDocuments ?
						props.isRequester ? <div>
								{props.request.profile.documentRequirements.map(v => {
									const docArray = props.documents[containerAndMinimalTypeToType(v.container, v.type)].map(d => {
										return {id: d.document.id, name: d.document.name}
									})
									const documents = new IndexedArray({
										array: docArray,
										key: "id"
									})
									return <div key={`${v.id}-${props.request.profile.id}`} style={{margin: "1vh 0vh"}}
										className={"paralian-theme-level-3 w3-animate-opacity"}>
										<MobileRequiredDocumentDocumentCard id={`${v.id}-${props.request.profile.id}`} requirement={v}
											documents={documents} canEdit={props.canEdit}
											refresh={props.refresh} currency={props.currency}
											country={props.country} state={props.state}/>
									</div>
								})}
							</div> :
							props.request.profile.documentRequirements.map(v => <div key={`${v.id}-${props.request.organization}`}
								className={"paralian-theme-level-3 w3-animate-opacity"}>
								<MobileRequiredDocumentVerificationCard id={`${v.id}-${props.request.profile.id}`}
									requirements={new IndexedArray({array: props.documentRequirements.requirements, key: "id"})}	document={v}/>
							</div>) : <></>
				}
			</> : <></>
		}
	</div>
}

export default MobileVerificationStatusCard